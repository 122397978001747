import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginAction } from "../Actions/LoginAction";
import { userInfo } from "../Actions/userInfo";
import isNull from "../functions/nullChecking";
class PatientDetails extends Component {
  render() {
    return (
      <div className="table-responsive ml-3 mr-3">
        <table className="table table-sm border-0 mb-0" id="kamran">
          <tr>
            <td className="border-0">
              <label className="dark-label-blue">
                <strong>
                  {!isNull(this.props.userInformation &&  this.props.userInformation[0].firstName && this.props.userInformation[0].lastName)
                    ? this.props.userInformation[0].firstName +
                      " " +
                      this.props.userInformation[0].lastName
                    : ""}
                </strong>
              </label>
            </td>
            <td className="border-0">
              <label className="pr-2">
                {!isNull(this.props.userInformation && this.props.userInformation[0].gender && this.props.userInformation[0].maritalStatus )
                  ? this.props.userInformation[0].gender +
                    " - " +
                    this.props.userInformation[0].maritalStatus
                  : ""}
              </label>
              <label>
                {!isNull(this.props.userInformation && this.props.userInformation[0].dob &&  this.props.userInformation[0].age )
                  ? this.props.userInformation[0].dob +
                    " (" +
                    this.props.userInformation[0].age +
                    ")"
                  : ""}
              </label>
            </td>
            <td className="border-0">
              <label
                className="dashboard-textoverflow"
                title={
                  !isNull(this.props.userInformation && this.props.userInformation[0].primaryInsurance)
                    ? this.props.userInformation[0].primaryInsurance
                    : "N/A"
                }
              >
                <b>PRI INSURANCE</b> :&nbsp;
                {!isNull(this.props.userInformation && this.props.userInformation[0].primaryInsurance)
                  ? this.props.userInformation[0].primaryInsurance
                  : "N/A"}
              </label>
            </td>
            <td className="border-0">
              <label>
                <b>POLICY #</b> :&nbsp;
                {!isNull(this.props.userInformation && this.props.userInformation[0].primaryPolicy)
                  ? this.props.userInformation[0].primaryPolicy
                  : "N/A"}
              </label>
            </td>

            <td className="border-0">
              <label>
                <b>Last Appointment: </b>&nbsp;
                {!isNull(this.props.userInformation && this.props.userInformation[0].lastAppointment)
                  ? this.props.userInformation[0].lastAppointment ===
                      "01/01/1900" ||
                    isNull(this.props.userInformation[0].lastAppointment)
                    ? "No Appointment"
                    : this.props.userInformation[0].lastAppointment
                  : "No Appointment"}
              </label>
            </td>
          </tr>
          <tr>
            <td className="border-0">
              <label
                className="dashboard-textoverflow"
                title={
                  !isNull(
                    this.props.userInformation 
                  
                    ? this.props.userInformation[0].address1 +
                      " " +
                      this.props.userInformation[0].city +
                      ", " +
                      this.props.userInformation[0].state +
                      " " +
                      this.props.userInformation[0].zipCode
                    : "")}
                
              >
                <i
                  class="fas fa-map-marker-alt text-danger pr-2"
                  aria-hidden="true"
                ></i>
                {!isNull(
                  this.props.userInformation)
                
                  ? this.props.userInformation[0].address1 +
                    " " +
                    this.props.userInformation[0].city +
                    ", " +
                    this.props.userInformation[0].state +
                    " " +
                    this.props.userInformation[0].zipCode
                  : ""}
              </label>
            </td>
            <td className="border-0">
              <i class="fa fa-phone text-danger pr-2"></i>
              <label>
                {!isNull(
                  this.props.userInformation &&
                    this.props.userInformation[0].phoneNumber
                )
                  ? this.props.userInformation[0].phoneNumber
                  : "N/A"}
              </label>
            </td>
            <td className="border-0">
              <label
                className="dashboard-textoverflow"
                title={
                  !isNull(
                    this.props.userInformation &&
                      this.props.userInformation[0].secondaryInsurance
                  )
                    ? this.props.userInformation[0].secondaryInsurance
                    : "N/A"
                }
              >
                <b>SEC INSURANCE</b> :&nbsp;
                {!isNull(
                  this.props.userInformation &&
                    this.props.userInformation[0].secondaryInsurance
                )
                  ? this.props.userInformation[0].secondaryInsurance
                  : "N/A"}
              </label>
            </td>
            <td className="border-0">
              <label
                className="dashboard-textoverflow"
                title={
                  !isNull(
                    this.props.userInformation &&
                      this.props.userInformation[0].secondaryPolicy
                  )
                    ? this.props.userInformation[0].secondaryPolicy
                    : "N/A"
                }
              >
                <b>POLICY #</b> :&nbsp;
                {this.props.userInformation &&
                !isNull(this.props.userInformation[0].secondaryPolicy)
                  ? this.props.userInformation[0].secondaryPolicy
                  : "N/A"}
              </label>
            </td>

            <td className="border-0">
              <label>
                <b>Next Appointment:</b>&nbsp;
                {!isNull(this.props.userInformation)
                  ? this.props.userInformation[0].nextVisit ===
                      "01/01/1900 12:00 AM" ||
                    isNull(this.props.userInformation[0].nextVisit)
                    ? "No Appointment"
                    : this.props.userInformation[0].nextVisit
                  : ""}
              </label>
            </td>
          </tr>
        </table>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInformation: state.userInformation,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      userInfo: userInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(PatientDetails);
