import React, { Component } from "react";
import * as serviceWorker from "./serviceWorker";
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { Switch, Route, withRouter } from "react-router-dom";

import "./css/stylesheet.css";
import "./css/bootstrap.min.css";
import "./css/style.css";

// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Appointment from "./components/Appointment";
import Login from "./components/Login";
import CheckoutForm from "./components/CheckoutForm";
import  ClinicalNotes from "./components/ClinicalNotes";
import LabOrder from "./components/LabOrder";
import Documents from "./components/Documents"

import InternalMessages from "./components/InternalMessages/InternalMessages";
import ComposeEmail from "./components/InternalMessages/ComposeInternalMessage";

//Redux Actions
import { loginAction } from "./Actions/LoginAction";
import { userInfo } from "./Actions/userInfo";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import InternalMessages from './components/InternalMessages/InternalMessages'

// const promise = loadStripe(
//   "pk_test_51HPWJtIBQ68Ovn0xqAWkNtXoBcOOWXN5hHDys3bgWJGYbM0uEniFrijcSGcUMfUULvbQ8oA89QnmOvPs3j2rx94W00bDjrdAli"
// );

class App extends Component {

  componentWillUnmount() {
    window.alert("unmount");
  }
  
  render() {
   let pages = (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/Login" component={Login} />
        <div className=" container-fluid p-0 m-0">
        <Header />
        {/* <Elements stripe={this.state.promise}>{pages}</Elements> */}
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/Appointment" component={Appointment} />
        <Route exact path="/CheckoutForm" component={CheckoutForm} />
        <Route exact path="/InternalMessages" component={InternalMessages} />
        <Route exact path="/ComposeEmail" component={ComposeEmail} />
        <Route exact path="/ClinicalNotes" component={ClinicalNotes} />
        <Route exact path="/LabOrder" component={LabOrder} />
        <Route exact path="/Documents" component={Documents} />
        </div>
        {/* <Route exact path="/InternalMessages" component={InternalMessages} /> */}
        {/* <Route exact path="/Draft" component={Draft} /> */}
        {/* <Route exact path="/Inbox" component={Inbox} /> */}
        {/* <Route exact path="/MessageDetails" component={MessageDetails} />
        <Route exact path="/MessageDetailsMain" component={MessageDetailsMain} /> */}
        {/* <Route exact path="/Sent" component={Sent} /> */}
      </Switch>
    );
    return (
      <div>
        
        {this.props.loginObject.isLogin === false ? (
          <Login />
        ) : (
            pages
          //   <div className=" container-fluid p-0 m-0">
          //    <Header /> 
          //   {/* <Elements stripe={this.state.promise}>{pages}</Elements> */}
          //    {pages}
          //  </div>
        )}


      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInformation: state.userInformation,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction,
      userInfo,
    },
    dispatch
  );
}
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(App));

serviceWorker.unregister();
