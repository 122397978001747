import { combineReducers } from 'redux'
import LoginReducer from './LoginReducer'
import userInfoReducer from './userInfoReducer'

const allReducers = combineReducers({
  loginToken: LoginReducer,
  userInformation: userInfoReducer,

  
})

export default allReducers
