import React from "react";
import { withRouter} from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { loginAction } from "../Actions/LoginAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../css/PrintStyle.css";
import Swal from "sweetalert2";
import { setNotesModules } from "../Actions/notesModuleAction";
import Spiner from "./Spiner";
import isNull from "../functions/nullChecking";
import LabOrderPrint from "./LabOrderPrint";
class Documents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.state = {
      loading: false,
      showPopup: "",
      data: [],
      table: [],
      Columns: [
        {
          label: "ORDER DATE",
          field: "orderDate",
          sort: "asc",
          width: 100,
        },
        
        {
          label: "PROVIDER",
          field: "provider",
          sort: "asc",
          width: 100,
        },
        {
          label: "LOCATION",
          field: "location",
          sort: "asc",
          width: 150,
        },
        {
          label: "LAB",
          field: "lab",
          sort: "asc",
          width: 100,
        },

        {
          label: "STATUS",
          field: "status",
          sort: "asc",
          width: 50,
        },
        {
          label: "SIGNED BY",
          field: "signed",
          sort: "asc",
          width: 100,
        },
        {
          label: "ACTION",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
    };
  }
  componentDidMount() {
    this.getClinicalNotesData();
  }

  loading = (action) => {
    this.setState({ loading: action });
  };

  getClinicalNotesData = () => {
    try {
      this.setState({ loading: true });

      axios
        .get(this.url + "LabSummary", this.config)
        .then((response) => {
          this.setState({
            loading: false,
          })
          let newList = [];
          try {
            response.data.forEach((row, i) => {
              newList.push({
                lab: row.lab,
                orderDate: row.orderDate,
                provider: row.provider,
                location: row.location,
                status : row.status,
                signed : row.signed,
                action: 
                (
                  <i
                  title="View"
                  class="fas fa-eye print-source f14"
                  style={{ cursor: "pointer" }}
                    onClick={(e) => this.showPopupFunc(e, row)}
                  ></i>
                ),
              });
           
            });
            this.setState({
              // data : newList,
              table: {
                columns: this.state.Columns,
                rows: newList,
              },
              loading: false,
            });
          } catch (error) {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };
  keyHandling = (event) => {
    if (event.keyCode === 27) {
      if (this.escCounter === 0) this.toggle();
      else {
        Swal.fire({
          title: "Do you want to close?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
          this.setState({ showPopup: false });
        });
      }
    } else if (event.altKey && event.keyCode === 83) {
      this.saveData();
    }
  };

  showPopupFunc = (e, row) => {
    e.preventDefault();
    this.setState({
      showPopup: (
        <LabOrderPrint
        labId={row.orderid}
        closeModal={() => this.setState({ showPopup: null })}
        
          // name={row.provider}
          // visitDate={row.dos}
          // signedBy={row.signedBy}
          // signedDate={row.signedDate}
          // status={row.signed}
          // keyHandling={this.keyHandling}
          // closeModal={() => this.setState({ showPopup: null })}
          // loading={this.loading}
        />
      ),
    });
  };
  render() {
    // const columns = [
    //   {
    //     name: "DATE",
    //     selector: "appointmentDate",
    //     sortable: true,
    //     minWidth: "100px",
    //     maxWidth: "100px",
    //   },
    //   {
    //     name: "LOCATION",
    //     selector: "location",
    //     sortable: true,
    //     minWidth: "150px",
    //     maxWidth: "150px",
    //   },
    //   {
    //     name: "PROVIDER",
    //     selector: "provider",
    //     minWidth: "250px",
    //     maxWidth: "250px"
    //   },
    //   {
    //     name: "VISIT REASON",
    //     selector: "visitReason",
    //     minWidth: "100px",
    //     maxWidth: "100px"
    //   },
    //   {
    //     name: "SIGNED BY",
    //     selector: "signedBy",
    //     sortable: true,
    //     minWidth: "250px",
    //     maxWidth: "250px"
    //   },
    //   {
    //     name: "SIGNED DATE",
    //     selector: "signedDate",
    //     sortable: true,
    //     minWidth: "200px",
    //     maxWidth: "200px"
    //   },
    //   {
    //     name: "ACTION",
    //     selector: "action",
    //     sortable: true,
    //     minWidth: "100px",
    //     maxWidth: "100px"
    //   }
    // ];
    let popup = "";
    if (!isNull(this.state.showPopup)) {
      document.body.style.overflowY = "hidden";
      popup = this.state.showPopup;
    } else document.body.style.overflowY = "visible";
    return (
      <React.Fragment>

        <div className="tab-pane fade show active" id="clinicalNotes">
          {popup}
          <Spiner loading={this.state.loading} />
          <div className="row m-2">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 custom-header">
                  <h6 className="pt-2 pb-1">
                    <b>Patient Documents Summary</b>
                  </h6>
                </div>

              </div>
              <div className="row">
                <div
                  className="tableGridContainer text-nowrap col-md-12 p-0 m-0 clinicalTable"
                  style={{ lineHeight: 1 }}
                >
                  <MDBDataTable
                    responsive={true}
                    striped
                    bordered
                    entries="10"
                    searching={false}
                    data={this.state.table}
                    displayEntries={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    setupLeftMenu: state.leftNavigationMenus,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInfo:
      state.loginInfo != null
        ? state.loginInfo
        : { userPractices: [], name: "", practiceID: null },
    notesModules: state.notesModules,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      setNotesModules: setNotesModules,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Documents)
);
