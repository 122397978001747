import React from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { loginAction } from "../Actions/LoginAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import "../css/PrintStyle.css";
// import Draggable from "react-draggable";
import Swal from "sweetalert2";
import ReactToPrint from "react-to-print";
// import PrintIcon from "@material-ui/icons/Print";
import { setNotesModules } from "../Actions/notesModuleAction";
import Spiner from "./Spiner";
import isNull from "../functions/nullChecking";
//import SocialHistory from '../DetailedNotes/SocialHistory'

class ClinicalDetailReports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.state = {
      allClinicalsData: [],
      summarypatientHeader: "",
      vitals: "",
      positionedComponent: [],
      practiceData: "",
      notesModules: [],
      module: [],
      check: true,
      positionArray: [],
      functionalCognitiveStatus:[],
      patientAllergy:[],
      patientImmunization: [],
      patientHPI:"",
      patientMedicalNotes:"",
      patientPrescription: [],
      patientProcedure:[],
      familyHistory: [],
      problemlist: [],
      patientPhysicalExam: [],
      patientROS: [],
      patientSocialHistroy:"",
      amendments:[]

    };
  }

  componentDidMount = async () => {
    const { patientNotesID } = this.props;
    this.props.loading(true);
    if (patientNotesID != null) {
      this.props.loading(true);
      axios
        .get(
          this.url + `GetPatientNotes?PatientNotesID=${patientNotesID}`,
          this.config
        )
        .then((response) => {
          this.setState(
            {
              allClinicalsData : response.data,
              summarypatientHeader: response.data.summarypatientHeader[0],
              vitals: response.data.patientVitals,
              familyHistory:response.data.familyHistory,
              functionalCognitiveStatus:
                response.data.functionalCognitiveStatus,
              patientAllergy: response.data.patientAllergy,
              patientHPI: !isNull(response.data.patientHPI)
                ? response.data.patientHPI
                : "",
              patientImmunization: response.data.patientImmunization,
              patientMedicalNotes: !isNull(response.data.patientMedicalNotes)
                ? response.data.patientMedicalNotes
                : "",
              patientPrescription: response.data.patientPrescription,
              patientProcedure: response.data.patientProcedure,
              problemlist: response.data.problemlist,
              practiceData: response.data.practice,
              patientPhysicalExam: response.data.patientPhysicalExam.PhysicalExam,
              patientROS: response.data.patientROS.Ros,
              patientSocialHistroy:response.data.patientSocialHistroy,
              amendments: response.data.amendments
            },
            this.setPosition
          );
          // this.setState({ allClinicals: response.data }, this.setPosition);
          this.props.loading(false);
        })
        .catch((error) => {
          this.props.loading(false);
          console.log(error);
        });
    }

    // if (patientNotesID!= null) {
    //   this.props.loading(true);
    //   await axios
    //     .get(this.url + `Practice`, this.config)
    //     .then((response) => {
    //       this.setState({ practiceData: response.data }, this.setPosition);
    //     })
    //     .catch((error) => {
    //       this.props.loading(false);
    //       console.log(error);
    //     });
    // }
  };
  componentWillUnmount = () => {
    this.setState({
      allClinicalsData: [],
      summarypatientHeader: "",
      vitals: "",
      positionedComponent: [],
      practiceData: "",
      notesModules: [],
      module: [],
      check: true,
      positionArray: [],
      functionalCognitiveStatus:[],
      patientAllergy:[],
      patientImmunization: [],
      patientHPI:"",
      patientMedicalNotes:"",
      patientPrescription: [],
      patientProcedure:[],
      familyHistory: [],
      problemList: [],
      patientPhysicalExam: [],
      patientROS: [],
      patientSocialHistroy:"",
      amendments:[]

    });
  };
  sign = () => {
    this.setState({ loading: true });
    let id = this.props.patientNotesID;
    axios
      .post(
        this.url + `SignPatientNotes?patientNotesId=${id}&sign=true`,
        "",
        this.config
      )
      .then((response) => {
        Swal.fire("Note Signed Successfully", "", "success").then(() => {
          if (this.props.refresh) this.props.history.push("/ClinicalNotes");
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        Swal.fire("Something Went Wrong", "", "error");
        this.setState({ loading: false });
      });
  };
  setPosition = () => {
    let reaction = [
      { id: "", description: "Please Select" },
      { id: 0, description: "Unknown" },
      { id: 1, description: "Skin Rash" },
      { id: 2, description: "Hives" },
      { id: 3, description: "Respiratory Distress" },
      { id: 4, description: "Laryngeal Edema" },
      { id: 5, description: "Asthmatic Attack" },
      { id: 6, description: "Syncope" },
      { id: 7, description: "Anaphylaxis" },
      { id: 8, description: "Other" },
      { id: 9, description: "Diarrhea" },
      { id: 10, description: "Dizziness" },
      { id: 11, description: "Wheezing" },
    ];

    let severity = [
      { id: "", description: "Please Select" },
      { id: 1, description: "Fatal" },
      { id: 2, description: "Severe" },
      { id: 3, description: "Moderate to Severe" },
      { id: 4, description: "Moderate" },
      { id: 5, description: "Mild to Moderate" },
      { id: 6, description: "Mild" },
    ];

    let allergyType = [
      { id: "", description: "Please Select" },
      { id: 1, description: "Food Intolerance" },
      { id: 2, description: "Food Allergy" },
      { id: 3, description: "Drug Allergy" },
      { id: 4, description: "Propensity to Adverse Reactions to Substance" },
      { id: 5, description: "Propensity to Adverse Reactions to Food" },
      { id: 6, description: "Allergy to Substance" },
      { id: 7, description: "Propensity to Adverse Reactions to Drug" },
      { id: 8, description: "Propensity to Adverse Reactions" },
      { id: 9, description: "Drug Intolerance" },
    ];
    let genderIdentity = [
      { id: 446151000124109, description: "Male" },
      {
        id: 407376001,
        description: " Female-to-Male (FTM)/Transgender Male/Trans Man",
      },
      { id: 446141000124107, description: "female" },
      {
        id: 446131000124102,
        description: "Genderqueer, neither exclusively male nor female",
      },
      { id: "OTH", description: "Other" },
      { id: "UNK", description: "Unknown" },
      { id: "ASKU", description: "Choose not to disclose" },
      //  { id:  ,description: },
      //  { id:  ,description: },
      //  { id:  ,description: },
    ];
    let sexualOrientation = [
      {
        id: 20430005,
        description: "Heterosexual (not lesbian, gay, or bisexual)",
      },
      { id: 38628009, description: "Lesbian or gay" },
      { id: 42035005, description: "Bisexual" },
      {
        id: 446131000124102,
        description: "Genderqueer, neither exclusively male nor female",
      },
      { id: "OTH", description: "Other" },
      { id: "UNK", description: "Unknown" },
      { id: "ASKU", description: "Choose not to disclose" },
      //  { id:  ,description: },
      //  { id:  ,description: },
      //  { id:  ,description: },
    ];
    let tobaccoStatus = [
      { id: 449868002, description: "Current every day smoker" },
      { id: 8517006, description: "Former smoker" },
      { id: 266919005, description: "Never smoker" },
      { id: 77176002, description: "Smoker, current status unknown" },
      { id: 266927001, description: "Unknown if ever smoked" },
      { id: 428071000124103, description: "Heavy tobacco smoker" },
      { id: 42806100012410, description: "Light tobacco smoker" },
      //  { id:  ,description: },
      //  { id:  ,description: },
      //  { id:  ,description: },
    ];

    // let  =
    //   this.state.saveModel.tobaccoStatus ==
    //     ?
    //     : this.state.saveModel.tobaccoStatus ==
    //     ?
    //     : this.state.saveModel.tobaccoStatus ==
    //     ?
    //     : this.state.saveModel.tobaccoStatus ==
    //     ?
    //     : this.state.saveModel.tobaccoStatus ==
    //     ?
    //     : this.state.saveModel.tobaccoStatus == ""
    //     ?
    //     : this.state.saveModel.tobaccoStatus == ""
    //     ?
    //     : "";


    const positionArray = [
      {
        name: "Amendments",
        Component:
         this.state.amendments.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Amendments</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">File Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Added By</th>
                      <th scope="col">Notes</th>
                    </tr>
                    { this.state.amendments &&
                      this.state.amendments.map((row, index) => (
                        <tr>
                          <>
                            <th scope="row">{index + 1}</th>
                            <td>{row.FileName}</td>
                            <td>{row.Status}</td>
                            <td>{row.AmendmentDate}</td>
                            <td>{row.AddedBy}</td>
                            <td>{row.Notes}</td>
                          </>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },
      {
        name: "Allergies",
        Component:
          this.state.patientAllergy.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Allergies</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Allergy Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Allergy Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Reaction</th>
                      <th scope="col">Severity</th>
                    </tr>

                    {this.state.patientAllergy &&
                      this.state.patientAllergy.map((row, index) => (
                        <>
                          {row.isArchive ? null : (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{row.allergyName}</td>
                                <td>{row.dos}</td>
                                <td>
                                  {" "}
                                  {allergyType.map((row2) =>
                                    row2.id === parseInt(row.AllergyType)
                                      ? row2.description === "Please Select"
                                        ? ""
                                        : row2.description
                                      : ""
                                  )}
                                </td>
                                <td>
                                  {row.isArchive ? "In Active" : "Active"}
                                </td>
                                <td>
                                  {reaction.map((row2) =>
                                    row2.id === row.reactionId
                                      ? row2.description === "Please Select"
                                        ? ""
                                        : row2.description
                                      : ""
                                  )}
                                </td>
                                <td>
                                  {severity.map((row2) =>
                                    row2.id === parseInt(row.severityCode)
                                      ? row2.description === "Please Select"
                                        ? ""
                                        : row2.description
                                      : ""
                                  )}
                                </td>
                              </tr>
                              {row.comment ? (
                                <td colSpan="12">
                                  <b>Comments :</b> {row.comment}
                                </td>
                              ) : null}
                            </>
                          )}{" "}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },
      {
        name: "Medication",
        Component:
          this.state.patientPrescription.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Medication</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Prescription</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Directions</th>
                      <th scope="col">Expires</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Refills</th>
                    </tr>
                    { this.state.patientPrescription &&
                       this.state.patientPrescription.map((row, index) => (
                        <tr>
                          {/* {row.IsArchive ? ( */}
                            <>
                              <th scope="row">{index + 1}</th>
                              <td>{row.Prescription}</td>
                              <td>{row.IsArchive ? "Active" : "In Active"}</td>
                              {row.PrescriptionSigs &&
                                row.PrescriptionSigs.map((item, index) => (
                                  <>
                                    <td>{item.AddedDate}</td>
                                    <td>{item.Description}</td>
                                  </>
                                ))}
                              <td>{row.ExpiryDate}</td>
                              <td>{row.Quantity}</td>
                              <td>{row.Refills}</td>
                            </>
                     
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },


      {
        name: "Social History",
        Component: !isNull(this.state.patientSocialHistroy)? (
          <div class="form-row font-weight-normal ">
            <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
              <h6 class="pl-2 pt-1">Social History</h6>
            </div>
            <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
              <div class="form-row p-0 m-0">
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr className="text-center">
                          <td class="detailednotes-report-font" colSpan="2">
                            Marital Status
                          </td>
                        </tr>
                        {!isNull(this.state.patientSocialHistroy.homeEnvironment) ? (
                          <tr>
                            <td class="detailednotes-report-font">
                              Home Environment
                            </td>
                            <td>
                              {!isNull(this.state.patientSocialHistroy)
                                ? this.state.patientSocialHistroy.homeEnvironment
                                : ""}
                            </td>
                          </tr>
                        ) : null}
                        {!isNull(this.state.patientSocialHistroy.children) ? (
                          <tr>
                            <td class="detailednotes-report-font">Children</td>
                            <td>
                              {!isNull(this.state.patientSocialHistroy)
                                ? this.state.patientSocialHistroy.children
                                : ""}
                            </td>
                          </tr>
                        ) : null}
                        {!isNull(this.state.patientSocialHistroy.heighestEducation )? (
                          <tr>
                            <td class="detailednotes-report-font">
                              Highest Education
                            </td>
                            <td>
                              {!isNull(this.state.patientSocialHistroy)
                                ? this.state.patientSocialHistroy.heighestEducation
                                : ""}
                            </td>
                          </tr>
                        ) : null}
                        {!isNull(this.state.patientSocialHistroy.occupation) ? (
                          <tr>
                            <td class="detailednotes-report-font">
                              Occupation:
                            </td>
                            <td>
                              {!isNull(this.state.patientSocialHistroy)
                                ? this.state.patientSocialHistroy.occupation
                                : ""}
                            </td>
                          </tr>
                        ) : null}
                        {!isNull(this.state.patientSocialHistroy.sexualOrientation) ? (
                          <tr>
                            <td class="detailednotes-report-font">
                              Sexual Orientation
                            </td>
                            <td>
                              {/* {this.state.patientSocialHistroy
                            ? this.state.patientSocialHistroy.sexualOrientation
                            : ''} */}

                              {sexualOrientation.map((row2) =>
                                 !isNull(this.state.patientSocialHistroy.genderIdentity)?
                                String(row2.id) ===
                                String(this.state.patientSocialHistroy.sexualOrientation)
                                  ? row2.description === "Please Select"
                                    ? ""
                                    : row2.description
                                  : "":""
                              )}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td class="detailednotes-report-font">
                            Gender Identity
                          </td>
                          <td>
                            {/* {this.state.patientSocialHistroy
                            ? this.state.patientSocialHistroy.this.state.patientSocialHistroy.genderIdentity
                            : ''} */}
                            {/* {this.state.patientSocialHistroy.genderIdentity
                                  ? ""
                                  : genderIdentity} */}

                            {genderIdentity.map((row2) =>
                            !isNull(this.state.patientSocialHistroy.genderIdentity)?
                            String(row2.id) ===  String(this.state.patientSocialHistroy.genderIdentity)
                                ? row2.description === "Please Select"
                                  ? ""
                                  : row2.description
                                : "":""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td
                            class="detailednotes-report-font text-center"
                            colSpan="2"
                          >
                            Risk Factors
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Exercise</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy.drugUse)
                              ? this.state.patientSocialHistroy.Excercise
                              : ""}
                          </td>
                        </tr>
                        {!isNull(this.state.patientSocialHistroy.drugUse)? (
                          <tr>
                            <td class="detailednotes-report-font">Drug Use</td>
                            <td>
                              {!isNull(this.state.patientSocialHistroy)
                                ? this.state.patientSocialHistroy.drugUse
                                : ""}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td class="detailednotes-report-font">Quit Date</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.quitDate
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Seatbelts:</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.seatBelts
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Exposure</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.Exposure
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td
                            class="detailednotes-report-font text-center"
                            colSpan="2"
                          >
                            Tobacco/Smoking Use
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Status</td>
                          <td>
                            {/* {this.state.patientSocialHistroy
                            ? this.state.patientSocialHistroy.tobaccoStatus
                            : ''} */}

                            {tobaccoStatus.map((row2) =>
                            !isNull(this.state.patientSocialHistroy.tobaccoStatus)?
                            
                              String(row2.id) === String(this.state.patientSocialHistroy.tobaccoStatus)
                                ? row2.description === "Please Select"
                                  ? ""
                                  : row2.description
                                : "":""
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">
                            Tobacco Type
                          </td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.tobaccoType
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">
                            Year Started
                          </td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.yearStarted
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Pack(s)/Day</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.packPerDay
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Start Date</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.startdate
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">End Date</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.enddate
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">
                            Tobacco Cessation
                          </td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.tobaccoCessation
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td
                            class="detailednotes-report-font text-center"
                            colSpan="2"
                          >
                            Alcohol/Caffeine Use
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">Alcohol Use</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.alcoholUse
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">
                            Caffeine Use
                          </td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.caffeineUse
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td class="detailednotes-report-font">ETOH</td>
                          <td>
                            {!isNull(this.state.patientSocialHistroy)
                              ? this.state.patientSocialHistroy.ETOH
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null,
      }
      ,
      {
        name: "Assessment & Diagnosis",
        Component:
        this.state.problemlist.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Assessment & Diagnosis</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <div class="table-responsive">
                  <table class="table table-sm font-weight-normal detailednotes-tr-height">
                    <tbody>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">ICD</th>
                        <th scope="col">Desc</th>
                        <th scope="col">Snomed</th>
                        <th scope="col">Snomed Desc</th>
                        <th scope="col">Status</th>
                      </tr>
                      {this.state.problemlist.length>0 &&
                         this.state.problemlist.map((row, index) => (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{row.dos}</td>
                            <td>{row.icdCode}</td>
                            <td>{row.icdDesc}</td>
                            <td>{row.snomedCode}</td>
                            <td>{row.snomedDesc}</td>
                            <td>{row.status}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null,
      },
      {
        name: "Forms",
        Component: <div></div>,
      },
      {
        name: "Vitals",
        Component: this.state.vitals ? (
          <div class="form-row font-weight-normal">
            <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
              <h6 class="pl-2 pt-1">Vitals</h6>
            </div>
            <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
              <div class="form-row text-center p-0 m-0 mb-2">
                <div class="form-group col-2 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="detailednotes-report-font">Height</td>
                          <td>
                            {this.state.vitals.Height_foot ? (
                              <>{this.state.vitals.Height_foot} ft </>
                            ) : null}
                            {this.state.vitals.Height_inches ? (
                              <>{this.state.vitals.Height_inches} in</>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="detailednotes-report-font">Weight</td>
                          <td>
                            {this.state.vitals.Weight_lbs ? (
                              <>{this.state.vitals.Weight_lbs} lbs </>
                            ) : null}
                            {this.state.vitals.Weight_OZ ? (
                              <>{this.state.vitals.Weight_OZ} oz </>
                            ) : null}
                            {this.state.vitals.Weight_Kg ? (
                              <>{this.state.vitals.Weight_Kg} kg </>
                            ) : null}
                            {this.state.vitals.Weight_gram ? (
                              <>{this.state.vitals.Weight_gram} gm </>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-2 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="detailednotes-report-font">BMI</td>
                          <td width="40%">
                            {this.state.vitals.BMI ? (
                              <>{this.state.vitals.BMI} kg</>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="detailednotes-report-font">
                            Head Circumference
                          </td>
                          <td>
                            {this.state.vitals.HeadCircumference ? (
                              <>{this.state.vitals.HeadCircumference} lbs</>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="detailednotes-report-font">
                            Temperature
                          </td>
                          {this.state.vitals.temperatureFahrenheit ||
                          this.state.vitals.temperatureCelsius ? (
                            <td>
                              {this.state.vitals.temperatureFahrenheit ? (
                                <>{this.state.vitals.temperatureFahrenheit} F</>
                              ) : (
                                <>{this.state.vitals.temperatureCelsius} C</>
                              )}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                        <tr>
                          <td className="detailednotes-report-font">
                            Respiratory Rate
                          </td>
                          <td>
                            {this.state.vitals.Respiratory_rate ? (
                              <>{this.state.vitals.Respiratory_rate} rpm</>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-2 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="detailednotes-report-font">Pulse</td>
                          <td>
                            {this.state.vitals.Pulse ? (
                              <>{this.state.vitals.Pulse} bpm</>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="detailednotes-report-font">BP</td>
                          {this.state.vitals.BPSystolic ||
                          this.state.vitals.BPDiastolic ? (
                            <td>
                              {this.state.vitals.BPSystolic ? (
                                <>{this.state.vitals.BPSystolic}</>
                              ) : null}{" "}
                              /{" "}
                              {this.state.vitals.BPDiastolic ? (
                                <>{this.state.vitals.BPDiastolic} mm</>
                              ) : null}
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-group col-3 pb-0 mb-0">
                  <div class="table-responsive">
                    <table class="table table-bordered table-sm">
                      <tbody>
                        <tr>
                          <td className="detailednotes-report-font">
                            Oxygen Sat
                          </td>
                          <td>
                            {this.state.vitals.OxygenSaturation ? (
                              <>{this.state.vitals.OxygenSaturation} %</>
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <td className="detailednotes-report-font">
                            Pulse Oximetry
                          </td>
                          <td>
                            {this.state.vitals.Pulse ? (
                              <>{this.state.vitals.Pulse} bpm</>
                            ) : null}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null,
      },
      {
        name: "Medical Notes",
        Component: this.state.patientMedicalNotes ? (
          <div class="form-row font-weight-normal">
            <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
              <h6 class="pl-2 pt-1">Medical Notes</h6>
            </div>

            <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless mediNotesUL">
              {ReactHtmlParser(this.state.patientMedicalNotes.note_html)}
            </div>
          </div>
        ) : null,
      }
      ,
      {
        name: "Functional And Cognitive Status",
        Component:
          this.state.functionalCognitiveStatus.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Functional And Cognitive Status</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Module Type</th>
                      <th scope="col">Date</th>
                      <th scope="col">Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Code Type</th>
                      <th scope="col" width="55%">
                        Desc
                      </th>
                    </tr>
      
                    { this.state.functionalCognitiveStatus &&
                       this.state.functionalCognitiveStatus.map((row, index) => (
                        <>
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{row.moduleType}</td>
                            <td>{row.date}</td>
                            <td>{row.code}</td>
                            <td>{row.status}</td>
                            <td>{row.codeType}</td>
                            <td style={{ textAlign: "justify" }}>
                              {row.description}
                            </td>
                          </tr>
                          {row.note ? (
                            <td colSpan="12">
                              <b>Notes :</b> {row.note}
                            </td>
                          ) : null}
                        </>
                      ))}

                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
    },
      {
        name: "Immunization",
        Component:
          this.state.patientImmunization.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Immunization</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Immunization Name</th>
                      <th scope="col">Date</th>
                      <th scope="col">Route</th>
                      <th scope="col">Dose</th>
                      <th scope="col">Manufacturer Info</th>
                    </tr>

                    {this.state.patientImmunization.length>0 &&
                      this.state.patientImmunization.map((row, index) => (
                        <>
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{row.immunizationName}</td>
                            <td>{this.props.visitDate}</td>
                            <td> {row.routeDescription} </td>
                            <td>
                              {row.dose} {row.units}
                            </td>
                            <td>{row.manufacturerDetail}</td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },


      {
        name: "Review of System",
        Component:
          this.state.patientROS.length > 0 ? (
            <div class="form-row font-weight-normal detailednotes-report-text">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Review of System</h6>
              </div>

              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <div class="form-row">
                  {this.state.patientROS &&
                    this.state.patientROS.map((row, index) => (
                      <div class="form-group col-6">
                        <>
                          <strong class="p-0 m-0">{row.RosItemname}</strong>
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <span
                                style={{
                                  listStyle: "inside",
                                  color: "grey",
                                }}
                              >
                                {this.renderAbnormalSentenses(row)}
                              </span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <span
                                style={{
                                  listStyle: "inside",
                                  color: "grey",
                                }}
                              >
                                {this.renderNormalSentenses(row)}
                              </span>
                            </div>
                          </div>
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : null,
      },
      {
        name: "RFV & HPI",
        Component: !isNull(this.state.patientHPI)? (
          <div class="form-row font-weight-normal">
            <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
              <h6 class="pl-2 pt-1">RFV & HPI</h6>
            </div>
            <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
              <div class="table-responsive">
                {this.state.patientHPI.reasonForVisit ||
               this.state.patientHPI.Location ||
               this.state.patientHPI.timing ||
               this.state.patientHPI.severity ||
               this.state.patientHPI.duration ||
               this.state.patientHPI.modifyingFactors ||
               this.state.patientHPI.assosciatedSymptoms ? (
                  <table class="table table-sm font-weight-normal detailednotes-tr-height">
                    <tbody>
                      <tr>
                        <th scope="col">Reason For Visit</th>
                        <th scope="col">Location</th>
                        <th scope="col">Timing</th>
                        <th scope="col">Severity</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Modifying Factor</th>
                        <th scope="col">Associated Symptons</th>
                      </tr>
                      <tr>
                        <td>
                          {!isNull(this.state.patientHPI)? this.state.patientHPI.reasonForVisit : ""}
                        </td>
                        <td>{!isNull(this.state.patientHPI) ? this.state.patientHPI.Location : ""}</td>
                        <td>{!isNull(this.state.patientHPI)? this.state.patientHPI.timing : ""}</td>
                        <td>{!isNull(this.state.patientHPI) ? this.state.patientHPI.severity : ""}</td>
                        <td>{!isNull(this.state.patientHPI) ? this.state.patientHPI.duration : ""}</td>
                        <td>
                          {!isNull(this.state.patientHPI) ? this.state.patientHPI.modifyingFactors : ""}
                        </td>
                        <td>
                          {!isNull(this.state.patientHPI) ? this.state.patientHPI.assosciatedSymptoms : ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : null}
              </div>
              {!isNull(this.state.patientHPI.context) ? (
                <div class="form-row d-flex">
                  <strong style={{ display: "contents", textAlign: "justify" }}>
                    Context :
                  </strong>
                  {this.state.patientHPI.context}
                </div>
              ) : null}
              {!isNull(this.state.patientHPI.description) ? (
                <div class="form-row d-flex">
                  <strong style={{ display: "contents" }}>
                    Description :
                  </strong>
                  {this.state.patientHPI.description}
                </div>
              ) : null}
              {!isNull(this.state.patientHPI.report) ? (
                <div class="form-row d-flex">
                  <span style={{ display: "contents" }} className = "font-weight-bold">HPI:</span>
                  <span>{ReactHtmlParser(this.state.patientHPI.report)}</span>
                  
                </div>
              ) : null}
            </div>
          </div>
        ) : null,
      },
      {
        name: "Physical Exam",
        Component:
          this.state.patientPhysicalExam.length > 0 ? (
            <div class="form-row font-weight-normal detailednotes-report-text">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Physical Exam</h6>
              </div>

              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <div class="form-row">
                  {this.state.patientPhysicalExam &&
                    this.state.patientPhysicalExam.map((row, index) => (
                      <div class="form-group col-6">
                        <>
                          <strong class="p-0 m-0">
                            {!isNull(row.physicalExamItemname)? row.physicalExamItemname:""}
                          </strong>
                          <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <span
                                style={{
                                  listStyle: "inside",
                                  color: "black",
                                }}
                              >
                                {this.renderAbnormalSentenses(row)}
                              </span>
                            </div>
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <span
                                style={{
                                  listStyle: "inside",
                                  color: "black",
                                }}
                              >
                                {this.renderNormalSentenses(row)}
                              </span>
                            </div>
                          </div>
                        </>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : null,
      },


      {
        name: "Patient Procedure",
        Component:
          this.state.patientProcedure.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                <h6 class="pl-2 pt-1">Patient Procedure</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col" width="3%">
                        #
                      </th>
                      <th scope="col" width="10%">
                        Date
                      </th>
                      <th scope="col" width="7%">
                        Code
                      </th>
                      <th scope="col" width="10%">
                        Status
                      </th>
                      <th scope="col" width="10%">
                        Type
                      </th>
                      <th scope="col" width="60%">
                        Description
                      </th>
                    </tr>
                    { this.state.patientProcedure &&
                    this.state.patientProcedure.map((row, index) => (
                        <>
                          {row.status ? (
                            <tr className = "borderTop">
                              <th scope="row">{index + 1}</th>
                              <td>{this.props.visitDate}</td>
                              <td>{row.code}</td>

                              <td>{row.status ? "Active" : "In Active"}</td>
                              <td>{row.type}</td>
                              <td style={{ textAlign: "justify" }}>
                                {!isNull(row.description)?row.description:"" }
                              </td>
                            </tr>
                          ) : null}
                          {row.status ? (
                            <>
                              {row.note ? (
                                <td colSpan="12">
                                  <b>Notes :</b> {!isNull(row.note)?row.note :""}
                                </td>
                              ) : null}{" "}
                            </>
                          ) : null}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },
      {
        name: "Family Health History",
        Component:
          this.state.familyHistory.length > 0 ? (
            <div class="form-row font-weight-normal">
              <div
                class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header"
                style={{
                  pageBreakBefore: "auto",
                  pageBreakInside: "auto",
                }}
              >
                <h6 class="pl-2 pt-1">Family Health History</h6>
              </div>
              <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                <table class="table  table-sm font-weight-normal detailednotes-tr-height">
                  <tbody>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Date</th>
                      <th scope="col">ICD</th>
                      <th scope="col">Desc</th>
                      <th scope="col">RelationShip</th>
                    </tr>
                    {this.state.familyHistory &&
                      this.state.familyHistory.map((row, index) => (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{!isNull(row.AddedDate)?row.AddedDate : ""}</td>
                          <td>{row.icdCode}</td>
                          <td>{row.icdDesc}</td>
                          <td>{row.relationship}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null,
      },
    ];
    // const { module } = this.props;
    // let positionedComponent = [];
    // module.map((item, index) => {
    //   positionArray.map((position) => {
    //     if (item.displayName === position.name) {
    //       positionedComponent.push(position);
    //     }
    //   });
    // });

    // this.setState({ positionedComponent });

    this.setState({
      positionArray: positionArray,
    });
  };
  renderAbnormalSentenses = (i) => {
    let abNormalSentense = "";
    i.organname.forEach((j) => {
      if (j.normal !== true) {
        abNormalSentense = isNull(abNormalSentense)
          ? isNull(j.comments)
            ? j.organName
            : j.organName + " (" + j.comments + ")"
          : isNull(j.comments)
          ? abNormalSentense + ", " + j.organName
          : abNormalSentense + ", " + j.organName + " (" + j.comments + ")";
      }
    });
    return isNull(abNormalSentense) ? null : (
      <div className="w-100">
        <span style={{ color: "black" }}>
          Patient reported symptoms for {abNormalSentense}
        </span>
      </div>
    );
  };
  renderNormalSentenses = (i) => {
    let normalSentense = "";
    i.organname.forEach((j) => {
      if (j.normal === true) {
        normalSentense = isNull(normalSentense)
          ? j.organName
          : normalSentense + ", " + j.organName;
      }
    });

    return isNull(normalSentense) ? null : (
      <div className="w-100">
        <span style={{ color: "black" }}>
          Patient didn't reported symptoms for {normalSentense}
        </span>
      </div>
    );
  };
  
  render() {
    const { name, visitDate, signedBy, signedDate } = this.props;
    return (
      <>
        <div className="ClinicalDetailReport container-fluid p-0 m-0">
          {/* <Draggable
            bounds={{ top: -25, left: -600, right: 600, bottom: 400 }}
            handle="patientdragger"
            // axis="x"
            //  defaultPosition={{x: 0, y: 0}}
            // allowAnyClick="true"
          > */}
            <div
              className="modal fade show"
              id="SchedularModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="popup-model"
              aria-hidden="true"
              onKeyDown={this.props.keyHandling}
            >
              
                <div
                  className="modal-content w-50"
                  style={{
                    minHeight: "575px",
                    overflowX: "hidden",
                  }}
                >
                  <Spiner loading={this.state.loading} />
                  <patientdragger>
                    <div className="custom modal-header">
                      <h4 className="modal-title m-0 p-0" id="popup-model">
                        Clinical Notes
                      </h4>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.props.closeModal}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </patientdragger>

                  <div
                    className="print-source detailednotes-greyborder detailednotes-header"
                    style={{ height: "25px" }}
                  >
                    <div className="row">
                      <div
                        className="col-12 text-right pt-1"
                        style={{ paddingRight: "30px" }}
                      >
                        <ReactToPrint
                          trigger={() => (
                            <Link
                              style={{
                                cursor: "pointer",
                                borderRight: "1.5px solid #ccc",
                                color: "#007bff",
                              }}
                              className="pr-1"
                            >
                              Print
                            </Link>
                            // <PrintIcon style={{ cursor: "pointer" }} />
                          )}
                          content={() => this.componentRef}
                        />
                    
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal-body p-0 m-0 mt-1"
                    style={{ overflowY: "auto",    maxHeight: "582px"}}
                  >
                    <div
                      ref={(el) => (this.componentRef = el)}
                      className="container detail-report"
                      style={{ pageBreakBefore: "always" }}
                    >
                      <div class="form-row pt-2 pb-2">
                        <div class="form-group col-md-2 com-sm-12">
                          {/* <img src="logo.png" width="80px" height="80px" /> */}
                        </div>
                        <div class="form-group col-12 text-center">
                          <h3>{!isNull(this.state.practiceData)? this.state.practiceData.name: ""}</h3>
                          <p>
                            <b>Address: </b>
                            {!isNull(this.state.practiceData)?this.state.practiceData.address:""}
                          </p>

                          <p>
                            <b>Phone: </b>
                            {!isNull(this.state.practiceData)?this.state.practiceData.phoneNumber:""} &nbsp;{" "}
                            {this.state.practiceData? (
                              <>
                                {" "}
                                <b>WebSite</b> {!isNull(this.state.practiceData.website)?this.state.practiceData.website:""}
                              </>
                            ) : null}
                            &nbsp;{" "}
                            {!isNull(this.state.practiceData)? (
                              <>
                                <b>Fax</b> {this.state.practiceData.faxNumber}{" "}
                              </>
                            ) : null}
                          </p>
                          {/* <div className="print-source">
                          <ReactToPrint
                            trigger={() => (
                              <PrintIcon style={{ cursor: "pointer" }} />
                            )}
                            content={() => this.componentRef}
                          />
                           <i
                        title="Sign Clinical Notes"
                        class="fas fa-file-signature"
                        onClick={this.sign}
                        style={{padding:"20px"}}
                      ></i>
                    </div> */}
                        </div>
                      </div>

                      <div
                        class="form-row border-bottom"
                        style={{ fontWeight: 400 }}
                      >
                        <div class="form-group col-8 p-0 m-0">
                          <div class="w-100">
                            <p class="d-inline">
                              <b>Patient Name:</b>
                              {!isNull(this.state.summarypatientHeader.PatientName)?this.state.summarypatientHeader.PatientName:""}
                            </p>
                            <p class="d-inline pl-2">
                              <b>DOB:</b> {!isNull(this.state.summarypatientHeader.DOB)?this.state.summarypatientHeader.DOB:""}
                              &nbsp;
                              <b>Age:</b>
                              {!isNull(this.state.summarypatientHeader.age)?this.state.summarypatientHeader.age:""}
                              {!isNull(this.state.summarypatientHeader.Gender)?this.state.summarypatientHeader.Gender:""}
                            </p>
                          </div>
                          <div class="w-100 pt-1">
                            <p class="d-inline">
                              <b>Address:</b>
                              {!isNull(this.state.summarypatientHeader.Address)?this.state.summarypatientHeader.Address:""}
                            </p>
                            <p class="d-inline pl-2">
                              <b>Cell #:</b>{" "}
                              {
                               !isNull(this.state.summarypatientHeader.phoneNumber)? this.state.summarypatientHeader.phoneNumber:""
                              }
                            </p>
                          </div>
                        </div>
                        <div class="form-group col-4 p-0 m-0 text-right ">
                          <div class="w-100">
                            <p class="d-inline w-100">
                              <b>Provider Name:</b> {!isNull(name)?name:""}
                            </p>
                          </div>
                          <div class="w-100 pt-1">
                            <p class="d-inline w-100">
                              <b>Visit Date:</b> {!isNull(visitDate)?visitDate:""}
                            </p>
                          </div>
                        </div>
                      </div>
                      {this.state.positionArray &&
                        this.state.positionArray.map((item) => item.Component)}
                      {!isNull(signedBy) ? (
                        <div class="form-row font-weight-normal mb-2">
                          <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                            <div class="form-row">
                              <div class="form-group col-6 d-flex">
                                <p class="pl-2 pt-1">Signed By : {signedBy}</p>
                              </div>
                              <div class="form-group col-6 d-flex">
                                <p class="pl-2 pt-1">
                                  Signed Date : {!isNull(signedDate) ? signedDate :""}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              
            </div>
          {/* </Draggable> */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    setupLeftMenu: state.leftNavigationMenus,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInfo:
      state.loginInfo != null
        ? state.loginInfo
        : { userPractices: [], name: "", practiceID: null },
    notesModules: state.notesModules,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      setNotesModules: setNotesModules,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(ClinicalDetailReports)
);
