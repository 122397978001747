import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import $ from "jquery";

import phrlogo from "../images/phr-logo.png";
import medicalicon from "../images/medical-icon.png";
import userlogin from "../images/user-login-ico.png";
import "../css/bootstrap.min.css";

import axios from "axios";
import isNull from "../functions/nullChecking";

//Redux Actions
import { loginAction } from "../Actions/LoginAction";
import { userInfo } from "../Actions/userInfo";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Eclips from "../images/loading_spinner.gif";
import GifLoader from "react-gif-loader";

import Swal from "sweetalert2";

class Login extends Component {
  constructor(props) {
    super(props);

    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    this.commonUrl = process.env.REACT_APP_URL + "/Common/";

    this.loginModel = {
      email: "",
      password: "",
    };
    this.searchModel = {
      confirmPassword: "",
      email: "",
      password: "",
    };

    this.validationModel = {
      passwordValField: "",
      cnfrmPassValField: "",
      emailVal: "",
      emailValField: "",
      validation: true,
    };

    this.state = {
      loginModel: this.loginModel,
      gettingUrl: "",
      searchModel: this.searchModel,
      validationModel: this.validationModel,
      loading: false,
      forgotPasswordPopup: false,
      email: "",
      token: "",
      sendEmail: "",
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.enterKeyLogin = this.enterKeyLogin.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.handleForgetPassChange = this.handleForgetPassChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      gettingUrl: this.props.url
        ? this.props.url
        : window.location.href.indexOf("ClientLogin") > 0
        ? window.location.href
        : "",
    });
  }
  enterKeyLogin(event) {
    if (event.charCode === "13") {
      this.handleLogin();
    }
  }
  handleForgetPassChange(event) {
    this.setState({
      searchModel: {
        ...this.state.searchModel,
        [event.target.name]: event.target.value,
      },
    });
  }
  forgotPassword(event) {
    event.preventDefault();
    this.setState({ forgotPasswordPopup: true });
  }
  closePasswordPopup = () => {
    $("#myModal").hide();
    this.setState({ forgotPasswordPopup: false });
  };
  handleLogin = async () => {
    var loginFailed = 0;
    var myVal = this.validationModel;
    myVal.validation = false;
    if (isNull(this.state.loginModel.email)) {
      myVal.emailValField = (
        <span className="text-danger">Please enter your email </span>
      );
      myVal.validation = true;
    } else {
      myVal.emailValField = "";
      if (myVal.validation === false) myVal.validation = false;
    }
    if (isNull(this.state.loginModel.password)) {
      myVal.passwordValField = (
        <span className="text-danger">Please enter your password </span>
      );
      myVal.validation = true;
    } else {
      myVal.passwordValField = "";
      if (myVal.validation === false) myVal.validation = false;
    }
    if (myVal.validation === true) {
      this.setState({ loading: false, validationModel: myVal });
      return;
    }

    try {
      this.setState({ loading: true });
      var token = "";
      await axios
        .post(this.url + "PatientLogin", this.state.loginModel)
        .then((response) => {
          token = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status) {
              if (error.response.status === "404") {
                Swal.fire("LOGIN FAILED", "No User Found", "error").then(
                  (sres) => {
                    this.props.history.push("/");
                  }
                );
                loginFailed = -1;
                return;
              } else if (error.response.status === "400") {
                if (error.response.data) {
                  Swal.fire(
                    "LOGIN FAILED",
                    error.response.data + "",
                    "error"
                  ).then((sres) => {
                    this.props.history.push("/");
                  });
                }
                loginFailed = -1;
                return;
              } else if (error.response.status === "401") {
                Swal.fire("LOGIN FAILED", "UnAuthorized Access", "error").then(
                  (sres) => {
                    this.props.history.push("/");
                  }
                );
                return;
              } else {
                Swal.fire(
                  "LOGIN FAILED",
                  error.response.data + "",
                  "error"
                ).then((sres) => {
                  this.props.history.push("/");
                });
                loginFailed = -1;
                return;
              }
            }
          }
          loginFailed = 1;
        });

      if (loginFailed === 1) {
        await this.setState({ loading: false });
        Swal.fire("LOGIN FAILED", "Internal Server Error", "error");
        this.props.history.push("/");
        return;
      } else if (loginFailed === -1) {
        this.setState({ loading: false });
        return;
      }

      this.config = {
        headers: {
          Authorization: "Bearer  " + token,
        },
      };
      // Get User Info
      await axios
        .get(this.url + "GetPatientInfo", this.config)
        .then((response) => {
          this.props.loginAction(token, true);
          this.props.userInfo(response.data);
          this.setState({ loading: false });

          if (this.state.gettingUrl !== "") {
            if (this.state.gettingUrl.indexOf("ClientLogin") > 0) {
              this.props.history.push("/ClientScheduler");
            } else {
              let a = "";
              a = this.state.gettingUrl.substr(
                this.state.gettingUrl.indexOf("=" + 1)
              );
              if (a === "=1") a = a.replace("=", "");
              this.props.selectTabAction("NewPatient", a);
              this.props.history.push("/NewPatient");
            }
          } else {
            this.props.history.push("/Dashboard");
          }
        })
        .catch((error) => {
          this.props.history.push("/Dashboard");
          this.setState({ loading: false });
          Swal.fire("ERROR", "Information not Found", "error").then((sres) => {
            // this.props.history.push("/Dashboard");
          });
        });
    } catch {
      this.setState({ loading: false });
    }
    this.setState({ loading: false });
    // window.location.reload(true) ;
  };

  handleChange(event) {
    this.setState({
      loginModel: {
        ...this.state.loginModel,
        [event.target.name]: event.target.value,
      },
    });
  }

  handlePasswordHyperChange = (event) => {
    event.preventDefault();
    this.setState({
      sendEmail: event.target.value,
    });
  };
  sendEmail = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    var myVal = this.validationModel;
    myVal.validation = false;
    if (isNull(this.state.sendEmail)) {
      myVal.emailVal = <span className="text-danger">Enter Email</span>;
      myVal.validation = true;
    } else {
      myVal.emailVal = "";
      if (myVal.validation === false) myVal.validation = false;
    }

    this.setState({
      validationModel: myVal,
      // {...this.state.validationModel,
      // emailVal :  myVal.emailVal
      // },
    });

    if (myVal.validation === true) {
      this.setState({ loading: false });
      return;
    }

    axios
      .get(this.url + "SendPasswordResetLink/" + this.state.sendEmail)
      .then((response) => {
        Swal.fire("Email Send Successfully", "", "success");
        this.props.history.push("/");
        this.setState({ loading: false });
      })

      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          if (error.response.status) {
            if (error.response.status === 400) {
              Swal.fire("", error.response.data, "error");
            }
          }
        }
      });
  };
  canclePassword(e) {
    e.preventDefault();
    // window.history.pushState({ urlPath: "/" }, "", "/login");
    // this.props.history.push("/");
    window.location.reload();
  }
  render() {
    let popup = "";
    // if (this.state.forgotPasswordPopup) {
    //   popup = (
    //     <SendEmail
    //       onClose={() => this.closePasswordPopup}
    //       email={this.state.email}
    //     ></SendEmail>
    //   );
    // } else {
    //   popup = <React.Fragment></React.Fragment>;
    // }
    let passwordHyperLink = (
      <form className="user p-4">
        <div>
          {this.state.forgotPasswordPopup === true ? (
            <p
              className="pt-2"
              style={{
                width: "100%",
                contain: "content",
                color: "#04729e",
                fontSize: "16px",
                fontWeight: "600",
                display: "inline-block",
              }}
            >
              <span style={{ display: "inline-block", marginTop: "-7px" }}>
                Forget Password?
              </span>
            </p>
          ) : null}
          <input
            style={{ borderRadius: "5px" }}
            type="email"
            className="form-control form-control-user"
            aria-describedby="emailHelp"
            placeholder="Enter Email Address..."
            value={this.state.sendEmail}
            name="sendEmail"
            id="sendEmail"
            onChange={(e) => this.handlePasswordHyperChange(e)}
          />
        </div>
        <div>{this.state.validationModel.emailVal}</div>

        <div className="clearfix"></div>
        <br></br>
        <button
          style={{ marginTop: "-6px" }}
          className="btn-phr  mr-2 float-right"
          onClick={(event) => this.canclePassword(event)}
        >
          Cancel
        </button>
        <button
          style={{ marginTop: "-6px" }}
          className="btn-phr mr-2 float-right"
          onClick={(event) => this.sendEmail(event)}
        >
          Send Email
        </button>
      </form>
    );
    let spiner = "";
    if (this.state.loading === true) {
      spiner = (
        <div className="spiner">
          <GifLoader
            loading={true}
            imageSrc={Eclips}
            imageStyle={{ marginTop: "20%", width: "100px", height: "100px" }}
            overlayBackground="rgba(0,0,0,0.5)"
          />
        </div>
      );
    }

    // let forgetPass = (
    //   <div className="mf-6" style={{ marginRight: "0px" }}>
    //     <div>
    //       <div className="mf-12 text-center">

    //       </div>
    //       <div className="mf-12 pL-75">
    //         <p>New Password</p>
    //         <input
    //           type="password"
    //           value={this.state.searchModel.password}
    //           name="password"
    //           id="password"
    //           onChange={this.handleForgetPassChange}
    //         ></input>
    //         {this.state.validationModel.passwordValField}
    //       </div>
    //       <div className="mf-12 pL-75">
    //         <p> Confirm Password </p>
    //         <input
    //           type="password"
    //           value={this.state.searchModel.confirmPassword}
    //           name="confirmPassword"
    //           id="confirmPassword"
    //           onChange={this.handleForgetPassChange}

    //         ></input>
    //         <p>Enter combination of at least 5 letters,numbers and symbols</p>
    //         {this.state.validationModel.cnfrmPassValField}
    //       </div>
    //       <div className="mf-12 pL-75" style={{ marginLeft: "40%" }}>
    //         <button class="btn-blue" onClick={this.savePassword}>
    //           Save
    //         </button>
    //         <button
    //           class="btn-grey"
    //           style={{
    //             marginTop: "2%",
    //             marginRight: "2%",
    //             marginBottom: "2%",
    //             marginLeft: "2%",
    //           }}
    //           onClick={this.canclePassword}
    //         >
    //           Cancel
    //         </button>

    //       </div>
    //     </div>
    //   </div>
    // );

    return (
      <div className="login-bg">
        {spiner}
        <div className="container">
          <div className="row opcity justify-content-center">
            <div className="">
              <div
                className="card o-hidden border-0 cardwidth"
              >
                <div className="card-body p-0">
                  <div className="row">
                    <div className="">
                      <img
                        alt="logo"
                        className="pl-3 img-responsive pt-4 mb-0 pb-2 loginpagelogocontainer"
                        src={phrlogo}
                      />
                    </div>
                    <div className="float-right logintoprightlogo"> 
                      <img
                        className="float-right img-responsive pt-4 pr-3 height90"
                        src={medicalicon}
                        alt="Medical"
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="col-lg-12 pt-4 col-md-12 col-sm-12 float-right">
                        {this.state.forgotPasswordPopup ? (
                          passwordHyperLink
                        ) : (
                          <form className="user p-2">
                            <div>
                              <input
                                type="email"
                                value={this.state.loginModel.email}
                                name="email"
                                id="email"
                                onChange={this.handleChange}
                                className="form-control form-control-user"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                style={{ borderRadius: "5px" }}
                              />

                              <div>
                                {this.state.validationModel.emailValField}
                              </div>
                            </div>
                            <div>
                              <input
                                type="password"
                                value={this.state.loginModel.password}
                                name="password"
                                id="password"
                                onChange={this.handleChange}
                                onKeyPress={(event) =>
                                  this.enterKeyLogin(event)
                                }
                                className="form-control mt-2 form-control-user"
                                placeholder="Password"
                                style={{ borderRadius: "5px" }}
                              />
                              <div>
                                {" "}
                                {this.state.validationModel.passwordValField}
                              </div>
                              <div className="custom-control pl-0 pt-1 custom-checkbox float-right small">
                                <a
                                  href="/"
                                  className="btn-alink"
                                  onClick={(event) =>
                                    this.forgotPassword(event)
                                  }
                                  style={{ fontSize: "16px" }}
                                >
                                  Forgot Password
                                </a>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                            <br />

                            <Link
                              href="#"
                              className="btn-phr mr-2 "
                              onClick={this.handleLogin}
                              style={{ marginTop: "-50px" }}
                            >
                              Login
                            </Link>
                            {/* <button className="btn btn-primary mr-2" type="submit" style={{marginTop:"-6px"}} onClick={this.handleLogin}>Login</button> */}
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 blue1 f14 pt-0 pb-4 pl-4 pr-4 text-left">
                  MediFusion patient health records (PHR) facilitates patients
                  and Physician's office for better communication by providing
                  convenient
                  <br /> 24 x 7 access from the comfort and privacy of your own
                  place.
                </div>
              </div>
            </div>
          </div>
        </div>
        {popup}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      userInfo: userInfo,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Login)
);
