import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { loginAction } from "../Actions/LoginAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import $ from "jquery";

import logo from "../img/logo.png";
import img from "../img/img.png";
import { userInfo } from "../Actions/userInfo";
import ChangePassword from "./ChangePassword";
// import { createPopper } from '@popperjs/core'

class Header extends Component {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.searchModel = {
      fromDate: "01/01/2020",
      toDate: "12/30/2020",
      fromTime: "",
      toTime: "",
      LocationID: [1],
      ProviderID: [16],
      visitReasonID: "",
      status: "",
      accountNum: "",
      firstName: "",
      lastName: "",
      dob: "",
    };

    this.state = {
      searchModel: this.searchModel,
      changepasswordPopup: false,
    };

    this.changepassword = this.changepassword.bind(this);
  }

  // componentDidMount() {
  //   const button = $('#button')
  //   const tooltip = $('#tooltip')
  //   tooltip.hide()

  //   button.click = function () {
  //     tooltip.show()
  //     createPopper(button, tooltip, {
  //       placement: 'top',
  //     })
  //   }
  // }

  changepassword(e) {
    e.preventDefault();

    this.setState({ changepasswordPopup: true });
  }
  closePasswordPopup = () => {
    $("#myModal").hide();
    this.setState({ changepasswordPopup: false });
  };

  // Handle Logout
  handleLogout = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure, you want to Logout?",
      text: "",
      type: "warning",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout",
    }).then((result) => {
      if (result.value) {
        if (result.value === true) {
          this.props.history.push("/");
          window.location.reload();
        }
       
      }
    });
  };
  openAddnewModel = () => {
    this.setState({
      isOpen: true,
    });
  };

  render() {
    let popups = "";
    if (this.state.changepasswordPopup) {
      popups = (
        <ChangePassword
          onClose={() => this.closePasswordPopup}
          email={this.state.email}
        ></ChangePassword>
      );
    } else {
      popups = <React.Fragment></React.Fragment>;
    }
    let lastName = "";
    let firstName = "";
    // let practiceName = "";

    try {
      lastName = this.props.userInformation[0].lastName;
      firstName = this.props.userInformation[0].firstName;
      // practiceName = this.props.userInformation[0].practiceName;
    } catch {}

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-7">
            {/* <a href="/"> */}
            <img src={logo} alt="logo" style={{ height: "57px" }} />
            {/* </a> */}
          </div>

          <div className="text-right col-md-5">
            <div className="pr-2">
              {/* <label className="pt-3 pr-2">{practiceName}</label> */}
              <img src={img} alt="logo" className="circle-img pr-2" />

              <label className="pt-3">
                {this.props.userInformation
                  ? "" + firstName + " " + lastName
                  : "Welcome"}
              </label>
              <label className="pt-3">
                <div class="dropdown pt-1">
                  <a
                    className="dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    href="/"
                  >
                    <span className="caret"></span>
                  </a>

                  {/* position: absolute;
    transform: translate3d(-185px, 22px, 0px);
    top: 0px;
    left: 0px;
    width: 200px!important;
    will-change: transform */}
                  <ul
                    className="dropdown-menu dropdownman"
                    style={{
                      willChange: "transform",
                      width: "200px",
                      position: "absolute",
                      transform: "translate3d(-187px, 19px, 0px)",
                    }}
                  >
                    <li className="">
                      <a
                        href="/"
                        className="text-primary"
                        onClick={this.handleLogout}
                      >
                        <i class="fas fa-sign-out-alt pr-2"></i>
                        Sign out
                      </a>
                    </li>
                    <li className="">
                      <a
                        href="/"
                        className="text-primary"
                        onClick={this.changepassword}
                      >
                        <i class="fa fa-key pr-2" aria-hidden="true"></i>
                        Change Password
                      </a>
                    </li>
                  </ul>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-sm-12 flex-grow-12 p-0 m-0">
            <div class="pl-2 topnav">
              <label for="show-menu" class="show-menu">
                Show Menu
              </label>
              <input type="checkbox" id="show-menu" role="button" />
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/Dashboard");
                  }}
                >
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <i class="fa fa-home p-2">&nbsp;Dashboard</i>
                  </a>
                </li>
                <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/InternalMessages");
                  }}
                >
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <i class="fa fa-envelope p-2">&nbsp;Messages</i>
                  </a>
                </li>
                <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/Appointment");
                  }}
                >
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <i class="fa fa-calendar p-2">&nbsp;Appointments</i>
                  </a>
                </li>
                {/* <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/Documents");
                  }}
                >
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    
                    <i class="fa fa-folder-open p-2">&nbsp;Documents</i>
                  </a>
                </li> */}

                <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/ClinicalNotes");
                  }}
                >
                  <a
                    class="nav-link"
                    id="clinical-notes"
                    data-toggle="tab"
                    href="#clinical-notes"
                    role="tab"
                    aria-controls="clinical-notes"
                    aria-selected="false"
                  >
                    <i class="fa fa-building float-left p-2">
                      &nbsp;Clinical Notes
                    </i>
                  </a>
                </li>

                <li
                  class="nav-item p-0 m-0"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.history.push("/LabOrder");
                  }}
                >
                  <a
                    class="nav-link"
                    id="LabOrder"
                    data-toggle="tab"
                    href="#LabOrder"
                    role="tab"
                    aria-controls="LabOrder"
                    aria-selected="false"
                  >
                    <i class="fa fa-flask float-left p-2">&nbsp;Lab Order</i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {popups}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userInformation: state.userInformation,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userInfo: userInfo,
      loginAction: loginAction,
    },
    dispatch
  );
}
export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Header)
);

//export default Header
