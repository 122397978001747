import React, { Component } from "react";
import CheckoutForm from "./CheckoutForm";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginAction } from "../Actions/LoginAction";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { userInfo } from "../Actions/userInfo";
import isNull from "../functions/nullChecking";
import ComposeEmail from "./InternalMessages/ComposeInternalMessage";
import Spiner from "./Spiner";
import PatientDetails from "./PatientDetails";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ViewPaymentStatement from "./ViewPaymentStatement";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.url2 =
      process.env.REACT_APP_URL + "/PatientPortal/GetInternalMessages";

    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.searchModel = {
      fromDate: "01/01/2020",
      toDate: "12/30/2020",
      fromTime: "",
      toTime: "",
      LocationID: [1],
      ProviderID: [16],
      visitReasonID: "",
      status: "",
      accountNum: "",
      firstName: "",
      lastName: "",
      dob: "",
    };
    this.sendAttachmentModel = {
      document_address: [],
    };

    this.state = {
      FileName: "",
      promise: "",
      sendAttachmentModel: this.sendAttachmentModel,
      // responseOfAttachemnt: "",
      openStatementModal: false,
      modal: false,
      searchModel: this.searchModel,
      TotalBalance: "",
      dos: "",
      loading: true,
      PatientInfo: [{}],
      end: "",
      data: [],
      AllMessages: [],
      FollowupList: [],
      newList: [],
      table: [],
      arr: [],
      openComposeEmail: false,
      replyData: "",
      checkReply: false,

      Columns: [
        {
          label: "Date of Service",
          field: "dos",
          sort: "asc",
          width: 150,
        },
        {
          label: "Billed Amount",
          field: "billed",
          sort: "asc",
          width: 100,
        },
        {
          label: "Insurance Paid",
          field: "paid",
          sort: "asc",
          width: 150,
        },
        {
          label: "Patient Paid",
          field: "patientPaid",
          sort: "asc",
          width: 150,
        },
        {
          label: "Patient Balance",
          field: "patientBalance",
          sort: "asc",
          width: 150,
        },
      ],
    };
    // pk_test_51HPWJtIBQ68Ovn0xqAWkNtXoBcOOWXN5hHDys3bgWJGYbM0uEniFrijcSGcUMfUULvbQ8oA89QnmOvPs3j2rx94W00bDjrdAli

    // this.stripe =
    // !isNull(userInformation && userInformation[0].frontKey)
    //   ? loadStripe(userInformation[0].frontKey)
    //   :null;
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.userInformation &&
      nextProps.userInformation[0].frontKey !== nextProps.userInformation &&
      nextProps.userInformation[0].frontKey
    ) {
      if (
        nextProps.userInformation &&
        nextProps.userInformation[0].frontKey !== null &&
        nextProps.userInformation[0].frontKey !== ""
      ) {
        this.stripe = loadStripe(nextProps.userInformation[0].frontKey);
      }
    }
  }
  getData = async () => {
    this.setState({
      loading: true,
    });
    axios
      .get(this.url2 + "/Inbox", this.config)
      .then((response) => {
        this.setState({
          AllMessages: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.getData();
    this.getappointments();
  }

  toggle = () => this.setState({ modal: false });

  toggleDiv = (evnt, Statment) => {
    evnt.preventDefault();
  };

  // getstatment = async (e, filename) => {
  //   e.preventDefault();
  //   this.setState({ loading: true, openStatementModal: true });
  //   var arr = [];
  //   arr.push(filename);

  //   await this.setState({
  //     sendAttachmentModel: {
  //       ...this.state.sendAttachmentModel,
  //       document_address: arr,
  //     },
  //   });

  //   await setTimeout(() => {
  //     axios
  //       .post(
  //         this.url + "getstatment",
  //         this.state.sendAttachmentModel,
  //         this.config
  //       )
  //       .then((response) => {
  //         this.setState({
  //           responseOfAttachemnt: response.data,
  //           loading: false,
  //         });
  //       })
  //       .catch((error) => {
  //         this.setState({ loading: false });

  //         if (error.response) {
  //           if (error.response.status) {
  //             Swal.fire("Something Went Wrong", "", "error");
  //             return;
  //           }
  //         } else if (error.request) {
  //           return;
  //         } else {
  //           return;
  //         }
  //       });
  //   }, 500);
  // };

  // to get patient appointment history
  getappointments = () => {
    try {
      this.setState({ loading: true });
      axios
        .get(this.url + "FindPatientPayment", this.config)
        .then((response) => {
          try {
            this.setState({
              newList: response.data.data,
              TotalBalance: response.data.totalBalance,
              loading: false,
            });
          } catch (error) {
            console.log(error);
          }
        });
    } catch (error) {
      this.setState({ loading: false });
    }
  };
  countdown = (date) => {
    var dateObject = new Date(date);
    // var convertedDate = new Date(date);
    var countDownDate = new Date(dateObject).getTime();

    var dateString = new Date(dateObject).toUTCString();
    dateString = dateString.split(" ").slice(0, 4).join(" ");

    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (distance > 0) {
        if (document.getElementById("clocks")) {
          document.getElementById("clocks").style.display = "unset";
          document.getElementById("dateobject").innerHTML = dateString;
          document.getElementById("hour").innerHTML = hours;
          document.getElementById("minute").innerHTML = minutes;
          document.getElementById("second").innerHTML = seconds;
        }
      }
      if (distance < 0) {
        clearInterval(x);
        if (document.getElementById("clocks")) {
          document.getElementById("clocks").style.display = "none";
        }
      }
    }, 1000);
  };
  openComposeEmailModal = async (e, row, check) => {
    e.preventDefault();
    await this.setState({
      openComposeEmail: true,
      replyData: row,
      checkReply: check,
    });
  };
  closeComposeEmailModal = () => {
    this.setState({
      openComposeEmail: false,
    });
  };

  closeAttachment = () => {
    this.setState({
      openStatementModal: false,
    });
  };

  // alertMessage = () => {

  //   Swal.fire("Payment Service is Not Available For This User", "", "error");
  // }

  openStatementModal = (e, FileName) => {
    e.preventDefault();
    this.setState({ FileName: FileName, openStatementModal: true });
  };

  keyHandling = (event) => {
    if (event.keyCode === 27) {
      Swal.fire({
        title: "Do you want to close?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.value === true) {
          this.toggle();
        }
      });
    }
  };

  showErrorMessage = () => {
    if (!isNull(this.stripe)) {
      this.setState({ modal: true });
    } else {
      Swal.fire(
        "Payment Setup is not configured, Please contact Administrator.",
        "",
        "error"
      );
    }
  };
  render() {
    const {
      openComposeEmail,
      replyData,
      checkReply,
      // responseOfAttachemnt,
    } = this.state;
    let popup = "";
    if (openComposeEmail) {
      document.body.style.overflowY = "hidden";
      popup = (
        <ComposeEmail
          isOpen={openComposeEmail}
          closeEmailModal={this.closeComposeEmailModal}
          replyData={replyData}
          checkReply={checkReply}
          threadID={this.props.threadID}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="tab-content DashBoard container-fluid p-0 m-0">
          <div className="tab-pane fade show active" id="dashboard">
            <div
              className="row mb-1 pt-1"
              style={{ backgroundColor: "#dff0f6" }}
            >
              <PatientDetails />
              <Spiner loading={this.state.loading} />

              {this.state.openStatementModal ? (
                <ViewPaymentStatement
                  openStatementModal={this.state.openStatementModal}
                  closeAttachment={this.closeAttachment}
                  filename={this.state.FileName}
                />
              ) : null}

              {/* {this.state.openStatementModal ? (
                <Draggable
                  bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
                  handle="handler"
                >
                  <div
                    className="attachmentModal fade show"
                    id=""
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby=""
                    aria-hidden="true"
                  >
                    <div className="attachmentModal-content">
                      <handler>
                        <div className="custom modal-header">
                          <h4 className="modal-title m-0 p-0" id="popup-model">
                            Patient Statement
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="attachmentModal"
                            aria-label="Close"
                            onClick={this.closeAttachment}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </handler>
                      <div className="attachmentModal-body p-0 m-0 ">
                        <div className="form-row">
                          <div className="form-group col-md-12 col-sm-12 col-12">
                            {!isNull(responseOfAttachemnt) ? (
                              <iframe
                                src={
                                  "data:application/pdf;base64," +
                                  responseOfAttachemnt
                                }
                                style={{
                                  height: "87vh",
                                  margin: " 0px ",
                                  padding: "0px",
                                  width: "100%",
                                }}
                              />
                            ) : (
                              <span style={{ fontSize: "17px" }}>
                                Please Wait....
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
           
                  </div>
                </Draggable>
              ) : (
                ""
              )} */}

              {/* <div className="table-responsive ml-3 mr-3">
                <table className="table table-sm border-0 mb-0">
                  <tr>
                    <td className="border-0">
                      <label className="dark-label-blue">
                        <strong>
                          {this.props.userInformation[0].firstName +
                            " " +
                            this.props.userInformation[0].lastName}
                        </strong>
                      </label>
                    </td>
                    <td className="border-0">
                      <label className="pr-2">
                        {this.props.userInformation[0].gender +
                          " - " +
                          this.props.userInformation[0].maritalStatus}
                      </label>
                      <label>
                        {this.props.userInformation[0].dob +
                          " (" +
                          this.props.userInformation[0].age +
                          ")"}
                      </label>
                    </td>
                    <td className="border-0">
                      <label
                        className="dashboard-textoverflow"
                        title={
                          !isNull(
                            this.props.userInformation[0].primaryInsurance
                          )
                            ? this.props.userInformation[0].primaryInsurance
                            : "N/A"
                        }
                      >
                        <b>PRI INSURANCE</b> :&nbsp;
                        {!isNull(this.props.userInformation[0].primaryInsurance)
                          ? this.props.userInformation[0].primaryInsurance
                          : "N/A"}
                      </label>
                    </td>
                    <td className="border-0">
                      <label>
                        <b>POLICY #</b> :&nbsp;
                        {!isNull(this.props.userInformation[0].primaryPolicy)
                          ? this.props.userInformation[0].primaryPolicy
                          : "N/A"}
                      </label>
                    </td>

                    <td className="border-0">
                      <label>
                        <b>Last Appointment: </b>&nbsp;
                        {this.props.userInformation[0].lastAppointment ===
                          "01/01/1900" ||
                        isNull(this.props.userInformation[0].lastAppointment)
                          ? "No Appointment"
                          : this.props.userInformation[0].lastAppointment}
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="border-0">
                      <label
                        className="dashboard-textoverflow"
                        title={
                          this.props.userInformation[0].address1 +
                          " " +
                          this.props.userInformation[0].city +
                          ", " +
                          this.props.userInformation[0].state +
                          " " +
                          this.props.userInformation[0].zipCode
                        }
                      >
                        <i
                          class="fas fa-map-marker-alt text-danger pr-2"
                          aria-hidden="true"
                        ></i>
                        {this.props.userInformation[0].address1 +
                          " " +
                          this.props.userInformation[0].city +
                          ", " +
                          this.props.userInformation[0].state +
                          " " +
                          this.props.userInformation[0].zipCode}
                      </label>
                    </td>
                    <td className="border-0">
                      <i class="fa fa-phone text-danger pr-2"></i>
                      <label>
                        {!isNull(this.props.userInformation[0].phoneNumber)
                          ? this.props.userInformation[0].phoneNumber
                          : "N/A"}
                      </label>
                    </td>
                    <td className="border-0">
                      <label
                        className="dashboard-textoverflow"
                        title={
                          !isNull(
                            this.props.userInformation[0].secondaryInsurance
                          )
                            ? this.props.userInformation[0].secondaryInsurance
                            : "N/A"
                        }
                      >
                        <b>SEC INSURANCE</b> :&nbsp;
                        {!isNull(
                          this.props.userInformation[0].secondaryInsurance
                        )
                          ? this.props.userInformation[0].secondaryInsurance
                          : "N/A"}
                      </label>
                    </td>
                    <td className="border-0">
                      <label
                        className="dashboard-textoverflow"
                        title={
                          !isNull(this.props.userInformation[0].secondaryPolicy)
                            ? this.props.userInformation[0].secondaryPolicy
                            : "N/A"
                        }
                      >
                        <b>POLICY #</b> :&nbsp;
                        {!isNull(this.props.userInformation[0].secondaryPolicy)
                          ? this.props.userInformation[0].secondaryPolicy
                          : "N/A"}
                      </label>
                    </td>

                    <td className="border-0">
                      <label>
                        <b>Next Appointment:</b>&nbsp;
                        {this.props.userInformation[0].nextVisit ===
                          "01/01/1900 12:00 AM" ||
                        isNull(this.props.userInformation[0].nextVisit)
                          ? "No Appointment"
                          : this.props.userInformation[0].nextVisit}
                      </label>
                    </td>
                  </tr>
                </table>
              </div> */}
            </div>

            <div className="rowd">
              <div className="">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6 custom-header">
                      <h6 className="pt-2 pb-1">
                        <b>Account Details</b>
                      </h6>
                    </div>
                    <div className="col-md-6 custom-header">
                      <button
                        class="btn-phr mt-1 mb-1 p-1 pr-2 ml-2 float-right"
                        onClick={() => {
                          this.showErrorMessage();
                        }}
                      >
                        <span class="p-2">Pay Now</span>
                        <i
                          class="fa fa-money"
                          aria-hidden="true"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </button>

                      <h6 className="pt-2 pb-1 float-right">
                        <b>Patient Balance</b>&nbsp;{" "}
                        <strong className="dark-label-red">
                          ${this.state.TotalBalance}
                        </strong>
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <table className="table table-bordered table-striped">
                      <thead style={{ backgroundColor: "#c9edf2" }}>
                        <tr>
                          <th>Date of Service</th>
                          <th>Billed Amount</th>
                          <th>Insurance Paid</th>
                          <th>Patient Paid</th>
                          <th>Patient Balance</th>
                          <th>Statement</th>
                        </tr>
                      </thead>
                      <tbody className="tblbody">
                        {this.state.newList.map((row) => (
                          <tr>
                            <td colSpan="6">
                              <table className="classdive1">
                                <tr>
                                  <td className="customwth">
                                    <a
                                      data-toggle="collapse"
                                      href={"#collapse" + row.id}
                                      role="button"
                                      aria-expanded="false"
                                      onClick={(evnt) =>
                                        this.toggleDiv(evnt, row.dos)
                                      }
                                      aria-controls="collapseExample"
                                    >
                                      {row.dos}
                                    </a>
                                  </td>
                                  <td className="customwth">{row.billed}</td>
                                  <td className="customwth">
                                    {row.insurancePaid}
                                  </td>
                                  <td className="customwth">
                                    {row.patientPaid}
                                  </td>
                                  <td className="customwth">
                                    {row.patientBalance}
                                  </td>
                                  <td className="attach text-center">
                                    {row.statment ? (
                                      <Link
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                      >
                                        <i
                                          className="fas fa-file-pdf-o text-danger"
                                          title="View"
                                          aria-hidden="true"
                                          onClick={(e) =>
                                            this.openStatementModal(
                                              e,
                                              row.statment
                                            )
                                          }
                                          // onClick={(e) =>
                                          //   this.getstatment(e, row.statment)
                                          // }
                                        ></i>
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                </tr>

                                <tr
                                  className="collapse"
                                  id={"collapse" + row.id}
                                >
                                  <td colSpan="6">
                                    <table className="classdive1">
                                      <tr>
                                        <table className="classdive1">
                                          <thead
                                            style={{
                                              backgroundColor: "#c9edf2",
                                            }}
                                          >
                                            <tr className="classdive1">
                                              <th>CPT</th>
                                              <th>Billed Amount</th>
                                              <th>Insurance Paid</th>
                                              <th>Patient Paid</th>
                                              <th>Copay</th>
                                              <th>Patient Balance</th>
                                            </tr>
                                          </thead>
                                          <tbody className="tblbody">
                                            {row.detaildos.map((row) => (
                                              <tr className="classdive1">
                                                <td>{row.cptcode}</td>
                                                <td>{row.totalAmount}</td>
                                                <td>{row.insurancepaid}</td>
                                                <td>{row.patientPaid}</td>
                                                <td>{row.copay}</td>
                                                <td>{row.patientBalance}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12 custom-header">
                    <h6 className="pt-1 pb-1">
                      <b>Practice Info</b>
                    </h6>
                  </div>
                </div>
                <div
                  className="row p-2 mb-1"
                  style={{ border: "1px solid #ccc" }}
                >
                  <div className="col-md-12 p-0 m-0">
                    <label htmlFor="">
                      <strong>Name :</strong>
                    </label>
                    <span className="pl-2">
                      {!isNull(
                        this.props.userInformation &&
                          this.props.userInformation[0].practiceName
                      )
                        ? this.props.userInformation[0].practiceName
                        : ""}
                    </span>
                  </div>
                  <div className="col-md-12 p-0 m-0">
                    <label htmlFor="">
                      <strong>Address :</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(this.props.userInformation)
                        ? this.props.userInformation[0].practiceAddress +
                          " " +
                          this.props.userInformation[0].practiceCity +
                          ", " +
                          this.props.userInformation[0].practiceState +
                          " " +
                          this.props.userInformation[0].practiceZIPCode
                        : ""}
                      {/* {!isNull(this.props.userInformation)
                        ? this.props.userInformation[0].practiceAddress
                        : ""} */}
                    </span>
                  </div>

                  {/* <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>City :</strong>
                    </label>
                    <span className="pl-2">
                      {!isNull(this.props.userInformation)
                        ? this.props.userInformation[0].practiceCity
                        : ""}
                    </span>
                  </div>
                  <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>State :</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(this.props.userInformation)
                        ? this.props.userInformation[0].practiceState
                        : ""}
                    </span>
                  </div>

                  <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>Zip Code:</strong>
                    </label>
                    <span className="pl-2">
                      {!isNull(this.props.userInformation)
                        ? this.props.userInformation[0].practiceZIPCode
                        : ""}
                    </span>
                  </div> */}
                  <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>Office Phone#:</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(
                        this.props.userInformation &&
                          this.props.userInformation[0].officePhoneNum
                      )
                        ? this.props.userInformation[0].officePhoneNum
                        : ""}
                    </span>
                  </div>

                  <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>Fax#:</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(
                        this.props.userInformation &&
                          this.props.userInformation[0].faxNumber
                      )
                        ? this.props.userInformation[0].faxNumber
                        : ""}
                    </span>
                  </div>

                  <div className="col-md-6 p-0 m-0">
                    <label htmlFor="">
                      <strong>Primary Physician :</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(
                        this.props.userInformation &&
                          this.props.userInformation[0].primaryPhysician
                      )
                        ? this.props.userInformation[0].primaryPhysician
                        : ""}
                    </span>
                  </div>
                  <div className="col-md-12 p-0 m-0">
                    <label htmlFor="">
                      <strong>Practice Email :</strong>
                    </label>

                    <span className="pl-2">
                      {!isNull(
                        this.props.userInformation &&
                          this.props.userInformation[0].practiceEmail
                      )
                        ? this.props.userInformation[0].practiceEmail
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 custom-header">
                    <h6 className="pt-1 pb-1">
                      <b>Reminders</b>
                    </h6>
                  </div>
                </div>
                <div
                  className="row pl-2 mb-1"
                  style={{ border: "1px solid #ccc", height: "115px" }}
                >
                  <div
                    className="text-center w-100 clock"
                    id="clocks"
                    style={{ display: "none" }}
                  >
                    <h5>Next Appointment</h5>
                    {this.countdown(
                      !isNull(
                        this.props.userInformation
                          ? this.props.userInformation[0].nextVisit
                          : ""
                      )
                    )}
                    <h6 className="ml-1 mb-3 w-100" id="dateobject">
                      dsd
                    </h6>
                    <span className="ml-1" id="hour"></span> &nbsp;:&nbsp;
                    <span className="ml-1" id="minute"></span>&nbsp;:&nbsp;
                    <span className="ml-1" id="second"></span>&nbsp;
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 custom-header">
                    <h6 className="pt-1 pb-1">
                      <b>Unread Messages</b>
                    </h6>
                  </div>
                </div>
                <div
                  className="row pl-2 pb-2 Messages"
                  style={{
                    border: "1px solid #ccc",
                    height: "200px",
                    overflowY: "auto",
                  }}
                >
                  {this.state.AllMessages.map((item) =>
                    item.isRead === false ? (
                      <div
                        className="col-md-12 p-2 dark-label-blue messagestripend"
                        style={{
                          borderBottom: "1px solid #ccc",
                          height: "max-content",
                        }}
                      >
                        <span class="dot"></span>&nbsp;{" "}
                        <b>
                          {!isNull(item.messageFrom) ? item.messageFrom : ""}
                        </b>
                        <i
                          onClick={(e) =>
                            this.openComposeEmailModal(e, item, false)
                          }
                          class="fa fa-reply-all light-blue float-right pl-1 pt-1"
                          title="Reply This Message"
                          style={{ cursor: "pointer" }}
                        ></i>
                        <div className="pt-1">
                          <strong>Subject:</strong> &nbsp;
                          {!isNull(item.subject) ? item.subject : ""}
                        </div>
                        <a data-toggle="collapse" href={"#collapse" + item.id}>
                          Show More
                        </a>
                        <div
                          className="text-dark mt-1 collapse"
                          id={"collapse" + item.id}
                          dangerouslySetInnerHTML={{
                            __html: item.message,
                          }}
                        ></div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            {popup}
          </div>

          {this.state.modal ? (
            <Elements stripe={this.stripe}>
              <CheckoutForm
                isOpen={this.state.modal}
                close={this.toggle}
                AmountDue={
                  !isNull(this.state.TotalBalance) ? this.state.TotalBalance : 0
                }
                practiceName={
                  !isNull(
                    this.props.userInformation &&
                      this.props.userInformation.practiceName
                  )
                    ? this.props.userInformation.practiceName
                    : ""
                }
              />
            </Elements>
          ) : (
            ""
          )}

          {/* {this.state.modal ? (
            this.stripe != null ? (
              <Elements stripe={this.stripe}>
                <CheckoutForm
                  isOpen={this.state.modal}
                  close={this.toggle}
                  AmountDue={
                    !isNull(this.state.TotalBalance)
                      ? this.state.TotalBalance
                      : 0
                  }
                  practiceName={
                    !isNull(
                      this.props.userInformation &&
                        this.props.userInformation.practiceName
                    )
                      ? this.props.userInformation.practiceName
                      : ""
                  }
                />
              </Elements>
            ) : (
              <div className="text-danger">
                Payment Setup is not configured, Please contact Administrator.
              </div>
            )
          ) : (
            ""
          )} */}
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInformation: state.userInformation,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      userInfo: userInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Dashboard);
