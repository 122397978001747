import React, { Component } from "react";
import "./Message.css";
import axios from "axios";
import Spiner from "../Spiner";
import Swal from "sweetalert2";
import ReactHtmlParser from "react-html-parser";
import ComposeEmail from "./ComposeInternalMessage";
import isNull from "../../functions/nullChecking";
// ReactReduxContext...
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import { loginAction } from "../../actions/LoginAction";
import { loginAction } from "../../Actions/LoginAction";
import { saveAs } from "file-saver";

class newMessage extends Component {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    this.url2 =
      process.env.REACT_APP_URL + "/PatientPortal/GetInternalMessages";

    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };
    this.sendAttachmentModel = {
      data: [],
    };
    this.state = {
      sendAttachmentModel: this.sendAttachmentModel,
      showDivItems: false,
      MessagesCounters: [],
      AllMessages: [],
      InboxMessagesOriginal: [],
      loading: false,
      threadId: "",
      messageId: "",
      currentRow: "",
      openComposeEmailModal: false,
      MessageDetails: [],
      checked: false,
      noData: "",
      selectedDuos: "",
      isRead: false,
      checkRead: "",
      TabSelected: "",
      HideRefreshIcon: false,
      hideReplays: false,
      openComposeEmail: false,
      internalMesagesForDraft: false,
      forwardInternalMessages: false,
      showReplyItems: false,
      showReply: [],
      Favourite: false,
      rowSelected: "",
      selectedImportant: false,
      FavouriteMessages: [],
      markState: "",
      markImportant: "",
    };
  }
  componentDidMount() {
    document.addEventListener("click", (e) => {
      if (e.target.id !== "showReply") {
        this.closeItems();
      }
    });
    this.setState(
      {
        selectedDuos: "",
        isRead: false,
        checkRead: "",
      },
      () => this.getData("/Inbox")
    );
  }

  closeItems = () => {
    var showReply = this.state.showReply;
    showReply.forEach((row) => {
      row.value = false;
    });
    this.setState({
      showReply: showReply,
    });
  };

  getData = async (dataOf) => {
    if (dataOf === "/Draft" || dataOf === "/Sent") {
      this.setState({
        hideReplays: true,
        HideRefreshIcon: false,
      });
    } else {
      this.setState({
        hideReplays: false,
        HideRefreshIcon: false,
      });
    }
    var TabSelected =
      dataOf === "/Inbox" ? "Inbox" : dataOf === "/Sent" ? "Sent" : "Draft";

    this.setState({
      loading: true,
      TabSelected: TabSelected,
      selectedDuos: "",
    });
    const MessagesCounters = await axios
      .get(this.url + "InternalMessagesCounter", this.config)
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      MessagesCounters: MessagesCounters.data ? MessagesCounters.data : [],
      loading: false,
    });

    this.setState({ loading: true });
    axios
      .get(this.url2 + dataOf, this.config)
      .then((response) => {
        this.setState({
          AllMessages: response.data,
          FavouriteMessages:
            TabSelected === "Inbox"
              ? this.showselectedDuos("Favourite", response.data)
              : this.state.FavouriteMessages,
          ImportantMessages:
            TabSelected === "Inbox"
              ? this.showselectedDuos("Important", response.data)
              : this.state.ImportantMessages,
          InboxMessagesOriginal: response.data,

          noData:
            response.data.length === 0 ? (
              <div style={{ fontSize: "16px", marginTop: "10px" }}>
                You don't have any Message.
              </div>
            ) : (
              ""
            ),
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  };
  openEmailModal = (e) => {
    e.preventDefault();
    this.setState({
      openEmailModal: true,
    });
  };

  toggle = () => {
    this.setState({
      openEmailModal: false,
    });
  };
  handleTabChange = (event, tab) => {
    event.preventDefault();
    this.setState({
      checkedTabExtraInfo: tab,
    });
  };

  showMessageDetails = (e, row) => {
    e.preventDefault();
    const from = e.target.getAttribute("from");
    if (from === "row") {
      if (this.state.selectedDuos === "") {
        this.getData("/" + this.state.TabSelected);
      }
      this.setState({ rowSelected: row });
      axios
        .get(
          this.url +
            `GetMessageDetail/${row.id}/${row.threadID}/${this.state.TabSelected}`,
          this.config
        )
        .then((response) => {
          this.setState({
            MessageDetails: response.data ? response.data : [],
          });
        });
    }
  };

  async downloadAsZip(e, att) {
    e.preventDefault();
    this.setState({ loading: true });
    var arr = [];
    att.forEach((row) => {
      arr.push(row.name);
    });

    await this.setState({
      sendAttachmentModel: {
        ...this.state.sendAttachmentModel,
        data: arr,
      },
    });
    axios
      .post(this.url + "DownloadZipDocument", this.state.sendAttachmentModel, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer  " + this.props.loginObject.token,
          Accept: "*/*",
        },
        responseType: "blob",
      })
      .then(function (res) {
        var blob = new Blob([res.data], {
          type: "application/zip",
        });

        saveAs(blob, "Document.zip");
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }
  downloadFile = async (e, att) => {
    e.preventDefault();
    this.setState({ loading: true });
    var arr = [];

    arr.push(att.name);

    await this.setState({
      sendAttachmentModel: {
        ...this.state.sendAttachmentModel,
        data: arr,
      },
    });

    try {
      axios
        .post(
          this.url + "DownloadZipDocument",
          this.state.sendAttachmentModel,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer  " + this.props.loginObject.token,
              Accept: "*/*",
            },
            responseType: "blob",
          }
        )
        .then(function (res) {
          var blob = new Blob([res.data], {
            type: "application/pdf",
          });

          saveAs(blob, att.name.split("_")[1]);
          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });

          if (error.response) {
            if (error.response.status) {
              Swal.fire("Something Went Wrong", "", "error");
              return;
            }
          } else if (error.request) {
            return;
          } else {
            return;
          }
        });
    } catch {}
  };

  openComposeEmailModal = async (e, row, check) => {
    e.preventDefault();
    await this.setState({
      openComposeEmail: true,
      replyData: row,
      checkReply: check,
    });
  };
  closeComposeEmailModal = () => {
    this.setState({
      openComposeEmail: false,
    });
  };
  openComposeForDraft = async (row) => {
    this.setState({
      internalMesagesForDraft: true,
      draftData: row,
    });
  };
  closeComposeEmailForDraft = () => {
    this.setState({
      internalMesagesForDraft: false,
    });
  };
  closeInternalMessages = () => {
    this.setState({
      forwardInternalMessages: false,
    });
  };
  forwardInternalMessages = async (e, row) => {
    e.preventDefault();
    await this.setState({
      forwardInternalMessages: true,
      forwardData: row,
    });
  };
  markAs = (e, mark, item) => {
    this.setState({ loading: false });
    e.preventDefault();
    let id = e.target.id;
    let markIDTO = item.markIDTO;
    let markIDCC = item.markIDCC;
    var markNew = "";
    if (mark === "Favourite") {
      if (item.markedAs === "Favourite") {
        markNew = "unFavourite";
      } else {
        markNew = mark;
      }
    }

    if (mark === "Important") {
      if (item.markedAs === "Important") {
        markNew = "unImportant";
      } else {
        markNew = mark;
      }
    }

    if (markNew === "Favourite" || markNew === "unFavourite") {
      this.state.AllMessages.forEach((items) => {
        if (items.threadID === parseInt(id)) {
          axios
            .post(
              this.url + `SetMarkOptions/${markIDTO}/${markIDCC}/${markNew}`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  markState: "",
                },
                () => this.getData("/" + this.state.TabSelected)
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
            });
        }
      });
    }

    if (markNew === "Important" || markNew === "unImportant") {
      this.state.AllMessages.forEach((items) => {
        if (items.threadID === parseInt(id)) {
          axios
            .post(
              this.url + `SetMarkOptions/${markIDTO}/${markIDCC}/${markNew}`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  selectedImportant: true,
                  markImportant: "",
                },
                () => this.getData("/" + this.state.TabSelected)
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
            });
        }
      });
    }

    if (mark === "Delete") {
      Swal.fire({
        title: "Are you sure, you want to delete this record?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let Favourite = this.state.FavouriteMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          let allMessages = this.state.AllMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );

          let Important = this.state.ImportantMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          axios
            .post(
              this.url + `SetMarkOptions/${markIDTO}/${markIDCC}/${mark}`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  MessageDetails: [],
                  FavouriteMessages: Favourite,
                  ImportantMessages: Important,
                  AllMessages: allMessages,
                },
                () => this.getData("/" + this.state.TabSelected)
              );
              Swal.fire("Record Deleted Successfully", "", "success");
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        }
      });
    }
  };
  showselectedDuos = (Mark, data) => {
    let array = [];
    if (data.length > 0) {
      data.forEach((row, index) => {
        if (row.markedAs === Mark) {
          array.push(row);
        }
      });
    }
    return array;
  };

  DeleteMain = (e, mark, item, selectedTab) => {
    e.preventDefault();
    let id = item.id;
    if (selectedTab === "Sent" && mark === "Delete") {
      Swal.fire({
        title: "Are you sure, you want to delete this record?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let Favourite = this.state.FavouriteMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          let allMessages = this.state.AllMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );

          let Important = this.state.ImportantMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          axios
            .post(
              this.url + `SetMarkOptions/${id}/0/SentDelete`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  MessageDetails: [],
                  FavouriteMessages: Favourite,
                  ImportantMessages: Important,
                  AllMessages: allMessages,
                },
                () => this.getData("/" + this.state.TabSelected)
              );

              Swal.fire("Record Deleted Successfully", "", "success");
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        }
      });
    } else if (selectedTab === "Draft" && mark === "Delete") {
      Swal.fire({
        title: "Are you sure, you want to delete this record?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let Favourite = this.state.FavouriteMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          let allMessages = this.state.AllMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );

          let Important = this.state.ImportantMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          axios
            .post(
              this.url + `SetMarkOptions/${id}/0/DraftDelete`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  MessageDetails: [],
                  FavouriteMessages: Favourite,
                  ImportantMessages: Important,
                  AllMessages: allMessages,
                },
                () => this.getData("/" + this.state.TabSelected)
              );

              Swal.fire("Record Deleted Successfully", "", "success");
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure, you want to delete this record?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let Favourite = this.state.FavouriteMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          let allMessages = this.state.AllMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );

          let Important = this.state.ImportantMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          axios
            .post(
              this.url +
                `SetMarkOptions/${item.markIDTO}/${item.markIDCC}/InboxDelete`,
              "",
              this.config
            )
            .then((response) => {
              this.setState(
                {
                  loading: false,
                  MessageDetails: [],
                  FavouriteMessages: Favourite,
                  ImportantMessages: Important,
                  AllMessages: allMessages,
                },
                () => this.getData("/" + this.state.TabSelected)
              );
              Swal.fire("Record Deleted Successfully", "", "success");
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        }
      });
    }
  };
  deleteMessageDetial = (e, mark, item) => {
    let id = e.target.id;
    e.preventDefault();
    var detailMessageToId = "";
    var detailMessageCcId = "";
    detailMessageToId =
      item.messageTO.length <= 0 ? 0 : item.messageTO[0].markIDTO;
    detailMessageCcId =
      item.messageCC.length <= 0 ? 0 : item.messageCC[0].markIDCC;

    if (mark === "Delete") {
      Swal.fire({
        title: "Are you sure, you want to delete this record?",
        text: "",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let Favourite = this.state.FavouriteMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          let allMessages = this.state.AllMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );

          let Important = this.state.ImportantMessages.filter(
            (row) => parseInt(id) !== row.threadID
          );
          axios
            .post(
              this.url +
                `SetMarkOptions/${detailMessageToId}/${detailMessageCcId}/${mark}`,
              "",
              this.config
            )
            .then((response) => {
              this.setState({
                loading: false,
                MessageDetails: [],
                FavouriteMessages: Favourite,
                ImportantMessages: Important,
                AllMessages: allMessages,
              });
              Swal.fire("Record Deleted Successfully", "", "success");
            })
            .catch((error) => {
              this.setState({ loading: false });
            });
        }
      });
    }
  };

  render() {
    const {
      selectedDuos,
      AllMessages,
      loading,
      TabSelected,
      HideRefreshIcon,
      rowSelected,
      MessagesCounters,
      openEmailModal,
      draftData,
      openComposeEmail,
      internalMesagesForDraft,
      replyData,
      checkReply,
      forwardInternalMessages,
      forwardData,
    } = this.state;
    let dataToMap = "";
    if (selectedDuos === "") dataToMap = AllMessages;
    else {
      dataToMap = this.state[selectedDuos + "Messages"];
    }

    let popup = "";
    if (openEmailModal) {
      document.body.style.overflowY = "hidden";
      popup = (
        <ComposeEmail isOpen={openEmailModal} closeEmailModal={this.toggle} />
      );
    } else if (openComposeEmail) {
      document.body.style.overflowY = "hidden";
      popup = (
        <ComposeEmail
          isOpen={openComposeEmail}
          closeEmailModal={this.closeComposeEmailModal}
          replyData={replyData}
          checkReply={checkReply}
          threadID={this.props.threadID}
        />
      );
    } else if (forwardInternalMessages) {
      document.body.style.overflowY = "hidden";
      popup = (
        <ComposeEmail
          callingFrom="Details"
          isOpen={forwardInternalMessages}
          closeInternalMessages={this.closeInternalMessages}
          forwardData={forwardData}
          threadID={this.props.threadID}
        />
      );
    } else if (internalMesagesForDraft) {
      document.body.style.overflowY = "hidden";
      popup = (
        <ComposeEmail
          callingFrom="Details"
          isOpen={internalMesagesForDraft}
          closeComposeEmailForDraft={this.closeComposeEmailForDraft}
          draftData={draftData}
          threadID={this.props.threadID}
        />
      );
    } else document.body.style.overflowY = "auto";
    return (
      <React.Fragment>
        <Spiner loading={loading} />

        <div class="mt-0 m-0 p-1 InternalMessages">
          <div class="form-row p-0 m-0 bg-white">
            <div class="form-row form-dashboard col-md-12 col-sm-12 col-12">
              <ul class="nav nav-tabs-justified nav-tabs  col-md-3 col-sm-10 col-10 p-0 m-0 border-0">
                <li class="border-radius-0" aria-expanded="false">
                  <a
                    data-toggle="tab"
                    href="#plans"
                    aria-expanded="false"
                    class="active"
                    onClick={() => this.getData("/Inbox")}
                  >
                    Inbox-{this.state.MessagesCounters.unread}
                  </a>
                </li>
                <li class="border-radius-0" aria-expanded="false">
                  <a
                    data-toggle="tab"
                    href="#plans"
                    aria-expanded="false"
                    class=""
                    onClick={() => this.getData("/Draft")}
                  >
                    Draft
                  </a>
                </li>
                <li class="" aria-expanded="false">
                  <a
                    data-toggle="tab"
                    href="#authorizations"
                    aria-expanded="false"
                    class=""
                    onClick={() => this.getData("/Sent")}
                  >
                    Sent
                  </a>
                </li>

                <li class="" aria-expanded="false">
                  <a
                    data-toggle="tab"
                    href="#referrals"
                    aria-expanded="false"
                    class=""
                    onClick={() =>
                      this.setState({
                        selectedDuos: "Favourite",
                        isRead: false,
                        checkRead: "",
                        HideRefreshIcon: true,
                      })
                    }
                  >
                    Favourite-{MessagesCounters.favourite}
                  </a>
                </li>
                <li class="" aria-expanded="false">
                  <a
                    data-toggle="tab"
                    href="#referrals"
                    aria-expanded="false"
                    class=""
                    onClick={() =>
                      this.setState({
                        selectedDuos: "Important",
                        isRead: false,
                        checkRead: "",
                        HideRefreshIcon: true,
                      })
                    }
                  >
                    Important-{MessagesCounters.important}
                  </a>
                </li>
              </ul>

              <div class="col-md-2 col-sm-2 col-2 flex-column flex-grow-1">
                <div className="w-100 float-left">
                  <div>
                    <a
                      href="/"
                      className="blue border-0 m-0 p-0 pr-3 font-weight-bold flex-column flex-grow-1"
                      onClick={this.openEmailModal}
                      title="Compose Message"
                      style={{ cursor: "pointer" }}
                    >
                      New
                    </a>
                    {HideRefreshIcon ? (
                      ""
                    ) : (
                      <span
                        className="border-0 m-0 pr-3 p-0 mr-0 flex-column flex-grow-1"
                        id="refresh"
                        style={{ cursor: "pointer" }}
                        title={`Refresh  ${TabSelected} Record`}
                      >
                        <i
                          onClick={() => this.getData("/" + TabSelected)}
                          class="fa fa-refresh blue blue"
                        ></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-0 m-0">
            <div class="col-md-12 col-sm-12 col-12 mt-0 border-top bg-white p-0 m-0 flex-column flex-grow-1">
              <div
                class="col-md-4 col-sm-4 col-4 flex-grow-0 pt-0 float-left pr-0 pl-0 "
                style={{
                  height: " 100vh",
                  borderRight: "1px solid #03658c",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {/* {this.state.noData} */}
                {dataToMap.map((item, index) => (
                  <React.Fragment>
                    <div
                      class={
                        item.isRead
                          ? "col-md-12 col-sm-12 pr-2 col-12  p-2 m-0 flex-column flex-grow-0 bg-email"
                          : "col-md-12 col-sm-12 pr-2 col-12  p-2 flex-column flex-grow-0  font-weight-bold bg-email-unread"
                      }
                      onClick={(e) => this.showMessageDetails(e, item, index)}
                      from="row"
                      style={{
                        background: item.id === rowSelected.id ? "#e6f2fa" : "",
                      }}
                    >
                      <div
                        from="row"
                        class="float-left d-flex  col-md-6 blue p-0 "
                      >
                        {/* <span className="InternalMessageUser">
                          {item.userType === "Patient" ? "P" : "U"}
                        </span> */}
                        {!isNull(item.fromName) ? (
                          item.fromName.length >= 8 ? (
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "4px",
                              }}
                              className="txt-capCase"
                              from="row"
                              title={item.fromName}
                            >
                              From : {item.fromName}
                            </span>
                          ) : (
                            <span
                              className="txt-capCase"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "4px",
                              }}
                              from="row"
                              title={item.fromName}
                            >
                              From : {item.fromName}
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        class="float-right col-md-6 text-right blue p-0 m-0 email-date pl-0 pt-0"
                        from="row"
                      >
                        {item.clientAddedDate}
                      </div>
                      <div class="clearfix"></div>
                      <div
                        class={
                          item.isRead
                            ? "float-left col-md-8 col-sm-8 col-8 flex-grow-0 email-sub pt-0 p-0 m-0 pl-1"
                            : "float-left col-md-8 col-sm-8 col-8 flex-grow-0 blue email-sub pt-0 p-0 m-0 pl-1 "
                        }
                        from="row"
                      >
                        {item.subject.length >= 8 ? (
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            from="row"
                            title={item.subject}
                          >
                            {item.subject}
                          </div>
                        ) : (
                          <div from="row" title={item.subject}>
                            {" "}
                            {item.subject}
                          </div>
                        )}
                      </div>
                      <div class="float-right col-md-4 col-sm-4 col-4 flex-grow-0 email-sub pt-0 p-0 m-0 pl-0">
                        {item.attachment ? (
                          <i
                            title="This Message Has Attachments"
                            class="fa fa-paperclip light-blue float-right pl-1 pt-1"
                          ></i>
                        ) : (
                          ""
                        )}
                        <i
                          id={item.id}
                          onClick={(e) =>
                            this.DeleteMain(
                              e,
                              "Delete",
                              item,
                              this.state.TabSelected
                            )
                          }
                          class="fa fa-trash light-blue float-right pl-1 pt-1"
                          title="Delete This Message"
                        ></i>

                        {this.state.TabSelected !== "Inbox" ||
                        this.state.selectedDuos ? (
                          ""
                        ) : (
                          <React.Fragment>
                            <i
                              onClick={(e) =>
                                this.openComposeEmailModal(e, item, false)
                              }
                              class="fa fa-reply-all light-blue float-right pl-1 pt-1"
                              title="Reply This Message"
                            ></i>
                            <i
                              class={
                                item.markedAs === "Favourite"
                                  ? "fa fa-star light-red  float-right pt-1"
                                  : "fa fa-star light-blue  float-right pt-1"
                              }
                              id={item.threadID}
                              onClick={(e) => this.markAs(e, "Favourite", item)}
                              title="Mark As Favourite"
                            ></i>
                            <i
                              class={
                                item.markedAs === "Important"
                                  ? " fa fa-exclamation pr-1 light-red  float-right pl-1 pt-1 "
                                  : "fa fa-exclamation pr-1  light-blue float-right pl-1 pt-1 "
                              }
                              id={item.threadID}
                              onClick={(e) => this.markAs(e, "Important", item)}
                              title="Mark As Important"
                            ></i>
                          </React.Fragment>
                        )}
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </React.Fragment>
                ))}
                {/* map ended */}
              </div>
              <div
                class="col-md-8 col-sm-8 col-8 p-0 m-0 float-right bg-white flex-column flex-grow-0"
                style={{
                  border: "2px solid rgb(255, 255, 255)",
                  height: "100vh",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {this.state.MessageDetails.map((item, index) => (
                  <React.Fragment>
                    <div class="col-md-12 col-sm-12 col-12 flex-grow-1 p-0 m-0">
                      {this.state.TabSelected !== "Inbox" &&
                      this.state.TabSelected !== "Sent" ? (
                        <div class="float-left blue  pt-0 pl-3 m-0">
                          <a
                            href="#farword"
                            onClick={(e) =>
                              this.forwardInternalMessages(e, item)
                            }
                            class="btn-alink-email email-date border-0 m-0 p-0 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Forward
                          </a>
                          |
                          <a
                            href="#delete"
                            id={item.id}
                            onClick={(e) =>
                              this.deleteMessageDetial(e, "Delete", item)
                            }
                            class="btn-alink-email email-date border-0 m-0 p-0 pl-1 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Delete
                          </a>
                        </div>
                      ) : this.state.TabSelected === "Sent" ? (
                        <div class="float-left blue  pt-0 p-0 m-0">
                          <a
                            href="#farword"
                            onClick={(e) =>
                              this.forwardInternalMessages(e, item)
                            }
                            class="btn-alink-email email-date border-0 m-0 p-0 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Forward
                          </a>
                        </div>
                      ) : (
                        <div class="float-left  blue pt-0 pl-3 m-0">
                          <a
                            href="#Reply"
                            onClick={(e) =>
                              this.openComposeEmailModal(e, item, false)
                            }
                            class="btn-alink-email email-date border-0 m-0 pl-0 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Reply
                          </a>
                          |
                          <a
                            href="#ReplyAll"
                            onClick={(e) =>
                              this.openComposeEmailModal(e, item, true)
                            }
                            class="btn-alink-email email-date border-0 m-0 pl-1 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Reply All
                          </a>
                          |
                          <a
                            href="#forward"
                            onClick={(e) =>
                              this.forwardInternalMessages(e, item)
                            }
                            class="btn-alink-email email-date border-0 m-0 p-0 pl-1 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Forward
                          </a>
                          |
                          <a
                            href="#delete"
                            id={item.id}
                            onClick={(e) =>
                              this.deleteMessageDetial(e, "Delete", item)
                            }
                            class="btn-alink-email email-date border-0 m-0 p-0 pl-1 pr-1 mb-1 mr-0 flex-column flex-grow-1"
                          >
                            Delete
                          </a>
                        </div>
                      )}
                      <span class="f16 float-right  email-sub  d-block mb-2">
                        {item.clientAddedDate}
                      </span>

                      <div class="clearfix"></div>

                      <div
                        className="form-row"
                        title="Click Here To See Details"
                        style={{ cursor: "pointer" }}
                        data-toggle="collapse"
                        data-target={"#collapse" + index}
                      >
                        <div class="f16 mb-2 col-md-12 col-sm-12 col-12 float-left email-sub">
                          <div
                            class="col-md-1 col-sm-1 col-1 float-left "
                            style={{ lineHeight: "2" }}
                          >
                            <strong className="text-capitalize">From:</strong>
                          </div>
                          <div class="col-md-11 text-left col-sm-11 col-11 float-right">
                            <div className="MessageFrom">
                              <strong className="px-2">
                                {item.fromName} {`<`}
                                {item.messageFrom} {`>`}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id={"collapse" + index}
                        class={
                          this.state.rowSelected.id === item.id
                            ? "show"
                            : "collapse"
                        }
                      >
                        <div className="form-row">
                          <div class="mb-2 col-md-12 col-sm-12 col-12 float-left email-sub">
                            <div
                              class="col-md-1 col-sm-1 col-1 float-left"
                              style={{ lineHeight: "2" }}
                            >
                              <strong className="text-capitalize">To:</strong>
                            </div>
                            <div class="col-md-11 text-left col-sm-11 col-11 float-right">
                              {item.messageTO.map((row) => (
                                <div className="MessageFrom">
                                  {row.type === "Patient" ? (
                                    <strong className="px-2">
                                      {row.name}
                                      {!isNull(row.email)
                                        ? `<
                                   ${row.email} >`
                                        : ""}
                                    </strong>
                                  ) : (
                                    <strong className="px-2">
                                      {row.name} {`<`}
                                      {row.id} {`>`}
                                    </strong>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        {/* <div className="form-row">
                        <div class="f16 mb-2 col-md-12 col-sm-12 col-12 float-left email-sub text-capitalize">
                          <div
                            class="col-md-1 col-sm-1 col-1  float-left"
                            style={{ lineHeight: "2" }}
                          >
                            <strong>CC:</strong>
                          </div>
                          <div class="col-md-11 text-left col-sm-11 col-11 float-right">
                            {item.messageCC.length !== 0
                              ? item.messageCC.map((row) => (
                                  <div className="MessageFrom">
                                    <span class="px-2">{row.name}</span>
                                  </div>
                                ))
                              : null}
                          </div>
                        </div>
                      </div> */}

                        <div className="form-row">
                          <div class="f16 mb-2  col-md-12 col-sm-12 col-12 float-left email-sub text-capitalize">
                            <div class="col-md-1 col-sm-1 col-1  float-left">
                              <strong className="text-capitalize">
                                Subject:
                              </strong>
                            </div>
                            <div class="col-md-11 pl-4 col-sm-11 col-11 float-right">
                              <div>{item.subject}</div>
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div class="f16 mb-2 col-md-12 col-sm-12 col-12 float-left  email-sub text-capitalize">
                            <div class="col-md-4 col-sm-4 col-4  float-left ">
                              <strong className="text-capitalize">
                                Attachments:
                                {!isNull(item.attachments) ? (
                                  item.attachments.length > 1 ? (
                                    <a
                                      href="#attachment"
                                      onClick={(e) =>
                                        this.downloadAsZip(e, item.attachments)
                                      }
                                    >
                                      &nbsp;
                                      <i
                                        class="f16 fas fa-file-archive"
                                        title="Download As Zip"
                                      ></i>
                                    </a>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </strong>
                            </div>
                            <div class="col-md-12 text-left float-left col-sm-12 col-12">
                              {!isNull(item.attachments)
                                ? item.attachments.map((att) => (
                                    <a
                                      href="/"
                                      style={{
                                        cursor: "pointer",
                                        color: "#3A8CCB",
                                        padding: "5px",
                                      }}
                                      onClick={(e) => this.downloadFile(e, att)}
                                      title="Click Here To Download"
                                      className="email-sub MessageFrom"
                                    >
                                      {att.name.split("/")[4]}
                                    </a>
                                  ))
                                : ""}
                            </div>
                          </div>
                        </div>

                        <div class="clearfix"></div>
                        <hr class="p-0 m-0 pb-0" />
                        <div class="f14 gray email-sub">
                          <div
                            class="w-100  p-3  mt-2  h-auto float-left"
                            style={{ border: "1px solid #e6e3e3" }}
                          >
                            {ReactHtmlParser(item.message)}
                          </div>
                          <div class="clearfix"></div>
                        </div>
                      </div>
                      <hr
                        class="p-0 m-0  mt-2 pb-0"
                        style={{ height: "2px" }}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          {popup}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { token: "", isLogin: false },
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
    },
    dispatch
  );
}
export default connect(mapStateToProps, matchDispatchToProps)(newMessage);
