//countries States
const usStates = [
    { value: "", display: "Select State" },
    { value: "AL", display: "AL - Alabama" },
    { value: "AK", display: "AK - Alaska" },
    { value: "AZ", display: "AZ - Arizona" },
    { value: "AR", display: "AR - Arkansas" },
    { value: "CA", display: "CA - California" },
    { value: "CO", display: "CO - Colorado" },
    { value: "CT", display: "CT - Connecticut" },
    { value: "DE", display: "DE - Delaware" },
    { value: "FL", display: "FL - Florida" },
    { value: "GA", display: "GA - Georgia" },
    { value: "HI", display: "HI - Hawaii" },
    { value: "ID", display: "ID - Idaho" },
    { value: "IL", display: "IL - Illinois" },
    { value: "IN", display: "IN - Indiana" },
    { value: "IA", display: "IA - Iowa" },
    { value: "KS", display: "KS - Kansas" },
    { value: "KY", display: "KY - Kentucky" },
    { value: "LA", display: "LA - Louisiana" },
    { value: "ME", display: "ME - Maine" },
    { value: "MD", display: "MD - Maryland" },
    { value: "MA", display: "MA - Massachusetts" },
    { value: "MI", display: "MI - Michigan" },
    { value: "MN", display: "MN - Minnesota" },
    { value: "MS", display: "MS - Mississippi" },
    { value: "MO", display: "MO - Missouri" },
    { value: "MT", display: "MT - Montana" },
    { value: "NE", display: "NE - Nebraska" },
    { value: "NV", display: "NV - Nevada" },
    { value: "NH", display: "NH - New Hampshire" },
    { value: "NJ", display: "NJ - New Jersey" },
    { value: "NM", display: "NM - New Mexico" },
    { value: "NY", display: "NY - New York" },
    { value: "NC", display: "NC - North Carolina" },
    { value: "ND", display: "ND - North Dakota" },
    { value: "OH", display: "OH - Ohio" },
    { value: "OK", display: "OK - Oklahoma" },
    { value: "OR", display: "OR - Oregon" },
    { value: "PA", display: "PA - Pennsylvania" },
    { value: "RI", display: "RI - Rhode Island" },
    { value: "SC", display: "SC - South Carolina" },
    { value: "SD", display: "SD - South Dakota" },
    { value: "TN", display: "TN - Tennessee" },
    { value: "TX", display: "TX - Texas" },
    { value: "UT", display: "UT - Utah" },
    { value: "VT", display: "VT - Vermont" },
    { value: "VA", display: "VA - Virginia" },
    { value: "WA", display: "WA - Washington" },
    { value: "WV", display: "WV - West Virginia" },
    { value: "WI", display: "WI - Wisconsin" },
    { value: "WY", display: "WY - Wyoming" },
  ];

//gender
const gender = [
  { value: "", display: "Select" },
  { value: "M", display: "MALE" },
  { value: "F", display: "FEMALE" },
  { value: "U", display: "UNKNOWN" },
];

//maritalStatus
const maritalStatus = [
  { value: "", display: "MARITAL STATUS" },
  { value: "SINGLE", display: "SINGLE" },
  { value: "MARRIED", display: "MARRIED" },
  { value: "DIVORCED", display: "DIVORCED" },
  { value: "WIDOW", display: "WIDOW" },
  { value: "OTHER", display: "OTHER" },
];
//RelationShip
const relationship = [
  { value: "", display: "Select Relationship" },
  { value: "18", display: "18 SELF" },
  { value: "01", display: "01 SPOUSE" },
  { value: "19", display: "19 CHiLD" },
  { value: "20", display: "20 EMPLOYEE" },
  { value: "21", display: "21 UNKNOWN" },
  { value: "39", display: "39 ORGAN DONAR" },
  { value: "53", display: "53 LIFE PARTNER" },
  { value: "G8", display: "G8 OTHER RELATIONSHIP" },
];

// coverage 
const coverage = [
  { value: "", display: "Select Coverage" },
  { value: "P", display: "PRIMARY" },
  { value: "S", display: "SECONDARY" },
  { value: "T", display: "TERTIARY" },
];

//Charges Search Status DropDown
const chargestatus = [
  { value: "null", display: "ALL" },
  { value: "N", display: "NEW CHARGE" },
  { value: "H", display: "HOLD" },
  { value: "Pending", display: "PENDING" },
  { value: "S", display: "SUBMITTED" },
  { value: "RS", display: "RESUBMITTED" },
  { value: "K", display: "999 ACCEPTED" },
  { value: "D", display: "999 DENIED" },
  { value: "E", display: "999 HAS ERROR" },
  { value: "A1AY", display: "RECEIVED BY CLEARING HOUSE" },
  { value: "A0PR", display: "FORWARDED TO PAYER" },
  { value: "A1PR", display: "RECEIVED BY PAYER" },
  { value: "A2PR", display: "ACCEPTED BY PAYER" },
  { value: "R", display: "REJECTED" },
  { value: "DN", display: "DENIED" },
  { value: "P", display: "PAID" },
  { value: "PATBAL>0", display: "PATIENT BAL > 0" },
  { value: "SystemRejected", display: "SYSTEM REJECTED" },
  { value: "CRED_NT_DONE", display: "CREDENTIALING NOT DONE" },
  { value: "INACTIVE_INS", display: "IN ACTIVE INSURANCE" },
  { value: "APPEAL", display: "APPEAL" },
  { value: "APP_TO_PAID", display: "APPROVED TO PAID" },
  { value: "NEED_POL", display: "NEED POLICY #" },
  { value: "REV_APPLIED", display: "REVERSAL APPLIED" },
  { value: "MISSING INFO", display: "MISSING INFO" },
  { value: "GLOBAL_PERIOD", display: "GLOBAL PERIOD" },
  { value: "PAID_TO_PATIENT", display: "PAID TO PATIENT" },
  { value: "PAT_RESP", display: "PATIENT RESPONSIBILITY" },
  { value: "SENT_FOR_REVIEW", display: "SENT BACK FOR REVIEW" },
  { value: "PROVIDER_WRITEOFF", display: "PROVIDER_WRITEOFF" },
];

//titles 
const titles = [
  { value: "", display: "Select" },
  { value: "MR.", display: "MR" },
  { value: "MRS.", display: "MRS" },
];




module.exports={
  Pstatus:chargestatus,
  gender:gender,
  maritalStatus:maritalStatus,
  relationship:relationship,
  coverage:coverage,
  usStates:usStates,
  titles:titles
}