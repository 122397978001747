import React from 'react'
import Draggable from 'react-draggable'
import Spiner from './Spiner'
import '../css/PrintStyle.css'
import ReactToPrint from 'react-to-print'
import axios from 'axios'
import { connect } from 'react-redux'
import isNull from '../functions/nullChecking'
import { Link } from 'react-router-dom'
import Logo from '../images/logo-com.png'
import medifusionLogo from '../images/medifusion-logo.png'
import signatureImg from '../images/footer-img.png'
import Format from '../functions/Format'
import { relationship } from '../functions/states'
import '../css/labPrintStyle.css'

class LabDetailReport extends React.PureComponent {
  constructor(props) {
    super(props)
    this.url = process.env.REACT_APP_URL + '/PatientPortal/'
    this.url1 = process.env.REACT_APP_URL + '/Lab/'
    this.config = {
      headers: {
        Authorization: 'Bearer  ' + this.props.loginObject.token,
        Accept: '*/*',
      },
    }
    this.state = {
      practiceData: [],
      labData: {
        labOrderTest: [],
        labDiagnose: [],
      },
      userdata: {},
      labResults: [],
      labOrderSummary: [],
      patientPlanReport: [],
      relationshipName:'',
      labLogo:null,
      labSignature:null
    }
  }
  componentDidMount = async () => {
    this.props.loading(true);
    const { labId, patientID } = this.props
   await axios
    .get(
      this.url + `GetLogo`,
      this.config,
    )
    .then((response) => {
      console.log(response)
      let labLogo=null
      let labSignature=null
      if(response.data){
        labLogo=response.data.value.labLogo
        labSignature=response.data.value.labSignature
      }
      this.setState({ labLogo:labLogo,labSignature:labSignature })
      this.props.loading(false)
    })
    if (labId != null) {
      await axios
        .get(this.url + `GetLabOrders?OrderID=${labId}`, this.config)
        .then((response) => {
          this.setState({
            labData: response.data.labOrders,
            practiceData: response.data.practice,
            userdata: response.data.summarypatientHeader[0],
          })
          this.getResults(response.data.labOrders)
        }).catch((error) => {
          this.props.loading(false);
          console.log(error)
        })
    }
    if (labId != null) {
      await axios
        .get(
          this.url + `GetLaborderSummaryreport?orderId=${labId}`,
          this.config,
        )
        .then((response) => {
          this.setState({ labOrderSummary: response.data[0] })
        })
        .catch((error) => {
          this.props.loading(false);
          console.log(error)
        })
    }
    if (patientID != null) {
      await axios
        .get(
          this.url + `GetPatientPlanReport?patientId=${patientID}`,
          this.config,
        )
        .then((response) => {
          let relationValue = response.data ? response.data[0].relationShip : ''
          let relationName = ''
          if (!isNull(relationValue)) {
            relationName = relationship.filter(
              (item) => item.value === relationValue,
            )[0].display
          }
          this.setState({
            patientPlanReport: response.data,
            relationshipName: relationName,
          })
        })
        .catch((error) => {
         this.props.loading(false);
          console.log(error)
        })
    }
  }
  getResults = (data) => {
    let results = []
    data.labOrderTest.forEach((element) => {
      if (element.id)
        axios
          .get(this.url + `GetLabOrderResult?TestID=${element.id}`, this.config)
          .then((response) => {
            let newLabData = [...this.state.labData.labOrderTest]
            newLabData.map((data) => {
              if (data.id === element.id)
                results.push({
                  labOrderTest: data,
                  labOrderResults: response.data,
                })
              return data
            })
            this.setState({
              labResults: results,
            })
          }).catch((error) => {
            this.props.loading(false);
            console.log(error)
          })
    })
  }
  showResult = (id) => {
    const { results } = this.state
    const chk = results.filter((row) => row.labOrdertestID === id)
    if (chk.length > 0) {
      return (
        <>
          <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
            <h6 class="pl-2 pt-1">Results</h6>
          </div>
          <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless vitalstab">
            <table class="table table-sm font-weight-normal detailednotes-tr-height">
              <tbody>
                <tr>
                  <th scope="col">Result Code</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Value</th>
                  <th scope="col">Description</th>
                  <th scope="col">Value Unit</th>
                </tr>
                {results &&
                  results.map((row, index) =>
                    row.labOrdertestID === id ? (
                      <tr>
                        <td>{row.resultCode}</td>
                        <td>{row.labComments}</td>
                        <td>{row.resultValue}</td>
                        <td>{row.resultDescription}</td>
                        <td>{row.resultValueUnit}</td>
                      </tr>
                    ) : null,
                  )}
              </tbody>
            </table>
          </div>
        </>
      )
    } else return null
  }
  render() {
    const { orderDate, labId } = this.props
    const {
      userdata,
      labOrderSummary,
      practiceData,
      patientPlanReport,
      labData,
      labResults,
      relationshipName,
      labLogo,
      labSignature
    } = this.state
    return (
      <>
        <div
          className="modal fade show d-block LabOerder"
          id="SchedularModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="popup-model"
          aria-hidden="true"
        >
          <div className="modal-content" style={{ height: '80%' }}>
            <Spiner loading={this.state.loading} />

            <div className="labdragger custom modal-header">
              <h4 className="modal-title m-0 p-0" id="popup-model">
                Lab Order
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.props.closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="print-source detailednotes-greyborder detailednotes-header"
              style={{ height: '25px' }}
            >
              <div className="row">
                <div
                  className="col-12 text-right pt-1"
                  style={{ paddingRight: '30px' }}
                >
                  <ReactToPrint
                    trigger={() => (
                      <Link
                        style={{
                          cursor: 'pointer',
                          borderRight: '1.5px solid #ccc',
                          color: '#007bff',
                        }}
                        className="pr-2"
                      >
                        Print
                      </Link>
                    )}
                    content={() => this.componentRef}
                  />
                </div>
              </div>
            </div>
            <div
              className="modal-body p-0 m-0 mt-1"
              style={{ overflowY: 'auto', maxHeight: '80vh' }}
            >
              <div
                ref={(el) => (this.componentRef = el)}
                className="container detail-report"
                style={{ pageBreakBefore: 'always' }}
              >
                <div class="wrapper">
                  <header class="header">
                    {' '}
                    <div class="header-item  header-item-drop20">
                      <img src={`data:image/jpeg;base64,${labLogo}`} alt="" />
                    </div>
                    <div class="header-item">
                      {/* <img src={medifusionLogo} alt="" /> */}
                      <p class="sm-txt">
                        {labOrderSummary.practciename} <br />
                        {labOrderSummary.practiceaddress} <br />
                        {labOrderSummary.npi}
                      </p>
                    </div>
                    <div class="header-item header-item-drop20">
                      <div class="dec-txt">
                        Group NPI # {labOrderSummary.practiceNpi}
                      </div>
                      <div class="dec-txt">
                        CLIA # {labOrderSummary.locationCliaNumber}
                      </div>
                    </div>
                  </header>
                  <div class="row-plain">
                    <div class="width-50">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">Account #:</td>
                            <td width="300px">{userdata.AccountNum}</td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Req #:</td>
                            <td>
                              {' '}
                              {`${Format(orderDate, 'yyyy-mm-dd')
                                .replace('-', '')
                                .replace('-', '')}-${labId}`}
                            </td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Bill Type:</td>
                            <td>{labData.BillType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="width-50">
                      <table>
                      <tbody>
                              <tr>
                                <td class="bold-label01">
                                  Collection Date:
                                </td>
                                <td width="300px">{labData.orderDate && labData.orderDate.includes('T')?labData.orderDate.split('T')[0]:labData.orderDate}</td>
                              </tr>
                              <tr>
                                <td class="bold-label01">
                                  Order Type:
                                </td>
                                <td width="300px">{labData.orderType}</td>
                              </tr>
                            </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row-plain">
                    <div class="colored-Strip">
                      <div class="width-50">
                        <table>
                          <tbody>
                            <tr>
                              <td class="bold-label01">
                                Client / Ordering Site:
                              </td>
                              <td>{labOrderSummary.locationName} </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="width-50">
                        <table>
                          <tbody>
                            <tr>
                              <td class="bold-label01">Ordering Physician:</td>
                              <td width="300px">{labOrderSummary.name}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="width-50">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">Address:</td>
                            <td width="300px">
                            {labOrderSummary.locationAddress}
                            </td>
                          </tr>
                          <tr>
                            <td class="bold-label01">City, state, Zip:</td>
                            <td>
                              {' '}
                              {`${
                                    labOrderSummary.locationCity
                                      ? `${labOrderSummary.locationCity},`
                                      : ''
                                  }${
                                    labOrderSummary.locationState
                                      ? `${labOrderSummary.locationState},`
                                      : ''
                                  }${
                                    labOrderSummary.locationZipCode
                                      ? `${labOrderSummary.locationZipCode}`
                                      : ''
                                  }`}
                            </td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Phone:</td>
                            <td>{labOrderSummary.locationPhoneNumber}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="width-50">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">NPI:</td>
                            <td width="300px">{labOrderSummary.npi}</td>
                          </tr>
                          <tr>
                            <td class="bold-label01">UPIN:</td>
                            <td>{labOrderSummary.upinNumber}</td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Physician ID:</td>
                            <td>{labData.providerID}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row-plain">
                    <div class="colored-Strip">
                      <div class="width-50">
                        <table>
                          <tbody>
                            <tr>
                              <td class="bold-label01">Patient Information</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="width-50">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">Name:</td>
                            <td width="300px">{userdata.PatientName}</td>
                          </tr>
                          <tr>
                            <td rowspan="2" class="bold-label01">
                              Date of Birth:
                            </td>
                            <td rowspan="2">{userdata.DOB}</td>
                          </tr>
                          <tr>
                            <td class="bold-label02">Gender:</td>
                            <td>
                              {userdata.Gender === 'M' ? (
                                <>Male</>
                              ) : userdata.Gender === 'F' ? (
                                <>Female</>
                              ) : null}
                            </td>
                          </tr>
                          {/* <tr>
                                  <td class="bold-label01">
                                    City, state, Zip:
                                  </td>
                                  <td>Newyork, NY, 456987</td>
                                </tr> */}
                          <tr>
                            <td class="bold-label01">Phone:</td>
                            <td>{userdata.phoneNumber}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="width-50">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">SSN:</td>
                            <td width="300px">{userdata.SSN}</td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Patient Id:</td>
                            <td>{userdata.ID}</td>
                          </tr>
                          <tr>
                            <td class="bold-label01">Phone:</td>
                            <td>{userdata.phoneNumber}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row-plain">
                    <div class="colored-Strip">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">Test Recorded:</td>
                            <td>{`(Total :${
                              labData.labOrderTest
                                ? labData.labOrderTest.length
                                : 0
                            })`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table>
                      <tbody>
                        {labResults.map((order) => (
                          <>
                            {order.labOrderTest ? (
                              <>
                                {' '}
                                <span
                                  style={{ padding: '3px 7px' }}
                                >{`${order.labOrderTest.code} - ${order.labOrderTest.description}`}</span>
                                <center>
                                  <div
                                    class="row-plain"
                                    style={{ width: '95%' }}
                                  >
                                    <div class="colored-Strip">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <td class="bold-label01">
                                              Report Date
                                            </td>
                                            <td class="bold-label01">
                                              Observations
                                            </td>
                                            <td class="bold-label01">Result</td>
                                            <td class="bold-label01">Status</td>
                                           {/* <td class="bold-label01">
                                              Reference
                                            </td> */}
                                            <td class="bold-label01">
                                              Notes
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <table>
                                      {' '}
                                      {order.labOrderResults.length > 0 ? (
                                        <>
                                          {order.labOrderResults.map((row) => (
                                            <tr>
                                              <td class="label03">
                                              {
                                              row.observationDatetime && row.observationDatetime.includes('T')?row.observationDatetime.split('T')[0]:row.observationDatetime
                                              }
                                               
                                              </td>
                                              <td class="label03">
                                                {row.resultDescription}
                                              </td>
                                              <td class="label03">
                                              {`${
                                                    row.resultValue
                                                      ? `${row.resultValue}`
                                                      : ''
                                                  }
                                                  ${
                                                    row.resultValue && row.resultValueUnit?
                                                    '-':''
                                                  }
                                                  ${
                                                    row.resultValueUnit
                                                      ? `${row.resultValueUnit}`
                                                      : ''
                                                  }`}
                                              </td>
                                              <td class="label03">
                                                {
                                                  row.resultStatus
                                                }
                                              </td>
                                              {/* <td class="label03">
                                                {
                                                  row.recomendedValue
                                                }
                                              </td> */}
                                              <td class="label03">
                                                {
                                                  row.comments
                                                }
                                              </td>
                                            </tr>
                                          ))}
                                        </>
                                      ) : (
                                        <center> No Results</center>
                                      )}
                                    </table>
                                  </div>
                                </center>
                              </>
                            ) : null}
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div class="row-plain">
                    <div class="colored-Strip">
                      <table>
                        <tbody>
                          <tr>
                            <td class="bold-label01">Diagnosis Codes:</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table>
                      <tbody>
                        {labData.labDiagnose ? (
                          <>
                            {' '}
                            {labData.labDiagnose.map((row) => (
                              <tr>
                                <td style={{ padding: '3px 7px' }}>
                                  {row.code}
                                </td>
                              </tr>
                            ))}
                          </>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                  {patientPlanReport.length > 0 ? (
                    <>
                      <div class="row-plain">
                        <div class="colored-Strip">
                          <div class="width-50">
                            <table>
                              <tbody>
                                <tr>
                                  <td class="bold-label01">
                                    Primary Insurance:
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="width-50">
                            <table>
                              <tbody>
                                <tr>
                                  <td class="bold-label01">
                                    Secondary Insurance:
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {patientPlanReport &&
                          patientPlanReport.map((row) => (
                            <div class="width-50">
                              <table>
                                <tbody>
                                  <tr>
                                    <td class="bold-label01">Ins Co Name</td>
                                    <td width="300px">{row.planName}</td>
                                  </tr>
                                  <tr>
                                    <td class="bold-label01">Ins Address 1</td>
                                    <td>{row.insuranceAddress}</td>
                                  </tr>
                                  <tr>
                                    <td rowspan="2" class="bold-label01">
                                      City, state, Zip:
                                    </td>
                                    <td rowspan="2">
                                      {`${
                                        row.insuranceCity
                                          ? `${row.insuranceCity},`
                                          : ''
                                      }${
                                        row.insuranceState
                                          ? `${row.insuranceState},`
                                          : ''
                                      }${
                                        row.insuranceZip
                                          ? `${row.insuranceZip}`
                                          : ''
                                      }`}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td class="bold-label01">NY:</td>
                                    <td>1236-456987</td>
                                  </tr> */}
                                  <tr>
                                    <td class="bold-label01">Policy Number</td>
                                    <td>{row.subscriberId}</td>
                                  </tr>
                                  <tr>
                                    <td class="bold-label01">Group Number</td>
                                    <td>{row.groupNumber}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                      <div class="row-plain">
                        <div class="colored-Strip">
                          <div class="width-50">
                            <table>
                              <tbody>
                                <tr>
                                  <td class="bold-label01">
                                    Primary Plicy Holder / Insured:
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="width-50">
                            <table>
                              <tbody>
                                <tr>
                                  <td class="bold-label01">
                                    Secondary Plicy Holder / Insured:
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {patientPlanReport &&
                          patientPlanReport.map((row) => (
                            <div class="width-50">
                              <table>
                                <tbody>
                                  <tr>
                                    <td class="bold-label01">Insured Name: </td>
                                    <td width="300px">{row.subscriberName}</td>
                                  </tr>
                                  <tr>
                                    <td class="bold-label01">
                                      Insured Address:{' '}
                                    </td>
                                    <td> {row.subscriberAddress}</td>
                                  </tr>
                                  <tr>
                                    <td class="bold-label01">
                                      Insured Relation to PT:
                                    </td>
                                    <td>{relationshipName}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : null}

                  <div class="footer">
                    <img src={`data:image/jpeg;base64,${labSignature}`}  alt="" />
                  </div>
                </div>

                {/* <div class="form-row font-weight-normal mt-2 mb-1">
                    <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                      <h6 class="pl-2 pt-1">Lab Print Request</h6>
                    </div>
                    <div class="form-group col-6 pl-2 pr-2 pt-2 pb-0 m-0 mb-2">
                      <table class="table table-sm font-weight-normal detailednotes-tr-height">
                        <thead>
                          <tr>
                            <th colSpan="4" className="text-center">
                              Patient Info
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Patient Name:</td>
                            <td>
                              {!isNull(this.state.userdata.PatientName)
                                ? this.state.userdata.PatientName
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>DOB:</td>
                            <td>{this.state.userdata.DOB}</td>
                            <td>Gender:</td>
                            <td>
                              {!isNull(this.state.userdata.Gender)
                                ? this.state.userdata.Gender
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td>
                              {!isNull(this.state.userdata.Address)
                                ? this.state.userdata.Address
                                : ""}
                            </td>

                            <td></td>
                            <td></td>
                          </tr>
                     
                          <tr>       
                            <td>Phone:</td>
                            <td>
                              {!isNull(this.state.userdata.phoneNumber)
                                ? this.state.userdata.phoneNumber
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="form-group col-6 pl-2 pr-2 pt-2 pb-0 m-0 mb-2">
                      <table class="table table-sm font-weight-normal detailednotes-tr-height ">
                        <thead>
                          <tr>
                            <th colSpan="4" className="text-center">
                              Client Info
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Practice Name</td>
                            <td>
                              {!isNull(this.state.practiceData.name)
                                ? this.state.practiceData.name
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>
                              {!isNull(this.state.practiceData.address)
                                ? this.state.practiceData.address
                                : ""}
                            </td>

                           
                          </tr>
                          <tr>
                            <td>Phone</td>
                            <td>
                              {!isNull(this.state.practiceData.phoneNumber)
                                ? this.state.practiceData.phoneNumber
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {this.state.labData.labOrderTest.length > 0 ? (
                      <>
                        <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                          <h6 class="pl-2 pt-1">Tests</h6>
                        </div>
                        <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                          <table class="table table-sm font-weight-normal detailednotes-tr-height">
                            <tbody>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                              </tr>
                              {this.state.labData.labOrderTest &&
                                this.state.labData.labOrderTest.map(
                                  (row, index) => (
                                    <React.Fragment key={index}>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          {!isNull(row.code) ? row.code : ""}
                                        </td>
                                        <td>
                                          {!isNull(row.description)
                                            ? row.description
                                            : ""}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="3">
                                          {row.id
                                            ? this.showResult(row.id)
                                            : null}
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null}

                    {this.state.labData.labDiagnose ? (
                      <>
                        <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                          <h6 class="pl-2 pt-1">Diagnose</h6>
                        </div>
                        <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                          <table class="table table-sm font-weight-normal detailednotes-tr-height">
                            <tbody>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                              </tr>
                              {this.state.labData.labDiagnose &&
                                this.state.labData.labDiagnose.map(
                                  (row, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {!isNull(row.code) ? row.code : ""}
                                      </td>
                                      <td>
                                        {!isNull(row.description)
                                          ? row.description
                                          : ""}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null}
                  </div> */}
                {/* 
                    {this.state.labData.labOrderTest ? (
                      <>
                        <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                          <h6 class="pl-2 pt-1">Tests</h6>
                        </div>
                        <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                          <table class="table table-sm font-weight-normal detailednotes-tr-height">
                            <tbody>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                              </tr>
                              {this.state.labData.labOrderTest &&
                                this.state.labData.labOrderTest.map(
                                  (row, index) => (
                                    <tr>
                                      <>
                                        <th scope="row">{index + 1}</th>
                                        <td>{row.code}</td>
                                        <td>{row.description}</td>
                                      </>
                                    </tr>
                                  ),
                                )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null} */}
                {/* {this.state.labData.labDiagnose ? (
                      <>
                        <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
                          <h6 class="pl-2 pt-1">Diagnose</h6>
                        </div>
                        <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
                          <table class="table table-sm font-weight-normal detailednotes-tr-height">
                            <tbody>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Code</th>
                                <th scope="col">Description</th>
                              </tr>
                              {this.state.labData.labDiagnose &&
                                this.state.labData.labDiagnose.map(
                                  (row, index) => (
                                    <tr>
                                      <>
                                        <th scope="row">{index + 1}</th>
                                        <td>{row.code}</td>
                                        <td>{row.description}</td>
                                      </>
                                    </tr>
                                  ),
                                )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null} */}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    setupLeftMenu: state.leftNavigationMenus,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: '', isLogin: false },
  }
}
export default connect(mapStateToProps, null)(LabDetailReport)
// import React from "react";
// import Draggable from "react-draggable";
// import Spiner from "./Spiner";
// import "../css/PrintStyle.css";
// import ReactToPrint from "react-to-print";
// import axios from "axios";
// import { connect } from "react-redux";
// import isNull from "../functions/nullChecking";

// class LabDetailReport extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.url = process.env.REACT_APP_URL + "/PatientPortal/";

//     this.config = {
//       headers: {
//         Authorization: "Bearer  " + this.props.loginObject.token,
//         Accept: "*/*",
//       },
//     };
//     this.state = {
//       practiceData: [],
//       labData: {
//         labOrderTest: [],
//         labDiagnose: [],
//       },
//       userdata: [],
//       results: [],
//     };
//   }
//   componentDidMount = async () => {
//     const { labId } = this.props;
//     if (labId != null) {
//       await axios
//         .get(this.url + `GetLabOrders?OrderID=${labId}`, this.config)
//         .then((response) => {
//           this.setState({
//             labData: response.data.labOrders,
//             practiceData: response.data.practice,
//             userdata: response.data.summarypatientHeader,
//           });
//           this.getResults(response.data.labOrders);
//         });
//     }
//   };
//   getResults = (data) => {
//     let results = [];
//     data.labOrderTest.forEach((element) => {
//       if (element.id)
//         axios
//           .get(this.url + `GetLabOrderResult?TestID=${element.id}`, this.config)
//           .then((response) => {
//             results.push(...response.data);
//             this.setState({
//               results: results,
//             });
//           });
//     });
//   };
//   showResult = (id) => {
//     const { results } = this.state;
//     const chk = results.filter((row) => row.labOrdertestID === id);
//     if (chk.length > 0) {
//       return (
//         <>
//           <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header text-left">
//             <h6 class="pl-2 pt-1">Results</h6>
//           </div>
//           <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
//             <table class="table table-sm font-weight-normal detailednotes-tr-height">
//               <tbody>
//                 <tr>
//                   <th scope="col"  >Result Code</th>
//                   <th scope="col" >Comments</th>
//                   <th scope="col" >Value</th>
//                   <th scope="col"  >Description</th>
//                   <th scope="col" >Value Unit</th>
//                 </tr>
//                 {results &&
//                   results.map((row, index) =>
//                     row.labOrdertestID === id ? (
//                       <tr className = "borderTop">
//                         <td >{row.resultCode}</td>
//                         <td>{row.labComments}</td>
//                         <td>{row.resultValue}</td>
//                         <td>{row.resultDescription}</td>
//                         <td>{row.resultValueUnit}</td>
//                       </tr>
//                     ) : null
//                   )}
//               </tbody>
//             </table>
//           </div>
//         </>
//       );
//     } else return null;
//   };
//   render() {
//     return (
//       <>
//         <Draggable
//           bounds={{ top: -25, left: -600, right: 600, bottom: 400 }}
//           handle=".labdragger"
//         >
//           <div
//             className="modal fade show d-block LabOerder"
//             id="SchedularModal"
//             tabindex="-1"
//             role="dialog"
//             aria-labelledby="popup-model"
//             aria-hidden="true"
//           >
//             <div className="modal-content">
//               <Spiner loading={this.state.loading} />

//               <div className="labdragger custom modal-header">
//                 <h4 className="modal-title m-0 p-0" id="popup-model">
//                   Lab Order
//                 </h4>
//                 <button
//                   type="button"
//                   className="close"
//                   data-dismiss="modal"
//                   aria-label="Close"
//                   onClick={this.props.closeModal}
//                 >
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>

//               <div
//                 className="print-source detailednotes-greyborder detailednotes-header"
//                 style={{ height: "25px" }}
//               >
//                 <div className="row">
//                   <div
//                     className="col-12 text-right pt-1"
//                     style={{ paddingRight: "30px" }}
//                   >
//                     <ReactToPrint
//                       trigger={() => (
//                         <a
//                           style={{
//                             cursor: "pointer",
//                             borderRight: "1.5px solid #ccc",
//                             color: "#007bff",
//                           }}
//                           className="pr-2"
//                           href = "#Print"
//                         >
//                           Print
//                         </a>
//                       )}
//                       content={() => this.componentRef}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div
//                 className="modal-body p-0 m-0 mt-1"
//                 style={{ overflowY: "auto", maxHeight: "80vh" }}
//               >
//                 <div
//                   ref={(el) => (this.componentRef = el)}
//                   className="container detail-report"
//                   style={{ pageBreakBefore: "always" }}
//                 >
//                   <div class="form-row font-weight-normal mt-2 mb-1">
//                     <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
//                       <h6 class="pl-2 pt-1">Lab Print Request</h6>
//                     </div>
//                     <div class="form-group col-6 pl-2 pr-2 pt-2 pb-0 m-0 mb-2">
//                       <table class="table table-sm font-weight-normal detailednotes-tr-height patientinfo">
//                         <thead className = "detailednotes-greyborder">
//                           <tr >
//                             <td colSpan="4" className="text-center font-weight-bold">
//                               Patient Info
//                             </td>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <td className = "font-weight-bold">Patient Name:</td>
//                             <td colSpan="3">
//                               {!isNull(this.state.userdata.patientName)
//                                 ? this.state.userdata.patientName
//                                 : ""}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td className = "font-weight-bold">DOB:</td>
//                             <td>  {!isNull(this.state.userdata.DOB)?this.state.userdata.DOB: "N/A" }</td>
//                             <td className = "font-weight-bold">Gender:</td>
//                             <td>
//                               {!isNull(this.state.userdata.patientGender)
//                                 ? this.state.userdata.patientGender
//                                 : ""}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td className = "font-weight-bold">Address:</td>
//                             <td colSpan="3">
//                               {!isNull(this.state.userdata.patientAddress)
//                                 ? this.state.userdata.patientAddress
//                                 : ""},
//                                  {!isNull(this.state.userdata.patientCity)
//                                 ? this.state.userdata.patientCity
//                                 : ""},
//                                    {!isNull(this.state.userdata.patientState)
//                                 ? this.state.userdata.patientState
//                                 : ""},
//                                    {!isNull(this.state.userdata.patientZipCode)
//                                 ? this.state.userdata.patientZipCode
//                                 : ""}
//                             </td>
//                           </tr>
//                           <tr>
//                             <td className = "font-weight-bold">Phone:</td>
//                             <td>
//                               {!isNull(this.state.userdata.patientphoneNumber)
//                                 ? this.state.userdata.patientphoneNumber
//                                 : ""}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>

//                     <div class="form-group col-6 pl-2 pr-2 pt-2 pb-0 m-0 mb-2">
//                       <table class="table table-sm font-weight-normal detailednotes-tr-height clientinfo">
//                         <thead className = "detailednotes-greyborder">
//                           <tr>
//                             <td colSpan="4" className="text-center font-weight-bold">
//                               Client Info
//                             </td>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr className = "PatientInfo">
//                             <td className = "font-weight-bold">Practice Name</td>
//                             <td>
//                               {!isNull(this.state.practiceData.name)
//                                 ? this.state.practiceData.name
//                                 : ""}
//                             </td>
//                           </tr>
//                           <tr className = "PatientInfo">
//                             <td className = "font-weight-bold">Address</td>
//                             <td>
//                               {!isNull(this.state.practiceData.address)
//                                 ? this.state.practiceData.address
//                                 : ""}
//                             </td>
//                           </tr>
//                           <tr className = "PatientInfo">
//                             <td className = "font-weight-bold" >Phone</td>
//                             <td>
//                               {!isNull(this.state.practiceData.phoneNumber)
//                                 ? this.state.practiceData.phoneNumber
//                                 : ""}
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>

//                     {this.state.labData.labOrderTest.length > 0 ? (
//                       <>
//                         <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
//                           <h6 class="pl-2 pt-1">Tests</h6>
//                         </div>
//                         <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
//                           <table class="table table-sm font-weight-normal detailednotes-tr-height">
//                             <tbody>
//                               <tr>
//                                 <th scope="col"  className = "detailednotes-greyborder">#</th>
//                                 <th scope="col"  className = "detailednotes-greyborder">Code</th>
//                                 <th scope="col"  className = "detailednotes-greyborder">Description</th>
//                               </tr>
//                               {this.state.labData.labOrderTest &&
//                                 this.state.labData.labOrderTest.map(
//                                   (row, index) => (
//                                     <React.Fragment key={index}>
//                                       <tr className = "borderTop">
//                                         <td scope="row">{index + 1}</td>
//                                         <td>
//                                           {!isNull(row.code) ? row.code : ""}
//                                         </td>
//                                         <td>
//                                           {!isNull(row.description)
//                                             ? row.description
//                                             : ""}
//                                         </td>
//                                       </tr>
//                                       <tr>
//                                         <td colSpan="3">
//                                           {row.id
//                                             ? this.showResult(row.id)
//                                             : null}
//                                         </td>
//                                       </tr>
//                                     </React.Fragment>
//                                   )
//                                 )}
//                             </tbody>
//                           </table>
//                         </div>
//                       </>
//                     ) : null}

//                     {this.state.labData.labDiagnose ? (
//                       <>
//                         <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header text-left">
//                           <h6 class="pl-2 pt-1">Diagnose</h6>
//                         </div>
//                         <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
//                           <table class="table table-sm font-weight-normal detailednotes-tr-height">
//                             <tbody>
//                               <tr>
//                                 <th scope="col"  className = "detailednotes-greyborder">#</th>
//                                 <th scope="col"  className = "detailednotes-greyborder">Code</th>
//                                 <th scope="col"  className = "detailednotes-greyborder">Description</th>
//                               </tr>
//                               {this.state.labData.labDiagnose &&
//                                 this.state.labData.labDiagnose.map(
//                                   (row, index) => (
//                                     <tr className = "borderTop">
//                                       <td scope="row">{index + 1}</td>
//                                       <td>
//                                         {!isNull(row.code) ? row.code : ""}
//                                       </td>
//                                       <td>
//                                         {!isNull(row.description)
//                                           ? row.description
//                                           : ""}
//                                       </td>
//                                     </tr>
//                                   )
//                                 )}
//                             </tbody>
//                           </table>
//                         </div>
//                       </>
//                     ) : null}
//                   </div>
//                   {/*
//                     {this.state.labData.labOrderTest ? (
//                       <>
//                         <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
//                           <h6 class="pl-2 pt-1">Tests</h6>
//                         </div>
//                         <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
//                           <table class="table table-sm font-weight-normal detailednotes-tr-height">
//                             <tbody>
//                               <tr>
//                                 <th scope="col">#</th>
//                                 <th scope="col">Code</th>
//                                 <th scope="col">Description</th>
//                               </tr>
//                               {this.state.labData.labOrderTest &&
//                                 this.state.labData.labOrderTest.map(
//                                   (row, index) => (
//                                     <tr>
//                                       <>
//                                         <th scope="row">{index + 1}</th>
//                                         <td>{row.code}</td>
//                                         <td>{row.description}</td>
//                                       </>
//                                     </tr>
//                                   ),
//                                 )}
//                             </tbody>
//                           </table>
//                         </div>
//                       </>
//                     ) : null} */}
//                   {/* {this.state.labData.labDiagnose ? (
//                       <>
//                         <div class="form-group col-12 p-0 m-0 detailednotes-greyborder detailednotes-header">
//                           <h6 class="pl-2 pt-1">Diagnose</h6>
//                         </div>
//                         <div class="form-group col-12 pl-2 pr-2 pt-2 pb-0 m-0 mb-2 detailednotes-grey-border-topless">
//                           <table class="table table-sm font-weight-normal detailednotes-tr-height">
//                             <tbody>
//                               <tr>
//                                 <th scope="col">#</th>
//                                 <th scope="col">Code</th>
//                                 <th scope="col">Description</th>
//                               </tr>
//                               {this.state.labData.labDiagnose &&
//                                 this.state.labData.labDiagnose.map(
//                                   (row, index) => (
//                                     <tr>
//                                       <>
//                                         <th scope="row">{index + 1}</th>
//                                         <td>{row.code}</td>
//                                         <td>{row.description}</td>
//                                       </>
//                                     </tr>
//                                   ),
//                                 )}
//                             </tbody>
//                           </table>
//                         </div>
//                       </>
//                     ) : null} */}
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* </div> */}
//         </Draggable>
//       </>
//     );
//   }
// }
// function mapStateToProps(state) {
//   return {
//     setupLeftMenu: state.leftNavigationMenus,
//     loginObject: state.loginToken
//       ? state.loginToken
//       : { toekn: "", isLogin: false },
//   };
// }
// export default connect(mapStateToProps, null)(LabDetailReport);
