import React, { Component } from "react";
// import Draggable from "react-draggable";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginAction } from "../Actions/LoginAction";
import { userInfo } from "../Actions/userInfo";
import isNull from "../functions/nullChecking";
import Swal from "sweetalert2";
import axios from "axios";
class ViewPaymentStatement extends Component {
  constructor(props) {
    super(props);

    this.url2 =
      process.env.REACT_APP_URL + "/PatientPortal/GetInternalMessages";

    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.sendAttachmentModel = {
      document_address: [],
    };

    this.state = {
      promise: "",
      sendAttachmentModel: this.sendAttachmentModel,
      responseOfAttachemnt: "",
      openStatementModal: false,
      modal: false,
      searchModel: this.searchModel,
      TotalBalance: "",
      dos: "",
      loading: true,
      PatientInfo: [{}],
      end: "",
      data: [],
      AllMessages: [],
      FollowupList: [],
      newList: [],
      table: [],
      arr: [],
      openComposeEmail: false,
      replyData: "",
      checkReply: false,
    };
  }
  componentDidMount() {
    this.getstatment(this.props.filename);
  }
  getstatment = async (filename) => {
    this.setState({ loading: true, openStatementModal: true });
    var arr = [];
    arr.push(filename);

    await this.setState({
      sendAttachmentModel: {
        ...this.state.sendAttachmentModel,
        document_address: arr,
      },
    });

    await axios
      .post(
        this.url + "getstatment",
        this.state.sendAttachmentModel,
        this.config
      )
      .then((response) => {
        this.setState({
          responseOfAttachemnt: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });

        if (error.response) {
          if (error.response.status) {
            Swal.fire("Something Went Wrong", "", "error");
            return;
          }
        } else if (error.request) {
          return;
        } else {
          return;
        }
      });
  };

  keyHandling = (event) => {
    if (event.keyCode === 27) {
      if (event.target.id === "attachmentModal") {
        this.props.closeAttachment();
       
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="tab-content viewPatientStatement container-fluid p-0 m-0">
          <div className="tab-pane fade show active" id="dashboard">
            <div
              className="row mb-1 pt-1"
              style={{ backgroundColor: "#dff0f6" }}
            >
              {this.props.openStatementModal ? (
                
                // <Draggable
                //   bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
                //   handle="handler"
                  
                // >
                  <div
                    className="attachmentModal fade show"
                    id="attachmentModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby=""
                    aria-hidden="true"
                    onKeyDown={this.keyHandling}
                  >
                    <div className="attachmentModal-content">
                      <handler>
                        <div className="custom modal-header">
                          <h4 className="modal-title m-0 p-0" id="popup-model">
                            Patient Statement
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="attachmentModal"
                            aria-label="Close"
                            onClick={this.props.closeAttachment}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      </handler>
                      <div className="attachmentModal-body p-0 m-0 ">
                        <div className="form-row">
                          <div className="form-group col-md-12 col-sm-12 col-12">
                            {!isNull(this.state.responseOfAttachemnt) ? (
                              <iframe
                                src={
                                  "data:application/pdf;base64," +
                                  this.state.responseOfAttachemnt
                                }
                                id="attachmentModal"
                                title = "View Attachemnt"
                                style={{
                                  height: "87vh",
                                  margin: " 0px ",
                                  padding: "0px",
                                  width: "100%",
                                }}
                                onKeyDown={this.keyHandling}
                              />
                            ) : (
                              <span style={{ fontSize: "17px" }}>
                                Please Wait....
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                // </Draggable>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInformation: state.userInformation,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      userInfo: userInfo,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ViewPaymentStatement);
