import React, { Component } from 'react'
import { MDBDataTable } from 'mdbreact'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import axios from 'axios'
import { userInfo } from '../Actions/userInfo'
import GifLoader from 'react-gif-loader'
import Eclips from '../images/loading_spinner.gif'
// import ShowAppointment from "./ShowAppointment";
import BookAppointment from './BookAppointment'
import isNull from '../functions/nullChecking'
import Swal from 'sweetalert2'
class Appointment extends Component {
  constructor(props) {
    super(props)
    this.url = process.env.REACT_APP_URL + '/PatientPortal/'
    this.config = {
      headers: {
        Authorization: 'Bearer  ' + this.props.loginObject.token,
        Accept: '*/*',
      },
    }

    this.searchModel = {
      fromDate: '01/01/1999',
      toDate: '12/30/2099',
      fromTime: '',
      toTime: '',
      LocationID: [],
      ProviderID: [],
      visitReasonID: '',
      status: '',
      accountNum: '',
      firstName: '',
      lastName: '',
      dob: '',
    }
    this.state = {
      searchModel: this.searchModel,
      openBookAppointment: false,
      loading: false,
      data: [],
      table: [],
      table2: [],
      Columns: [
        {
          label: 'Date',
          field: 'appointmentDate',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Location',
          field: 'location',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Provider',
          field: 'provider',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Visit Reason',
          field: 'visitReason',
          sort: 'asc',
          width: 100,
        },

        {
          label: 'Duration',
          field: 'timeInterval',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'Status',
          field: 'status',
          sort: 'asc',
          width: 100,
        },
      ],

      Columns2: [
        {
          label: 'Date',
          field: 'appointmentDate',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Location',
          field: 'location',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Provider',
          field: 'provider',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Visit Reason',
          field: 'visitReason',
          sort: 'asc',
          width: 100,
        },

        {
          label: 'Duration',
          field: 'timeInterval',
          sort: 'asc',
          width: 50,
        },
        {
          label: 'Status',
          field: 'status',
          sort: 'asc',
          width: 100,
        },
      ],

      client: '',
      providers: [],
      type: [],
      userLocation: [],
    }
  }

  componentDidMount() {
    this.getappointments()
    this.getPatientProviderData()
  }

  getPatientProviderData = () => {
    axios
      .get(this.url + 'GetProfiles', this.config)
      .then((response) => {
        this.setState({
          client: response.data.client,
          providers: response.data.providers,
          type: response.data.type,
          userLocation: response.data.userLocations,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }
  getappointments = () => {
    try {
      this.setState({ loading: true })

      axios
        .post(
          this.url + 'FindAppointments',
          this.state.searchModel,
          this.config,
        )
        .then((response) => {
          let newList = []
          let newList2 = []
          let date = new Date()
          try {
            response.data.forEach((row, i) => {
              date > new Date(row.appointmentDate)
                ? newList.push({
                    appointmentDate: row.appointmentDate,
                    accountNum: row.accountNum,
                    patient: row.patient,
                    dob: row.patientDOB,
                    provider: row.provider,
                    plan: row.plan,
                    location: row.location,
                    visitReason: row.visitReason,
                    status: row.statusDescription,
                    timeInterval: row.timeInterval + ' M',
                  })
                : newList2.push({
                    appointmentDate: row.appointmentDate,
                    accountNum: row.accountNum,
                    patient: row.patient,
                    dob: row.patientDOB,
                    provider: row.provider,
                    plan: row.plan,
                    location: row.location,
                    visitReason: row.visitReason,
                    status: row.statusDescription,
                    timeInterval: row.timeInterval + ' M',
                  })
              newList2 = newList2.sort((first, second) => {
                return first.appointmentDate < second.appointmentDate ? -1 : 1
              })
            })
            this.setState({
              //data: newList,
              loading: false,
              table: {
                columns: this.state.Columns,
                rows: newList,
              },
              table2: {
                columns: this.state.Columns,

                rows: newList2,
              },
              //loading: false,
            })
          } catch (error) {
            this.setState({ loading: false })
            console.log(error)
          }
        })
        .catch((error) => {
          this.setState({ loading: false })
          try {
            if (error.response) {
              if (error.response.status) {
                if (error.response.status === 401) {
                  Swal.fire('Unauthorized Access', '', 'error')
                  return
                } else if (error.response.status === 404) {
                  Swal.fire('Not Found', 'Failed With Status Code 404', 'error')
                  return
                } else if (error.response.status === 400) {
                  Swal.fire('Error', error.response.data, 'error')
                  return
                } else {
                  Swal.fire('Something Wrong', 'Please Try Again', 'error')
                  return
                }
              }
            } else {
              Swal.fire('Something went Wrong', '', 'error')
              return
            }
          } catch {}
        })
    } catch (error) {
      this.setState({ loading: false })
      console.log(error)
    }
  }

  bookAppointment = () => {
    this.setState({
      openBookAppointment: true,
    })
  }

  closeBookAppointment = () => {
    this.setState({
      openBookAppointment: false,
    })
  }

  render() {
    console.log(this.state)
    let spiner = ''
    if (this.state.loading === true) {
      spiner = (
        <div className="spiner">
          <GifLoader
            loading={true}
            imageSrc={Eclips}
            imageStyle={{ marginTop: '20%', width: '100px', height: '100px' }}
            overlayBackground="rgba(0,0,0,0.5)"
          />
        </div>
      )
    }
    let popup = ''
    if (this.state.openBookAppointment) {
      document.body.style.overflowY = 'hidden'
      popup = (
        <BookAppointment
          closeBookAppointment={this.closeBookAppointment}
          ShowAppointment={this.state.openBookAppointment}
          userInformation={this.props.userInformation}
          client={!isNull(this.state.client) ? this.state.client : ''}
          type={this.state.type}
          provider={this.state.providers}
          location={this.state.userLocation}
        />
      )
    } else {
      document.body.style.overflowY = 'auto'
    }

    return (
      <React.Fragment>
        {spiner}
        <div className="tab-pane fade show active" id="appointments">
          <div className="row m-2">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 custom-header">
                  <h6 className="pt-2 pb-1 col-md-10 float-left">
                    <b>Future Appointment(s)</b>
                  </h6>
                </div>
              </div>
              <div className="row">
                <div
                  className="tableGridContainer text-nowrap col-md-12 p-0 m-0 appointmentTable"
                  style={{ lineHeight: 1 }}
                >
                  <MDBDataTable
                    responsive={true}
                    striped
                    bordered
                    entries="6"
                    searching={false}
                    data={this.state.table2}
                    displayEntries={false}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 custom-header">
                  <h6 className="pt-2 pb-1 col-md-10 float-left">
                    <b>Past Appointment(s)</b>
                  </h6>

                  {/* <button
                    className="pt-2 btn-blue float-right"
                    onClick={this.bookAppointment}
                  >
                    Add New
                  </button> */}
                </div>
              </div>
              <div className="row">
                <div
                  className="tableGridContainer text-nowrap col-md-12 p-0 m-0 appointmentTable"
                  style={{ lineHeight: 1 }}
                >
                  <MDBDataTable
                    responsive={true}
                    striped
                    bordered
                    entries="6"
                    searching={false}
                    data={this.state.table}
                    displayEntries={false}
                  />
                </div>
              </div>
              {popup}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
function mapStateToProps(state) {
  return {
    userInformation: state.userInformation,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: '', isLogin: false },
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      userInfo: userInfo,
    },
    dispatch,
  )
}
export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(Appointment),
)

// export default Appointment;
