const isNull = (value) => {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value === "Please Select" ||
    value.length === 0
  ) {
    return true;
  } else {
    return false;
  }
};

export default isNull;
