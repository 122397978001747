import React from "react";
import Eclips from "../images/loading_spinner.gif";
import GifLoader from "react-gif-loader";

function Spiner(props) {
  const { loading } = props;
  return loading === true ? (
    <div className="spiner">
      <GifLoader
        loading={true}
        imageSrc={Eclips}
        imageStyle={{ marginTop: "20%", width: "100px", height: "100px" }}
        overlayBackground="rgba(0,0,0,0.5)"
      />
    </div>
  ) : null;
}

export default Spiner;
