import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Draggable from "react-draggable";
import Spiner from "./Spiner";
import Swal from "sweetalert2";
import Axios from "axios";
import isNull from "../functions/nullChecking";

function CheckoutForm(props) {
  const url = process.env.REACT_APP_URL + "/PatientPortal/";
  // const token = props.loginObject.token;
  const config = {
    headers: {
      Authorization: "Bearer  " + props.loginObject.token,
      Accept: "*/*",
    },
  };

  // const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  // const [disabled, setDisabled] = useState(true);
  // const [amount, setAmount] = useState(0);
  const [paymentModal, setPaymentModal] = useState({});

  const stripe = useStripe();
  //  props.stripe;
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {},
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // setDisabled(event.empty);
    setError(
      event.error ? (
        <div className="text-danger">{event.error.message}</div>
      ) : (
        ""
      )
    );
  };

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   window
  //     .fetch(url + 'create-payment-intent', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ items: [{ id: 'sk_test_51HPWJtIBQ68Ovn0xqzth6jivpIYQHdnEsSSzHCDuHkHfcbGBOqAexNI8YVeZgcJFLXz5CipUbFUUJBIA0nFkL1GT00KQOxfn8Q' }] }),
  //     })
  //     .then((res) => {
  //       return res.json()
  //     })
  //     .then((data) => {
  //       setClientSecret(data.clientSecret)
  //       alert(data.clientSecret)
  //     })
  // }, [])

  const setPayment = (e) => {
    const { value } = e.target;
    setPaymentModal({
      items: [
        { Id: 0 },
        {
          Amount: value,
        },
      ],
    });
  };

  const UpdatePaymentResponse = async (confirmData) => {
    Axios.post(url + "UpdatePaymentResponse", confirmData, config)
      .then((result) => {
        Swal.fire("Payment Done Successfully", "", "success");
      })
      .catch((error) => {
        if (error) {
          Swal.fire(`Payment failed ${error.message}`);
        }
      });
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);
    Axios.post(url + "create-payment-intent", paymentModal, config)
      .then((response) => {
        stripe
          .confirmCardPayment(response.data.clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          })
          .then((result) => {
            if (result.error) {
              setProcessing(false);
              // Swal.fire({
              //   title: 'Medifusion',
              //   showClass: {
              //     popup: 'animate__animated animate__fadeInDown'
              //   },
              //   icon: 'error',
              //   html: `Payment failed ${result.error.message}`,
              //   hideClass: {
              //     popup: 'animate__animated animate__fadeOutUp'
              //   }
              // })

              
              Swal.fire("Please Contact Administrator", `Payment failed ${result.error.message}`, "error" );
            } else {
              UpdatePaymentResponse(result.paymentIntent);
              setError(null);
              setProcessing(false);
              props.close();
            }
          })
          .catch((error) => {
            setProcessing(false);
            Swal.fire(`Payment failed ${error.message}`);
          });
      })
      .catch((error) => {
        if (error) {
          setProcessing(false);
          props.close();
          Swal.fire("Someting Went Wrong", "", "error");
        }
      });
  };

  const keyHandling = (event) => {
    if (event.keyCode === 27) {
      Swal.fire({
        title: "Do you want to close?",
        showDenyButton: true,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.value === true) {
          props.close();
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Spiner loading={processing} />
      <Draggable
        bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
        handle="handler"
        onKeyDown={keyHandling}
      >
        <div
          className="modal fade show checkOutForm"
          id="changePassword"
          role="dialog"
          aria-labelledby="changePassword"
          aria-hidden="true"
          style={{ display: "block", width: "55%", left: "25%" }}
          onKeyDown={keyHandling}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div
              className="modal-content"
              style={{ width: "60%", minHeight: "30px" }}
            >
              <handler>
                <div className="custom modal-header">
                  <h4 className="modal-title m-0 p-0" id="changePassword">
                    Patient Payment
                  </h4>
                  <button type="button" className="close" onClick={props.close}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </handler>

              <div
                className="modal-body p-2 m-0 "
                style={{ overflow: "hidden" }}
              >
                <form>
                  <div className="mainTable">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12 col-12 mb-2">
                        {!isNull(
                          props.userInformation &&
                            props.userInformation[0].frontKey
                        ) ? (
                          // props.practiceName === "DEMO-PRAC"

                          ""
                        ) : (
                          <div className="text-danger">
                            Payment Setup is not completed by Practice, Please
                            contact Administrator.
                          </div>
                        )}
                      </div>
                      <div className="col-md-2 col-sm-12 col-1">
                        <label
                          style={{
                            lineHeight: 3,
                            fontSize: "14px",
                          }}
                        >
                          Amount Due :
                        </label>
                      </div>
                      <div className="col-md-3 col-sm-12 col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Amount Due $"
                          readOnly={true}
                          value={props.AmountDue}
                        />
                      </div>
                      <div className="col-md-2 col-sm-12 col-1">
                        <label
                          style={{
                            lineHeight: 3,
                            fontSize: "14px",
                          }}
                        >
                          Amount :
                        </label>
                      </div>
                      <div className="col-md-3 col-sm-12 col-12">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Add Amount$"
                          name="Amount"
                          onChange={setPayment}
                          min="0"
                          // readOnly={
                          //   props.practiceName !== "DEMO-PRAC" ? true : false
                          // }
                        />
                      </div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-md-12 col-sm-12 col-12">
                        <form id="payment-form" onSubmit={handleSubmit}>
                          <CardElement
                            className="box form-control"
                            id="card-element"
                            options={cardStyle}
                            onChange={handleChange}
                            // readOnly={
                            //   props.practiceName !== "DEMO-PRAC" ? true : false
                            // }
                          />

                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                              {error && (
                                <div className="card-error" role="alert">
                                  {error}
                                </div>
                              )}

                              <button
                                disabled={
                                  !isNull(
                                    props.userInformation &&
                                      props.userInformation[0].frontKey
                                  )
                                    ? false
                                    : true
                                }
                                id="submit"
                                type="submit"
                                className="btn-phr"
                              >
                                <span id="button-text">
                                  {processing ? (
                                    <div className="spinner" id="spinner">
                                      Pay
                                    </div>
                                  ) : (
                                    "Pay"
                                  )}
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    userInformation: state.userInformation,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
  };
}

export default withRouter(connect(mapStateToProps, null)(CheckoutForm));
