import React, { Component } from "react";
import isNull from "../../functions/nullChecking";
// import Draggable from "react-draggable";
import "./Message.css";
import axios from "axios";
import Swal from "sweetalert2";
import Spiner from "../Spiner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// ReactReduxContext...
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginAction } from "../../Actions/LoginAction";

class ComposeInternalMessage extends Component {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };
    this.escCounter = 0;
    this.saveModal = {
      ThreadID: this.props.replyData ? this.props.replyData.threadID : "0",
      UserID: "0",
      PracticeID: "0",
      Subject: this.props.forwardData
        ? "FWD :" + this.props.forwardData.subject
        : this.props.replyData
        ? "RE :" + this.props.replyData.subject
        : "",
      Message: this.props.forwardData
        ? this.props.forwardData.message
        : this.props.draftData
        ? this.props.draftData.message
        : "",
      MessageFrom: "",
      MessageTO: [],
      MessageCC: [],
      IsRead: false,
      Attachment: false,
      MarkedAs: "",
      Category: "",
      Deleted: false,
      InActive: false,
      AddedBy: "",
      AddedDate: "",
      ClientAddedDate: "",
      UpdatedBy: "",
      UpdatedDate: "",
      Attachments: [],
      IsDraft: false,
      UserType: "User",
    };
    this.state = {
      saveModal: this.saveModal,
      openEmailModal: this.props.isOpen ? this.props.isOpen : false,
      Filename: "",
      formContent: "",
      Files: this.props.forwardData
        ? this.props.forwardData.attachments
        : this.props.draftData
        ? this.props.draftData.attachments
        : [],
      showFileDiv: false,
      searchPatient: "",
      AutoCompleteArray: [],
      showPatientName: false,
      SaveInternalMessages: [],
      patient: [],
      checked: false,
      loading: false,
      latestMessageTo: [],
      type: "User",
      searchUser: "",
      arrayUsers: [],
      disabledSaveButton: false,
      validationMSG: "",
      error: false,
    };
  }

  openEmailModal = () => {
    this.setState({
      openEmailModal: true,
    });
  };

  toggle = () => {
    try {
      this.props.closeEmailModal();
    } catch (error) {}
    try {
      this.props.closeInternalMessages();
    } catch (error) {}
    try {
      this.props.closeComposeEmailModal();
    } catch {}
    try {
      this.props.closeComposeEmailForDraft();
    } catch (error) {}
    this.closePatientName();
    this.deleteMessage();
  };

  sendMessage = async () => {
    let curruntdate = new Date();
    let clientDate = curruntdate.toLocaleString();

    await this.setState({
      loading: true,
    });
    let AttachmentsArray = [];
    let MessageTOArray = [];

    if (
      this.props.callingFrom === "Details" ||
      this.props.callingFrom === "draftData"
    ) {
      if (!isNull(this.state.Files)) {
        this.state.Files.forEach((row) => {
          AttachmentsArray.push({
            Name: row.Filename,
            DocumentString: row.content,
            Url: row.Url,
          });
        });
      }
    } else {
      await this.state.Files.forEach((row) => {
        AttachmentsArray.push({
          Name: row.Filename,
          DocumentString: row.content,
          Url: "",
        });
      });
    }
    await this.state.patient.forEach((row) => {
      return row.InActive
        ? null
        : MessageTOArray.push({
            name: row.name,
            id: row.id,
            type: row.type,
          });
    });
    await this.setState({
      Files: AttachmentsArray,
      saveModal: {
        ...this.state.saveModal,
        Attachments: AttachmentsArray,
        MessageTO: MessageTOArray,
      },
    });
    if (this.state.Files.length > 0) {
      this.setState({
        saveModal: { ...this.state.saveModal, Attachment: true },
      });
    } else {
      this.setState({
        saveModal: { ...this.state.saveModal, Attachment: false },
      });
    }
    await this.setState({
      saveModal: {
        ...this.state.saveModal,
        ClientAddedDate: clientDate,
      },
    });
    if (
      (this.state.patient.length < 1 || this.state.saveModal.Subject === "") &&
      this.state.saveModal.IsDraft === false
    ) {
      this.setState({
        error: true,
        loading: false,
      });
    } else {
      axios
        .post(
          this.url + "SaveInternalMessages",
          this.state.saveModal,
          this.config
        )
        .then((response) => {
          this.setState({
            loading: false,
            disabledSaveButton: true,
          });
          this.state.saveModal.IsDraft
            ? Swal.fire("Message Saved In Draft Successfully", "", "success")
            : Swal.fire("Message Sent Successfully", "", "success");
          this.deleteMessage();
          this.toggle();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          Swal.fire("Something Went Wrong", "", "error");
          this.deleteMessage();
        });
    }

    if (this.props.draftData) {
      this.setState({
        saveModal: { ...this.state.saveModal, IsDraft: false },
      });
    }
  };

  attachFile = (e) => {
    try {
      let arr = this.state.Files;
      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      let file = e.target.files[0];
      let content = "";
      let name = "";
      reader.onloadend = (e) => {
        try {
          content = reader.result;
          name = file.name;
        } catch {}
        content = content.substr(content.indexOf(",") + 1);
        arr.push({
          id: 99 - arr.length,
          Filename: name,
          content: content,
          Url: "",
        });
        this.setState({
          Files: arr,
          showFileDiv: true,
        });
      };
    } catch {}
  };

  deleteMessage = () => {
    this.setState({
      Files: [],
      patient: [],
      type: "",
    });
    this.closePatientName();
    this.clearAllFields();
  };

  deleteFile = (e) => {
    e.preventDefault();
    let latestFiles = [];
    this.state.Files.forEach((row) => {
      if (String(row.id) !== String(e.target.id)) {
        latestFiles.push(row);
      }
    });

    this.setState({
      Files: latestFiles,
    });
  };

  async addEventListener(e) {
    var patientId = e.target.id;
    var myValue = e.target.value;
    this.escCounter++;
    if (myValue === "") {
      this.escCounter = 0;
    }
    this.setState({
      searchPatient: e.target.value.toUpperCase(),
      disabledSaveButton: false,
    });
    var arr = [];
    var a,
      b,
      val = e.target.value;
    if (this.state.type === "User") {
      await axios
        .get(
          this.url + "SearchUsers?criteria=" + e.target.value.toUpperCase(),

          this.config
        )
        .then((response) => {
          response.data.forEach((row) => {
            arr.push({
              name: row.lastName + " " + row.firstName,
              id: row.email,
              type: "User",
            });

            this.setState({ AutoCompleteArray: arr });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
    this.closeAllLists(e);
    if (!val) {
      return false;
    }
    await this.setState({ currentFocus: -1 });
    if (this.state.AutoCompleteArray.length > 0) {
      a = document.createElement("DIV");
      a.setAttribute("id", patientId + "autocomplete-list");
      a.setAttribute("class", "allAutocomplete-items");
      document.getElementById("InternalMessagesParent").appendChild(a);
      this.state.AutoCompleteArray.forEach((row) => {
        var start = row.name.toUpperCase().indexOf(val.toUpperCase());
        if (start > -1) {
          b = document.createElement("DIV");
          b.onclick = this.optionSelected;
          b.innerHTML = row.name.substr(0, start);
          b.setAttribute("id", row.id);
          b.innerHTML +=
            `<strong id=${row.id}  onclick=this.optionSelected>` +
            row.name.substr(start, val.length) +
            `</strong>`;
          b.innerHTML += row.name.substr(start + val.length);
          b.innerHTML += "<input type='hidden' value='" + row.name + "'  >";
          a.appendChild(b);
        }
      });
    }
  }

  optionSelected = async (e) => {
    var id = e.target.id;
    var patientChk = [];
    var patient = [];
    await this.state.AutoCompleteArray.forEach((row) => {
      if (row.type === "User") {
        if (row.id === id) {
          patient.push(row);
        }
      }
      if (row.type === "Patient") {
        if (row.id === parseInt(id)) {
          patient.push(row);
        }
      }
    });
    patientChk = this.state.patient.filter(
      (row) => parseInt(row.id) === parseInt(id)
    );

    let exist = true;
    let inactiveExist = false;
    let array = [];
    if (this.state.patient.length === 0) {
      patient.forEach((row) => {
        array.push(row);
      });
      exist = false;
    } else {
      patient.forEach((row) => {
        if (patientChk.length === 0) {
          array.push(row);
          exist = false;
        } else if (patientChk.length > 0) {
          this.state.patient.forEach((row2) => {
            if (parseInt(row2.id) === parseInt(e.target.id)) {
              if (row2.InActive) {
                row2.InActive = false;
                inactiveExist = true;
                exist = false;
              }
            }
          });
        }
      });
    }
    if (!exist) {
      if (!inactiveExist) {
        let a = this.state.patient;
        array.forEach((row) => {
          a.push(row);
        });
        this.setState({ patient: a, searchPatient: "" });
      } else if (inactiveExist) {
        this.setState({ searchPatient: "" });
      }
    }
    this.closeAllLists(e);
  };
  /*execute a function presses a key on the keyboard:*/
  KeyDown = async (e) => {
    var x = document.getElementById(e.target.id + "autocomplete-list");
    if (x) x = x.getElementsByTagName("div");
    if (e.keyCode === 40) {
      /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/

      await this.setState({ currentFocus: this.state.currentFocus + 1 });

      /*and and make the current item more visible:*/
      this.addActive(x);
    } else if (e.keyCode === 38) {
      //up
      /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
      await this.setState({ currentFocus: this.state.currentFocus - 1 });
      /*and and make the current item more visible:*/
      this.addActive(x);
    } else if (e.keyCode === 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (this.state.currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) x[this.state.currentFocus].click();
      }
    }
  };

  addActive(x) {
    var crrFocus = this.state.currentFocus;
    if (!x) return false;
    this.removeActive(x);
    if (crrFocus >= x.length) {
      crrFocus = 0;
    }
    if (crrFocus < 0) {
      crrFocus = x.length - 1;
    }
    this.setState({ currentFocus: crrFocus });
    x[crrFocus].classList.add("patient-active");
  }

  removeActive(x) {
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("patient-active");
    }
  }

  closeAllLists(elmnt, e) {
    var x = document.getElementsByClassName("allAutocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt !== x[i] && elmnt !== e) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }

  closePatientName = () => {
    this.setState({
      saveModal: {
        ...this.state.saveModal,
        MessageTO: [],
      },
      searchPatient: "",
      showPatientName: false,
    });
  };

  handleChange = (event) => {
    event.preventDefault();

    var myValue = event.target.value;
    this.escCounter++;
    if (myValue === "") {
      this.escCounter = 0;
    }
    this.setState({
      saveModal: {
        ...this.state.saveModal,
        [event.target.name]: myValue,
      },
      disabledSaveButton: false,
    });
  };

  clearAllFields = () => {
    this.setState({
      saveModal: this.saveModal,
    });
  };

  saveInDraft = () => {
    this.setState({
      saveModal: {
        ...this.state.saveModal,
        IsDraft: !this.state.saveModal.IsDraft,
      },
    });
  };

  closePatient = (e) => {
    let p = this.state.patient;
    this.state.patient.forEach((row, i) => {
      if (String(row.id) === String(e.target.id)) {
        p[i].InActive = true;
      }
    });
    this.setState({
      patient: p,
    });
  };
  componentDidMount() {
    let AttachmentsArray = [];
    if (
      this.props.callingFrom === "Details" ||
      this.props.callingFrom === "draftData"
    ) {
      if (!isNull(this.state.Files)) {
        this.state.Files.forEach((row, i) => {
          let name = row.name.substr(row.name.indexOf("_") + 1);
          AttachmentsArray.push({
            id: 100 - (i + 1),
            Filename: name,
            content: "",
            Url: row.name,
          });
        });
      }
      this.setState({
        Files: AttachmentsArray,
        showFileDiv: true,
      });
    }
    if (this.props.replyData) {
      let arrForReplyData = [];

      arrForReplyData.push({
        name: this.props.replyData.fromName,
        id: this.props.replyData.messageFrom,
        type: this.props.replyData.userType,
      });
      if (this.props.checkReply) {
        let messageTo = this.props.replyData.messageTO;

        messageTo.forEach((row) => {
          if (row.id !== this.props.replyData.messageFrom)
            arrForReplyData.push(row);
        });
      }
      this.setState({
        patient: arrForReplyData,
      });
    }
    if (this.props.draftData) {
      let arrForMessageTo = [];
      this.props.draftData.messageTO.forEach((items) => {
        arrForMessageTo.push(items);
      });

      this.setState({
        saveModal: {
          ...this.state.saveModal,
          Subject: this.props.draftData.subject,
          Message: this.props.draftData.message,
        },
        patient: arrForMessageTo,
      });
    }

    document.addEventListener("click", function (elmnt, e) {
      var x = document.getElementsByClassName("allAutocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt !== x[i] && elmnt !== e) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    });
  }

  handleType = (event) => {
    this.setState({
      type: event.target.value,
    });
  };
  keyHandling = (event) => {
    if (event.keyCode === 27) {
      if (this.escCounter === 0) {
        if (event.target.id === "ComposeEmail") {
          this.toggle();
        }
      } else {
        Swal.fire({
          title: "Do you want to close?",
          showCancelButton: true,
          type: "warning",
          confirmButtonText: `Yes`,
        }).then((result) => {
          if (result.value === true) {
            this.toggle();
          }
        });
      }
    } else if (event.altKey && event.keyCode === 83) {
      this.sendMessage();
    }
  };
  clearField = () => {
    this.setState({
      searchPatient: "",
      saveModal: {
        ...this.state.saveModal,
        patientID: "",
      },
    });
  };

  handleEditor = (e, editor) => {
    const data = editor.getData();
    this.setState({
      saveModal: {
        ...this.state.saveModal,
        Message: data,
      },
    });
  };
  render() {
    const {
      saveModal,
      error,
      loading,
      patient,
      searchPatient,
      showFileDiv,
      Files,
    } = this.state;

    let showFilesData = "";
    if (showFileDiv) {
      showFilesData = (
        <div
          style={{
            width: "100%",
            float: "left",
            overflowY: "scroll"
          }}
        >
          {Files.map((items) => (
            <div className="AttachmentNames">
              <span>
                <strong className="px-2">{items.Filename}</strong>
              </span>
              <span style={{ cursor: "pointer" }}>
                <i
                  class="f16 fa text-danger fa-times-circle"
                  onClick={this.deleteFile}
                  title="Remove"
                  id={items.id}
                ></i>
              </span>
            </div>
          ))}
        </div>
      );
    }
    return (
      <React.Fragment>
        {/* <Draggable
          bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
          handle="handler"
        > */}
          <div
            className="modal fade show"
            id="ComposeEmail"
            role="dialog"
            aria-labelledby="ComposeEmail"
            aria-hidden="true"
            onKeyDown={this.keyHandling}
            style={{display:"block"}}
          >
            <Spiner loading={loading} />
            <div className="modal-dialog modal-lg " role="document">
              <div
                className="modal-content"
                style={{ width: "60%", minHeight: "30px" }}
              >
                <handler>
                  <div className="custom modal-header">
                    <h4 className="modal-title m-0 p-0" id="ComposeEmail">
                      {this.props.replyData ? (
                        <span className="m-0 font-weight-bold d-block  float-left">
                          Reply Message
                        </span>
                      ) : this.props.forwardData ? (
                        <span className="m-0 font-weight-bold d-block float-left">
                          Forward Message
                        </span>
                      ) : (
                        <span className="m-0 font-weight-bold d-block float-left">
                          New Message
                        </span>
                      )}
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={this.toggle}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </handler>

                <div
                  className="modal-body p-0 m-0 "
                  style={{ overflow: "hidden" }}
                >
                  <form>
                    <div className="clearfix"></div>
                    <div
                      className="pr-0 pb-0 mb-0 pt-1 pl-1 pr-1"
                      aria-expanded="true"
                    >
                      <div className="form-row">
                        <div className="pt-1 col-md-12 col-sm-12 col-12">
                          <div className="form-row">
                            <div className="col-lg-0 col-sm-0 float-left pt-1 col-md-0">
                              <label>To :</label>
                            </div>
                            <div className="col-lg-11 col-sm-11 col-md-11">
                              <span
                                onClick={() =>
                                  document
                                    .getElementById("searchPatientID")
                                    .focus()
                                }
                                id="InternalMessagesComposeTextArea"
                                className="InternalMessagesComposeTextArea row"
                              >
                                <span>
                                  {patient !== []
                                    ? patient.map((row, i) =>
                                        row.InActive ? null : (
                                          <span id="emailPatients">
                                            <label className="user1 ml-2">
                                              <strong className="px-2">
                                                {row.name}
                                              </strong>
                                              <span
                                                className="ml-2"
                                                style={{
                                                  cursor: "pointer",
                                                  width: "16px",
                                                  height: "16px",
                                                }}
                                              >
                                                <i
                                                  class="f16 fa text-danger fa-times-circle"
                                                  onClick={(e) =>
                                                    this.closePatient(e)
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  title="Remove"
                                                  id={row.id}
                                                ></i>
                                              </span>
                                            </label>
                                          </span>
                                        )
                                      )
                                    : ""}
                                  <span
                                    id="InternalMessagesParent"
                                    className="autocomplete"
                                    style={{ position: "relative" }}
                                  >
                                    <input
                                      autocomplete="off"
                                      value={searchPatient}
                                      onChange={(e) => this.addEventListener(e)}
                                      onKeyDown={(e) => this.KeyDown(e)}
                                      id="searchPatientID"
                                      type="text"
                                      name="searchPatient"
                                      className="searchPatient"
                                    />
                                    <div className="float-left w-100">
                                      {patient.length < 1 && error ? (
                                        <span className="text-danger float-left">
                                          Please Fill this Field
                                        </span>
                                      ) : null}
                                    </div>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-12">
                          <hr
                            style={{
                              border: "1px solid #BEBEBE",
                              marginTop: "5px",
                              marginBottom: "0px",
                            }}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-lg-0 col-sm-0 float-left pt-1 col-md-0 ml-3">
                              <label>Subject :</label>
                            </div>
                            <div className="col-lg-10 col-sm-10 float-right col-md-10">
                              <input
                                type="text"
                                autocomplete="off"
                                style={{
                                  border: "none",
                                  lineHeight: "2",
                                  outline: "none",
                                }}
                                className="w-100 p-0 m-0 col-lg-12 col-sm-12  col-md-12"
                                name="Subject"
                                value={this.state.saveModal.Subject}
                                onChange={this.handleChange}
                              />
                              {this.state.saveModal.Subject === "" &&
                              this.state.error ? (
                                <span className="text-danger float-left w-100">
                                  Please Enter Subject
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className=" col-md-12 col-sm-12 col-12">
                          <hr
                            style={{
                              border: "1px solid #BEBEBE",
                              marginTop: "0px",
                              marginBottom: "0px",
                            }}
                          />
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">
                          <div className="row">
                            <div className="col-md-12 col-sm-12 col-12">
                              <div
                                className="text-center myEditor"
                                style={{ color: "black" }}
                              >
                                <CKEditor
                                  className="p-2 w-100"
                                  editor={ClassicEditor}
                                  onChange={this.handleEditor}
                                  data={this.state.saveModal.Message}
                                  id="Message"
                                  name="Message"
                                  placeholder="Type Your Message Here..."
                                ></CKEditor>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-12 pt-2">
                          <div className="form-row">
                            <div className="col-md-7 col-sm-7 col-lg-7 pl-3">
                              <button
                                class="btn-phr text-light mt-1 mb-1 mr-1 float-left"
                                onClick={this.sendMessage}
                                type="button"
                                disabled={this.state.disabledSaveButton}
                              >
                                {this.state.saveModal.IsDraft ? "Save" : "Send"}
                              </button>

                              <label
                                for="file-upload"
                                style={{ lineHeight: "3" }}
                              >
                                <i
                                  title="Uplaod Files"
                                  style={{
                                    width: "30px",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                    marginTop: "10px",
                                  }}
                                  class="fa fa-paperclip light-blue float-right pl-1 pt-1"
                                ></i>
                                <input
                                  id="file-upload"
                                  type="file"
                                  name="files"
                                  style={{ visibility: "hidden", width: "2px" }}
                                  onChange={(e) => this.attachFile(e)}
                                  multiple
                                />
                              </label>
                            </div>
                            <div className="col-lg-5 col-sm-5 col-md-5">
                              <div
                                className="float-left w-100 pr-4"
                                style={{ lineHeight: "3" }}
                              >
                                <div className="float-right">
                                  <div className="float-left mr-2">
                                    <span>
                                      <input
                                        type="checkbox"
                                        name="IsDraft"
                                        className="mr-2"
                                        checked={saveModal.IsDraft}
                                        onChange={this.saveInDraft}
                                      />
                                    </span>
                                    <label
                                      htmlFor="Category"
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Save As Draft
                                    </label>
                                  </div>
                                  <div className="float-right">
                                    <label
                                      onClick={this.deleteMessage}
                                      href="#detete"
                                      title="Clear All"
                                      style={{
                                        color: "#03658c",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Clear All
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12">
                              {this.state.Files != null &&
                              this.state.Files.length >= 3 ? (
                                <span className="float-left w-100 overflow-auto">
                                  {showFilesData}
                                </span>
                              ) : (
                                <span className="float-left w-100">
                                  {showFilesData}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        {/* </Draggable> */}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { token: "", isLogin: false },
    userInfo1: state.loginInfo
      ? state.loginInfo
      : { userPractices: [], name: "", practiceID: null, clientID: null },
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(ComposeInternalMessage);
