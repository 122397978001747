import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { userInfo } from "../Actions/userInfo";
// import Draggable from "react-draggable";
import Spiner from "./Spiner";
import isNull from "../functions/nullChecking";

import Swal from "sweetalert2";
//Redux Actions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginAction } from "../Actions/LoginAction";

class changepassword extends Component {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };
    this.validationModel = {
      emailVal: "",
      newpass: "",
      confirmpass: "",
    };
    this.state = {
      validationModel: this.validationModel,
      sendEmail: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleConfirm = (event) => {
    this.setState({
      confirmPassword: event.target.value,
    });
  };

  changepassword(e) {
    e.preventDefault();
    this.setState({ loading: true });
    var myVal = this.validationModel;
    myVal.validation = false;
    if (isNull(this.state.newPassword)) {
      myVal.newpass = (
        <span className="validationMsg float-left text-danger">
          Enter New Password
        </span>
      );
      myVal.validation = true;
    } else if (isNull(this.state.confirmPassword)) {
      myVal.confirmpass = (
        <span className="validationMsg float-left text-danger">
          Enter Confirm Password
        </span>
      );
      myVal.validation = true;
    } else if (this.state.newPassword !== this.state.confirmPassword) {
      myVal.confirmpass = (
        <span className="validationMsg text-danger float-left">
          New and Confirm Password Do Not Matched
        </span>
      );
      myVal.validation = true;
    } else {
      myVal.newpass = "";
      myVal.confirmpass = "";
      if (myVal.validation === false) myVal.validation = false;
    }

    this.setState({
      validationModel: myVal,
    });

    if (myVal.validation === true) {
      this.setState({ loading: false });
      return;
    }

    axios
      .get(this.url + "UpdatePassword/" + this.state.newPassword, this.config)
      .then((response) => {
        this.props.onClose();

        Swal.fire("Password Changed Successfully", "", "success");

        // this.props.history.push("/Dashboard");
        this.setState({ loading: false });
      })

      .catch((error) => {
        this.setState({ loading: false });
        if (error.response) {
          if (error.response.status) {
            if (error.response.status === 400) {
              Swal.fire("", error.response.data, "error");
            }
          }
        }
      });
  }
  render() {
    return (
      <React.Fragment>
        {/* <Draggable
          bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
          handle="handler"
        > */}
          <div
            className="modal fade show"
            id="changePassword"
            role="dialog"
            aria-labelledby="changePassword"
            aria-hidden="true"
            onKeyDown={this.keyHandling}
            style={{ display: "block", width: "55%", left: "25%" }}
          >
            <Spiner loading={this.state.loading} />
            <div className="modal-dialog modal-md" role="document">
              <div
                className="modal-content"
                style={{ width: "60%", minHeight: "30px" }}
              >
                <handler>
                  <div className="custom modal-header">
                    <h4 className="modal-title m-0 p-0" id="changePassword">
                      Change Password
                    </h4>
                    <button
                      type="button"
                      className="close"
                      onClick={this.props.onClose()}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </handler>

                <div
                  className="modal-body p-0 m-0 "
                  style={{ overflow: "hidden" }}
                >
                  <form>
                    <div
                      className="modal-body text-center"
                      style={{ maxHeight: this.state.maxHeight }}
                    >
                      <div className="mainTable">
                        <div className="row-form">
                          <div className="col-md-12" style={{ width: "100%" }}>
                            <label
                              style={{
                                float: "left",
                                width: "35%",
                                lineHeight: 2,
                              }}
                            >
                              New Password<span className="redlbl"> *</span>
                            </label>
                            <input
                              className="margina  form-control"
                              type="password"
                              value={this.state.newPassword}
                              name="newPassword"
                              id="newPassword"
                              autoComplete="off"
                              style={{ width: "50%", height: "30px" }}
                              onChange={this.handleChange}
                            />
                            {this.state.validationModel.newpass}
                          </div>
                        </div>
                        <div className="row-form mt-3">
                          <div className="col-md-12" style={{ width: "100%" }}>
                            <label
                              style={{
                                float: "left",
                                width: "35%",
                                lineHeight: 2,
                              }}
                            >
                              Confirm Password<span className="redlbl"> *</span>
                            </label>
                            <input
                              className="marginb form-control"
                              type="password"
                              style={{ width: "50%", height: "30px" }}
                              value={this.state.confirmPassword}
                              name="confirmPassword"
                              id="confirmPassword"
                              autoComplete="off"
                              onChange={this.handleConfirm}
                            />
                            {this.state.validationModel.confirmpass}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row-form text-center pt-2"
                      id="gridData"
                      key="gridData"
                    >
                      <button
                        id="btnCancel"
                        className="btn-phr  mb-2 mr-1"
                        data-dismiss="modal"
                        onClick={(e) => this.changepassword(e)}
                      >
                        Save
                      </button>
                      <button
                        id="btnCancel"
                        className="btn-phr mb-2 "
                        data-dismiss="modal"
                        onClick={this.props.onClose()}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        {/* </Draggable> */}
      </React.Fragment>
      // <React.Fragment>
      //   <div
      //     id="myModal"
      //     className="modal fade bs-example-modal-new show"
      //     tabIndex="-1"
      //     role="dialog"
      //     aria-labelledby="myLargeModalLabel"
      //     aria-hidden="true"
      //     style={{ display: "block",  width: "55%", left: "25%" }}
      //   >
      //     <div className="modal-dialog modal-md">
      //       {spiner}
      //       <div className="modal-content" style={{ overflow: "hidden" }}>
      //         <div className="mainTable fullWidthTable" id="ptstid">
      //             <div className="row" style={{ backgroundColor: "#03658c" }}>
      //                 <h6 className="modal-title text-white pl-4 pt-1 pb-1">
      //                   Change Password</h6>
      //             </div>
      //           <div
      //             className="modal-body text-center"
      //             style={{ maxHeight: this.state.maxHeight }}
      //           >
      //             <div className="mainTable">
      //               <div className="row-form">
      //                 <div
      //                    className="col-md-12"
      //                   style={{ width: "100%" }}
      //                 >
      //                   <label style = {{
      //                     float: "left",
      //                     width: "35%",
      //                     lineHeight: 2
      //                   }}>
      //                     New Password<span className="redlbl"> *</span>
      //                   </label>
      //                   <input
      //                     className="margina  form-control"
      //                     type="password"
      //                     value={this.state.newPassword}
      //                     name="newPassword"
      //                     id="newPassword"
      //                     autoComplete = "off"
      //                     style = {{width : "50%", height : "30px"}}
      //                     onChange={this.handleChange}
      //                   />
      //                   {this.state.validationModel.newpass}
      //                 </div>
      //               </div>
      //               <div className="row-form mt-2">
      //                 <div
      //                   className="col-md-12"
      //                   style={{ width: "100%" }}
      //                 >
      //                   <label
      //                   style = {{
      //                     float: "left",
      //                     width: "35%",
      //                     lineHeight: 2
      //                   }}
      //                   >
      //                     Confirm Password<span className="redlbl"> *</span>
      //                   </label>
      //                   <input
      //                     className="marginb form-control"
      //                     type="password"
      //                     style = {{width : "50%", height : "30px"}}
      //                     value={this.state.confirmPassword}
      //                     name="confirmPassword"
      //                     id="confirmPassword"
      //                     autoComplete = "off"
      //                     onChange={this.handleConfirm}
      //                   />
      //                   {this.state.validationModel.confirmpass}
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //           <div className="row-form text-center" id="gridData" key="gridData">
      //             <button
      //               id="btnCancel"
      //               className="btn-phr  mb-2 mr-1"
      //               data-dismiss="modal"
      //               onClick={() => this.sendEmail()}
      //             >
      //               Save
      //             </button>
      //             <button
      //               id="btnCancel"
      //               className="btn-phr mb-2 "
      //               data-dismiss="modal"
      //               onClick={this.props.onClose()}
      //             >
      //               Cancel
      //             </button>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInformation: state.userInformation,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      userInfo: userInfo,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(changepassword)
);
