import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import '@fortawesome/fontawesome-free/css/all.min.css';

import * as serviceWorker from './serviceWorker'
import { HashRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducers from "./reducers";
import 'bootstrap/dist/css/bootstrap.css';

const store = createStore(allReducers);
serviceWorker.unregister();
ReactDOM.render(
  <Provider store={store}>
    <Router><App /></Router>

  </Provider>,
  document.getElementById("root")
);




