import React from "react";
import { withRouter } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import { loginAction } from "../Actions/LoginAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../css/PrintStyle.css";
import Swal from "sweetalert2";
// import PrintIcon from "@material-ui/icons/Print";
import { setNotesModules } from "../Actions/notesModuleAction";
import ClinicalDetailReports from "./ClinicalDetailReports";
import Spiner from "./Spiner";
import isNull from "../functions/nullChecking";
//import SocialHistory from '../DetailedNotes/SocialHistory'

class ClinicalNotes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.url = process.env.REACT_APP_URL + "/PatientPortal/";
    //Authorization Token
    this.config = {
      headers: {
        Authorization: "Bearer  " + this.props.loginObject.token,
        Accept: "*/*",
      },
    };

    this.state = {
      loading: false,
      showPopup: "",
      data: [],
      table: [],
      Columns: [
        {
          label: "DATE",
          field: "appointmentDate",
          sort: "asc",
          width: 100,
        },
        {
          label: "LOCATION",
          field: "location",
          sort: "asc",
          width: 150,
        },
        {
          label: "PROVIDER",
          field: "provider",
          sort: "asc",
          width: 100,
        },
        {
          label: "VISIT REASON",
          field: "visitReason",
          sort: "asc",
          width: 100,
        },

        {
          label: "SIGNED BY",
          field: "signedBy",
          sort: "asc",
          width: 50,
        },
        {
          label: "SIGNED DATE",
          field: "signedDate",
          sort: "asc",
          width: 100,
        },
        {
          label: "ACTION",
          field: "action",
          sort: "asc",
          width: 100,
        },
      ],
    };
  }
  componentDidMount() {
    this.getClinicalNotesData();
  }

  loading = (action) => {
    this.setState({ loading: action });
  };

  showPopupFunc = (e, row) => {
    e.preventDefault();
    this.setState({
      showPopup: (
        <ClinicalDetailReports
          patientNotesID={row.patientNotesID}
          name={row.provider}
          visitDate={row.dos}
          signedBy={row.signedBy}
          signedDate={row.signedDate}
          status={row.signed}
          keyHandling={this.keyHandling}
          closeModal={() => this.setState({ showPopup: null })}
          loading={this.loading}
        />
      ),
    });
  };

  getClinicalNotesData = () => {
    try {
      this.setState({ loading: true });

      axios
        .get(this.url + "ClinicalNotesSummary", this.config)
        .then((response) => {
          let newList = [];
          try {
            response.data.forEach((row, i) => {
              newList.push({
                appointmentDate: row.dos,
                provider: row.provider,
                location: row.location,
                signedBy: row.signedBy,
                signedDate: row.signedDate,
                visitReason: row.visitReason,
                action: (
                  <i
                    title="View"
                    class="fas fa-eye print-source f14"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => this.showPopupFunc(e, row)}
                  ></i>
                ),
              });
            });
            this.setState({
              // data : newList,
              table: {
                columns: this.state.Columns,
                rows: newList,
              },
              loading: false,
            });
          } catch (error) {
            console.log(error);
          }
        }).catch((error)=>{
          this.setState({ loading: false });
          try {
            if (error.response) {
              if (error.response.status) {
                if (error.response.status === 401) {
                  Swal.fire('Unauthorized Access', '', 'error')
                  return
                } else if (error.response.status === 404) {
                  Swal.fire('Not Found', 'Failed With Status Code 404', 'error')
                  return
                } else if (error.response.status === 400) {
                  Swal.fire('Error', error.response.data, 'error')
                  return
                } else {
                  Swal.fire('Something Wrong', 'Please Try Again', 'error')
                  return
                }
              }
            } else {
              Swal.fire('Something went Wrong', '', 'error')
              return
            }
          } catch {}
        });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };
  keyHandling = (event) => {
    if (event.keyCode === 27) {
 
        Swal.fire({
          title: "Do you want to close?",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Yes`,
        }).then((result) => {
         
        
           if(result.value === true){
            this.setState({ showPopup: false })
           }
      
         
        });
  
    } 
  };
  render() {
    // const columns = [
    //   {
    //     name: "DATE",
    //     selector: "appointmentDate",
    //     sortable: true,
    //     minWidth: "100px",
    //     maxWidth: "100px",
    //   },
    //   {
    //     name: "LOCATION",
    //     selector: "location",
    //     sortable: true,
    //     minWidth: "150px",
    //     maxWidth: "150px",
    //   },
    //   {
    //     name: "PROVIDER",
    //     selector: "provider",
    //     minWidth: "250px",
    //     maxWidth: "250px"
    //   },
    //   {
    //     name: "VISIT REASON",
    //     selector: "visitReason",
    //     minWidth: "100px",
    //     maxWidth: "100px"
    //   },
    //   {
    //     name: "SIGNED BY",
    //     selector: "signedBy",
    //     sortable: true,
    //     minWidth: "250px",
    //     maxWidth: "250px"
    //   },
    //   {
    //     name: "SIGNED DATE",
    //     selector: "signedDate",
    //     sortable: true,
    //     minWidth: "200px",
    //     maxWidth: "200px"
    //   },
    //   {
    //     name: "ACTION",
    //     selector: "action",
    //     sortable: true,
    //     minWidth: "100px",
    //     maxWidth: "100px"
    //   }
    // ];
    let popup = "";
    if (!isNull(this.state.showPopup)) {
      document.body.style.overflowY = "hidden";
      popup = this.state.showPopup;
    } else document.body.style.overflowY = "visible";
    return (
      <React.Fragment>
        <div className="tab-pane fade show active" id="clinicalNotes">
          {popup}
          <Spiner loading={this.state.loading} />
          <div className="row m-2">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 custom-header">
                  <h6 className="pt-2 pb-1">
                    <b>Clinical Notes</b>
                  </h6>
                </div>
              </div>
              <div className="row">
                <div
                  className="tableGridContainer text-nowrap col-md-12 p-0 m-0 clinicalTable"
                  style={{ lineHeight: 1 }}
                >
                  {/* <DataTable
              columns={columns}
              data={this.state.data}
              customStyles={customStyles}
              pagination
              paginationDefaultPage={1}
              paginationResetDefaultPage={false}
              paginationTotalRows={this.state.data.length}
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handlePerRowsChange}
              fixedHeader={true}
              fixedHeaderScrollHeight="300px"
              persistTableHead={true}
              striped={true}
              responsive={true}
              direction="ltr"
              overflowX={false}
              className="fawad"
            /> */}
                  <MDBDataTable
                    responsive={true}
                    striped
                    bordered
                    entries="10"
                    searching={false}
                    data={this.state.table}
                    displayEntries={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    setupLeftMenu: state.leftNavigationMenus,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
    userInfo:
      state.loginInfo != null
        ? state.loginInfo
        : { userPractices: [], name: "", practiceID: null },
    notesModules: state.notesModules,
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginAction: loginAction,
      setNotesModules: setNotesModules,
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, matchDispatchToProps)(ClinicalNotes)
);
