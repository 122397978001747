import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Spiner from "./Spiner";
// import Draggable from "react-draggable";
import Swal from "sweetalert2";
import Calendar from "react-calendar";
import { connect } from "react-redux";
import "react-calendar/dist/Calendar.css";
// import NumberFormat from "react-number-format";
// import logoImage from "../images/logo-Medi-Fusion.png";
import isNull from "../functions/nullChecking";

const url = `${process.env.REACT_APP_URL}/ExternalServices/`;
// const url2 = `${process.env.REACT_APP_URL}/PatientPortal/`;
let timeOut = "";

function BookAppointment(props) {

  const initialValidationModal = {
    lastNameVal: "",
    firstnameVal: "",
    providerIDVal: "",
    LocationIDVal: "",
    visitReasonVal: "",
    AppointmentTimeVal: "",
    DOBVal: "",
    emailVal: "",
  };

  const initialSaveModal = {
    firstname: "",
    lastName: "",
    DOB: "",
    email: "",
    Notes: "",
    Duration: "",
    visitReason: "",
    providerID: "",
    LocationID: "",
    ClientID: 0,
    PracticeID: 0,
    AppointmentDate: "",
    AppointmentTime: "",
    phoneNumber: "",
    patientID: 0,

    // public string lastName { get; set; }
    // public DateTime? DOB { get; set; }
    // public DateTime? AppointmentDate { get; set; }
    // public DateTime ?AppointmentTime { get; set; }
    // public string email { get; set; }
    // public string phoneNumber { get; set; }
    // public string visitReason { get; set; }
    // public string Notes { get; set; }
    // public int? Duration { get; set; }

    // public long? providerID { get; set; }
    // public long? LocationID { get; set; }
    // public long? ClientID { get; set; }
    // public long? PracticeID { get; set; }
    // public long? patientID { get; set; }
  };

  const [loading, setLoading] = useState(false);
  // const [providers, setProviders] = useState([]);
  const [locations, setLocations] = useState([]);
  // const [visitReasons, setVisitReasons] = useState([]);
  const [slots, setSlots] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [dateClicked, setDateClicked] = useState(false);
  const [slotSelected, setSlotSelected] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [saveModal, setSaveModal] = useState(initialSaveModal);

  // const [client, setClient] = useState("");
  // const [type, setType] = useState([]);
  const [validationModal, setValidationModal] = useState(
    initialValidationModal
  );
  const [validation, setvalidation] = useState(false);
  const isInitialMount = useRef(true);
  // const [modalSet, setModalSet] = useState(false);

  // const url = process.env.REACT_APP_URL + "/PatientPortal/";
  const config = {
    headers: {
      Authorization: "Bearer  " + props.loginObject.token,
      Accept: "*/*",
    },
  };
  useEffect(() => {
    try {
      Array.from(document.querySelectorAll(".react-calendar")).forEach(
        (tab) => {
          tab.setAttribute("style", "max-height: 300px; overflow-y: auto;");
        }
      );
    } catch (error) {}

    setLocations(props.location);
    // setClient(props.client);
    // setProviders(props.provider);
    // setType(props.type);

    let modal = { ...saveModal };
    modal.ClientID = props.client;
    modal.firstname = props.userInformation[0].firstName;
    modal.lastName = props.userInformation[0].lastName;
    modal.DOB = props.userInformation[0].dob;
    modal.email = props.userInformation[0].email;
    modal.phoneNumber = props.userInformation[0].phoneNumber;
    modal.PracticeID = props.userInformation[0].practiceID;
    modal.patientID = props.userInformation[0].patientID;

    setSaveModal(modal);
  }, [props.location, props.client, props.userInformation, saveModal]);

  const dateToday = (date) => {
    let a = new Date(date);

    let day = a.getDate();
    let month = a.getMonth() + 1;
    let year = a.getFullYear();

    let fullDate = month + "/" + day + "/" + year;
    return fullDate;
  };

  useEffect(() => {
    if (!isNull(errorMsg)) {
      document.getElementById("showStatement").click();
      timeOut = setTimeout(() => {
        document.getElementById("showStatement").click();
      }, 2900);
    }
  }, [errorMsg]);
  const onChange = async (date) => {
    setLoading(true);
    let modal = { ...saveModal };
    modal.AppointmentDate = dateToday(date);
    // modal.ClientID = props.client;
    // modal.firstname = props.userInformation[0].firstName
    // modal.lastName = props.userInformation[0].lastName
    // modal.DOB = props.userInformation[0].dob
    // modal.email = props.userInformation[0].email
    // modal.phoneNumber = props.userInformation[0].phoneNumber
    
    setSaveModal(modal);
    setDateClicked(true);

    let fullDate = dateToday(date);
    let clientID = saveModal.ClientID;

    let providerID = saveModal.providerID;

    if (isNull(providerID)) {
      await clearTimeout(timeOut);
      setErrorMsg("");
      setLoading(false);
      setSlotSelected(false);
      setDateClicked(false);
      setSlots([]);
      showMsg("Select A Provider", "error");
    } else {
      if (slotSelected) {
        setDateClicked(true);
        setSlotSelected(false);
        setSlots([]);
        let modal = { ...saveModal };
        modal.AppointmentTime = "";
        modal.AppointmentDate = "";

        setSaveModal(modal);
      } else {
        setDateClicked(true);
        setSlots([]);
      }

      axios
        .post(
          `${url}AppointmentSolts?date=${fullDate}&ClientID=${clientID}&providerID=${providerID}`,
          ""
        )
        .then((response) => {
          if (
            response.data ===
            "No Schedule set for this Day of selected provider."
          ) {
            clearTimeout(timeOut);
            // setErrorMsg("");
            setDateClicked(false);
            setLoading(false);
            setIntervals([
              {
                Duration: "Select Date...",
              },
            ]);
            showMsg(response.data, "error");
          } else {
            let myIntervals = [];
            for (let i = 1; i <= 6; i++) {
              myIntervals.push({
                Duration: response.data.interval * i,
              });
            }
            setSlots(response.data.slots);
            setErrorMsg("");
            setIntervals(myIntervals);
            let modal = { ...saveModal };
            modal.AppointmentDate = fullDate;
            modal.Duration = response.data.interval;
            setSaveModal(modal);
            setLoading(false);
          }
        })
        .catch((error) => {
          Swal.fire("Something Went Wrong", "", "error");
          setLoading(false);
        });
    }
  };

  // const handleNumericCheck = (event) => {
  //   if (event.charCode >= 48 && event.charCode <= 57) {
  //     return true;
  //   } else {
  //     event.preventDefault();
  //     return false;
  //   }
  // };

  // const gotoLogin = (e) => {
  //   e.preventDefault();
  //   props.history.push("/ClientLogin");
  // };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let modal = { ...saveModal };
    modal[name] =
      name === "visitReason" || name === "Duration"
        ? value
        : value.toUpperCase();
    setSaveModal(modal);
    if (name === "providerID" || name === "LocationID") {
      setValidationModal({ ...validationModal, [name + "Val"]: "" });
    }
    // Only for Validations
       if (
        name === "firstname" ||
        name === "lastName" ||
        name === "providerID" ||
        name === "LocationID" ||
        name === "email" ||
        name === "DOB" ||
        name === "id"
      ) {
        setValidationModal({ ...validationModal, [name + "Val"]: "" });
      }
  
  };

  const bookAppointment = (e) => {
    e.preventDefault();

    /****************************** VALIDATIONS ***************************/
    var myVal = validationModal;
    let inValid = false;
    const validFields = [
      {
        name: "lastName",
        msg: "Enter Last Name",
      },
      {
        name: "firstname",
        msg: "Enter First Name",
      },
      {
        name: "providerID",
        msg: "Select Provider",
      },
      {
        name: "LocationID",
        msg: "Select Location",
      },
      {
        name: "visitReason",
        msg: "Select Appointment Type",
      },
      {
        name: "AppointmentTime",
        msg: "Select a Date & a Suitable Time Slot",
      },
      {
        name: "DOB",
        msg: "Select Date of Birth",
      },
    ];

    validFields.forEach((field) => {
      if (isNull(saveModal[field.name])) {
        myVal[field.name + "Val"] = (
          <span className="validationMsg">{field.msg}</span>
        );
        inValid = true;
      } else {
        myVal[field.name + "Val"] = "";
        if (inValid === false) inValid = false;
      }
    });

    // if (!isNull(saveModal.email) && saveModal.email.indexOf("@") <= 0) {
    //   myVal.emailVal = <span className="validationMsg">Enter Valid Email</span>;
    //   inValid = true;
    // } else {
    //   myVal.emailVal = "";
    //   if (inValid === false) inValid = false;
    // }

    setvalidation(inValid);
    setValidationModal(myVal);
    if (inValid === true) {
      setLoading(false);
      Swal.fire("Please Fill All Fields Properly", "", "error");
    } else {
      setLoading(true);
      let url = `${process.env.REACT_APP_URL}/ExternalServices/SaveExternalAppointmentProfiles?patientPortal=true`;
      axios
        .post(url, saveModal, config)
        .then((response) => {
          if (response.data === "Appointment for patient already exist.") {
            clearTimeout(timeOut);
            setLoading(false);
            setErrorMsg("");
            showMsg(response.data, "error");
            
          } else {
            clearTimeout(timeOut);
            setLoading(false);
            setErrorMsg("");
            showMsg("Appointment Booked Successfully", "success");
            setSaveModal({
              ...initialSaveModal,
              ClientID: saveModal.ClientID,
              PracticeID: saveModal.PracticeID,
              visitReason: saveModal.visitReason,
              providerID: saveModal.providerID,
              LocationID: saveModal.LocationID,
            });
            setSlots([]);
            setDateClicked(false);
            setSlotSelected(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire(
            "Something Went Wrong",
            "Unable to Book Appointment",
            "error"
          );
        });
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  }, [validation]);

  const selectedSlot = (e, row) => {
    Array.from(document.querySelectorAll(".AppointmentSlot")).forEach((tab) => {
      if (parseInt(e.target.id) === parseInt(tab.id)) {
        tab.style.borderLeft = "4px solid #1AFF1A";
      } else {
        tab.style.borderLeft = "4px solid #225B90";
      }
    });
    setSlotSelected(true);
    setSaveModal({
      ...saveModal,
      AppointmentTime: saveModal.AppointmentDate + " " + row,
    });
    setValidationModal({ ...validationModal, AppointmentTimeVal: "" });
  };

  const showMsg = (data, status) => {
    try {
      setErrorMsg(
        <p
          id="error"
          class="col-lg-12 px-0 mt-1 text-center collapse"
          style={{
            color: status === "error" ? "#D20047" : "green",
            background: status === "error" ? "rgb(245, 197, 197)" : "#B5F3B5",
            position: "absolute",
            zIndex: 100,
          }}
        >
          <span class="py-2" style={{ display: "block" }}>
            {status === "error" ? (
              <i
                class="fas fa-exclamation pr-3"
                style={{ color: "#D20047", fontSize: "10px" }}
              />
            ) : (
              <i
                class="fa fa-check pr-3"
                aria-hidden="true"
                style={{ color: "green", fontSize: "10px" }}
              ></i>
            )}
            {data}
          </span>
        </p>
      );
    } catch {}
  };

  return (
    <React.Fragment>
      <div className="container-fluid mt-0 m-0 p-0">
        <Spiner loading={loading} />
        {/* <Draggable
          bounds={{ top: 0, left: -600, right: 600, bottom: 400 }}
          handle="handler"
        > */}
          <div
            className="modal fade show"
            id="ComposeEmail"
            role="dialog"
            aria-labelledby="ComposeEmail"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-lg " role="document">
              <div
                className="modal-content"
                style={{ width: "60%", minHeight: "30px" }}
              >
                <handler>
                  <div className="custom modal-header">
                    <h4 className="modal-title m-0 p-0" id="ComposeEmail">
                      <span className="m-0 font-weight-bold d-block float-left">
                        Book Appointment
                      </span>
                    </h4>

                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        clearTimeout(timeOut);
                        props.closeBookAppointment();
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </handler>
                <div
                  className="modal-body p-0 m-0"
                  style={{ backgroundColor: "whitesmoke" }}
                >
                  <a
                    id="showStatement"
                    href="#error"
                    data-toggle="collapse"
                    hidden
                  >d</a>
                  <div
                      style={{
                        color: "#D20047",
                        background:  "#B5F3B5",
                     
                      }}
                  >
                     {errorMsg}

                  </div>
                 
                  <form>
                    <div className="clearfix"></div>
                    <div
                      className="pr-0 pb-0 mb-0 pt-1 pl-2 pr-2"
                      aria-expanded="true"
                      style={{ overflowY: "auto" }}
                    >
                      <div className="form-row">
                        <div className="form-group p-2 col-md-4 col-sm-4 col-12">
                          <label for="visitReason">
                            Appointment Type:
                            <span className="text-danger"> *</span>
                          </label>
                          <select
                            class="form-control custom"
                            name="visitReason"
                            id="visitReason"
                            onChange={handleChange}
                            disabled={props.type.length > 0 ? false : true}
                          >
                            <option value="0">Please Select</option>
                            {props.type.length > 0 ? (
                              props.type.map((row) => (
                                <option value={row.id}>{row.name}</option>
                              ))
                            ) : (
                              <option>No Appointment Type Found</option>
                            )}
                          </select>
                          <div class="text-danger">
                            {validationModal.visitReasonVal}
                          </div>

                          <label for="LocationID" className="mt-2">
                            Location: <span className="text-danger"> *</span>
                          </label>
                          <select
                            class="form-control custom"
                            name="LocationID"
                            id="LocationID"
                            onChange={handleChange}
                            value={saveModal.LocationID}
                            disabled={props.location.length > 0 ? false : true}
                          >
                            <option value="0">Please Select</option>
                            {props.location.length > 0 ? (
                              locations.map((row) => (
                                <option value={row.id}>{row.name}</option>
                              ))
                            ) : (
                              <option>No Locations Found</option>
                            )}
                          </select>
                          <div class="text-danger">
                            {validationModal.LocationIDVal}
                          </div>

                          <label for="providerID" className="mt-2">
                            Provider: <span className="text-danger"> *</span>
                          </label>

                          <select
                            class="form-control custom"
                            name="providerID"
                            id="providerID"
                            onChange={handleChange}
                            value={saveModal.providerID}
                            disabled={props.provider.length > 0 ? false : true}
                          >
                            <option value="0">Please Select</option>
                            {props.provider.length > 0 ? (
                              props.provider.map((row) => (
                                <option value={row.id}>{row.name}</option>
                              ))
                            ) : (
                              <option>No Providers Found</option>
                            )}
                          </select>
                          <div className="text-danger">
                        {validationModal.providerIDVal}
                      </div>
                          {/* <div class="text-danger">
                            {validationModal.providerIDVal}
                          </div> */}

                          <label for="Duration" className="mt-2">
                            Duration:
                          </label>
                          <select
                            class="form-control custom"
                            name="Duration"
                            id="Duration"
                            onChange={handleChange}
                            value={saveModal.Duration}
                            disabled={
                              props.provider.length === 0 || !dateClicked
                                ? true
                                : false
                            }
                          >
                            {dateClicked ? (
                              intervals.map((row) => (
                                <option key={row.Duration} value={row.Duration}>
                                  {row.Duration}
                                </option>
                              ))
                            ) : (
                              <option>Select Date First</option>
                            )}
                          </select>

                          <label for="Notes" className="mt-2">
                            Notes:
                          </label>
                          <textarea
                            class="form-control custom"
                            name="Notes"
                            id="Notes"
                            value={saveModal.Notes}
                            onChange={handleChange}
                            style={{ height: "15%" }}
                          ></textarea>
                        </div>

                        <div className="form-group p-2 col-md-5 col-sm-5 col-12">
                          <span class="mb-1">
                            <h5
                              style={{
                                backgroundColor: "#196891",
                                color: "white",
                                padding: "5px 10px",
                                textAlign: "center",
                                margin: "0px",
                              }}
                            >
                              Select Date
                            </h5>
                          </span>
                          <Calendar
                            onChange={onChange}
                            /* value={date} */ className="myAppointmentCalendar"
                          />
                          <div class="text-danger">
                            {validationModal.AppointmentTimeVal}
                          </div>
                       
                        </div>

                        <div className="form-group p-2 col-md-3 col-sm-3 col-12">
                          <span class="mb-1">
                            <h5
                              style={{
                                backgroundColor: "#196891",
                                color: "white",
                                padding: "5px 10px",
                                textAlign: "center",
                                margin: "0px",
                              }}
                            >
                              Available Slots
                            </h5>
                          </span>
                          <div style={{ maxHeight: 300, overflow: "auto" }}>
                            {dateClicked ? (
                              slots.length > 0 ? (
                                slots.map((row, i) => (
                                  <span
                                    class="mb-1"
                                    onClick={(e) => selectedSlot(e, row)}
                                    id={i}
                                  >
                                    <div
                                      class="col-lg-12 p-1 mb-1 text-center AppointmentSlot"
                                      style={{
                                        height: "40px",
                                        borderLeft: "4px solid #225B90",
                                        cursor: "pointer",
                                      }}
                                      id={i}
                                    >
                                      <h5
                                        id={i}
                                        style={{
                                          color: "black",
                                          paddingTop: "3px",
                                        }}
                                      >
                                        <strong id={i}>{row}</strong>
                                      </h5>
                                    </div>
                                  </span>
                                ))
                              ) : (
                                <span class="mb-1">
                                  <div class="col-lg-12 p-1 mb-1 text-center">
                                    <h5
                                      style={{
                                        color: "black",
                                        paddingTop: "3px",
                                      }}
                                    >
                                      <strong>No Slots Available</strong>
                                    </h5>
                                  </div>
                                </span>
                              )
                            ) : (
                              <span class="mb-1">
                                <div class="col-lg-12 p-1 mb-1 text-center">
                                  <h5
                                    style={{
                                      color: "black",
                                      paddingTop: "3px",
                                    }}
                                  >
                                    <strong>Select Date...</strong>
                                  </h5>
                                </div>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer p-0 m-0 pt-1 border-0">
                      <div class="form-check font-weight-bold p-0 pt-0 m-0 col-md-6 col-sm-6 col-12 float-right text-right pr-2">
                        <button
                          className="btn btn-primary mb-1 mr-1"
                          onClick={bookAppointment}
                        >
                          BOOK APPOINTMENT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        {/* </Draggable> */}
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    userInformation: state.userInformation,
    loginObject: state.loginToken
      ? state.loginToken
      : { toekn: "", isLogin: false },
  };
}
export default connect(mapStateToProps)(BookAppointment);
